import React from "react";
import styles from "./index.scss";
import { Card, Tabs, Button, Row, Col ,Icon, Modal, message} from "antd";
import JobItem from 'components/job';
import { Link } from "react-router-dom";
// import router from 'umi/router';
import * as utils from 'utils/utils';
import dateutils from 'utils/dateutils'
import {getUser, getToken} from 'utils/authority'
import constants from 'utils/constants'
import {callGet, callPost} from 'service/api'
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'

// @connect(({ job, login, loading }) => ({
//     job,
//     login,
// }))
export default class JobDetailPage extends React.Component {
  constructor(props) {
    super(props);
    console.log(' JobDetailPage ', props);
    const {id} = props.match.params;
    this.state = {
      data: {id},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      positionNum: 0,
      recommends: [],
      isApplied: false,
      isShowBuyTip: false,
      isShowPayModal: false,
      type: 1,
      payAmount: 0,
      orderNumber: '',
      count: 0
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {

    // const script = document.createElement("script")
    // script.src = "https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js"
    // // script.async = true

    // const flexibleScript = document.createElement("script")
    // flexibleScript.src = "http://player.alicdn.com/resource/player/lib/flexible.min.js";
    
    // const zeptoScript = document.createElement('script')
    // zeptoScript.src = 'https://cdn.jsdelivr.net/npm/zepto/zepto.min.js';
    // document.body.appendChild(flexibleScript)
    // document.body.appendChild(script)
    // document.body.appendChild(zeptoScript)

    this._toLoadDetail()
  }

  componentWillReceiveProps(newProps) {
      const {id} = newProps.match.params;
      const {data} = this.state;
      console.log(' componentWillReceiveProps ', newProps, this.state)
      if (id !== this.state.data.id) {
          data.id = id;
          this.setState({
            data
          }, this._toLoadDetail)
      }
  }

  _toLoadDetail = async () => {
    let {data} = this.state;
    console.log('load detail', data.id)
    try {
        let result = await callGet('/public/web/positions/'+data.id)
        let recommends = await callGet('/public/recommendPositions', {positionId: data.id})
        let launcherNum = 0; 
        if (result) {
            launcherNum = await callGet('/public/orgPositionNum', {orgId: result.organization.organizationInfoId})
            this.setState({
                data: result,
                recommends,
                positionNum: launcherNum.num
            })
        }
    } catch (e) {
        console.log('e', e.errorMessage)
    }
  }

  _toApply = async ()=> {
      if (!getToken()) {
          this.props.history.push('/user/login')
          return;
      }
      const {data} = this.state;
      try {
        let resumes = await callGet('/resumes', {pageNo: 0, pageSize: 1 });
        if (!resumes || resumes.length <= 0) {
            message.error('请先完善简历')
            return;
        }
        let result = await callPost('/userPositionApplications', {positionId: data.id, resumeId: resumes[0].resumeId})
          if (result) {
              this.setState({
                  data: result.position
              })
              message.success('已投递')
          }
    } catch (e) {
        console.log('e', e.errorMessage)
    }
  }

  concatTags = (item) => {
    const companyType = constants.getName(constants.ORG_TYPE_ARRAY, item.type);
    const experience = constants.getName(constants.WORK_YEARS_ARRAY, item.experienceRequire);
    const education = constants.getName(constants.EDUCATION_ARRAY, item.educationDegreeRequire);
    const jobType = constants.getName(constants.JOB_TYPE_ARRAY, item.jobType)

    const tags = [];
    if (companyType) {
        tags.push(companyType);
    }
    tags.push(experience);
    tags.push(education);
    tags.push(jobType)
    return tags;
  }

  onCourseClick = (item)=> {
    console.log(' course click', item)
    let path = {
      pathname: `/user/job/jobDetail/${item.id}`,
      query: {
        id: item.id
      }}
      this.props.history.replace(path)
    // router.push(path);
  }

  render() {
    // const { course, loading} = this.props;
    const {sources, data, positionNum, recommends } = this.state;
    const user = getUser()
    console.log('render', sources)
    const tags = this.concatTags(data)
    let orgtags = []
    if (data.city && data.city.name) {
        orgtags.push(data.city.name)
    }

    if (data.organization) {
        orgtags.push(constants.getName(constants.ORG_SCALE_ARRAY, data.organization.numberOfEmployee))
        orgtags.push(constants.getName(constants.ORG_TYPE_ARRAY, data.organization.type))
    }

    return (
      <div>
        {/* <UserLearningNavBar /> */}
        {/* <UserBanner banners={banners}/> */}
        
          <div className='user-learning-page-container'>
            {/* <div  className="prism-player" id='Ali_Player' /> */}
            <BackButton/>
            <div className='centerContent' style={{marginTop: 10}}>
                <Card title='简介' extra={`职位发布于${dateutils.getFriendlyTime(data.createdAt)}`}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                            <span><b>{data.title}&nbsp;&nbsp;&nbsp;&nbsp;{utils.getSalaryRange(data)}</b>
                                {
                                    data.quickHiringService === 1 &&
                                        <span
                                        style={{
                                            backgroundColor: '#FF6550',
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            paddingLeft: 6,
                                            paddingRight: 6,
                                            marginLeft: 10,
                                            height: 16,
                                            borderRadius: 8,
                                            color: 'white',
                                            fontSize: 10
                                        }}>{'平台优推'}</span>
                                } <br/></span>
                            <span><b></b> <br/></span>
                            <div>
                                <span><b>{data.organizationName}&nbsp;&nbsp;&nbsp;&nbsp;{data.campusName}</b> <br/></span>
                                <span><b></b> <br/></span>
                                <span>
                                    {
                                    tags && tags.map(item => `${item}  `)
                                    }
                                    {/* 民办&nbsp;&nbsp;&nbsp;&nbsp;经验不限&nbsp;&nbsp;&nbsp;&nbsp;本科&nbsp;&nbsp;&nbsp;&nbsp;全职 */}
                                </span>
                            </div>
                        </div>
                        {
                            data && data.buyFlag === 0 &&
                                <Button onClick={this._toApply} ghost type="primary" shape="round" size={'large'} disabled={data.applicationFlag === 1}>{data.applicationFlag === 1 ? '已投递' : '立即投递'}</Button>
                        }
                        {
                            data && data.buyFlag === 1 &&
                                <Button type="primary" shape="round" size={'large'} disabled>您已开通该职位平台优推服务</Button>
                        }
                        {
                            data && data.buyFlag !== 1 && data.quickHiringService === 1 &&
                                <Button onClick={this._showBuyTip} type="primary" shape="round" size={'large'}>开通该职位平台优推服务</Button>
                        }
                    </div>
                    
                </Card>

                <Card title='职位描述' style={{marginTop: '24px'}}>
                    <div style={{width: '100%', overflow: 'hidden'}}>
                    <p style={{wordBreak: 'break-word'}}>{data.jobDescription} <br/></p>

                    { data.quickHiringService === 1 && <div style={{
                        height: 1,
                        marginTop: 10,
                        marginBottom: 10,
                        backgroundColor: '#f5f5f5'
                    }}/>}
                            { data.quickHiringService === 1 && <span style={{
                                    color: '#FFC65A',
                                    fontSize: 11,
                                }}> 此岗位为师业有成官方猎头精选，现在开通平台优推立享全程指导，优先入职等尊贵优质服务，若未能入职，我们将全额退款！！！</span>}
                    </div>
                </Card>

                <Card title='企业信息' style={{marginTop: '24px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <img src={data.organization &&  data.organization.avatarUrl ? data.organization.avatarUrl : constants.COMMONS.orgAvatar} alt="" style={{width:'60px',height:'60px'}}/>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: '10px'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <b><span style={{fontSize: '14px', fontStyle:'bold', marginRight: '8px'}}>{data.organizationName}</span></b>
                                <span>在招职位{positionNum}个<br/></span>
                            </div>
                            <span>
                                {
                                orgtags && orgtags.map(item => `${item}  `)
                                }
                                {/* 民办&nbsp;&nbsp;&nbsp;&nbsp;经验不限&nbsp;&nbsp;&nbsp;&nbsp;本科&nbsp;&nbsp;&nbsp;&nbsp;全职 */}
                            </span>
                        </div>
                    </div>
                </Card>

                {
                    data.quickHiringService === 1 && <Card title='什么是平台优推' bordered={true} style={{marginTop: '24px'}}
                    >
                        <img src='https://img.syyc.top/h5/baoruzhi.jpg' style={{
                            width: '100%',
                            maxWidth: 500
                        }} alt=''/>
                    </Card>
                }

                <Card title='更多职位' bordered={true} style={{marginTop: '24px'}}>
                    <Row gutter={8}>
                        {recommends && recommends.length > 0 && recommends.map((item, index) => <JobItem key={'recom'+ item.id} index={index} {...item} onClick={()=> this.onCourseClick(item)}/>)}
                    </Row>
                </Card>
            </div>
          </div>
          {this.renderBuyTip()}
          {this.renderPayModal()}
      </div>
    );
  }

  _showBuyTip = () => {
    this.setState({
        isShowBuyTip: true
    })
}

_hideBuyTip = () => {
    this.setState({
        isShowBuyTip: false
    })
}

_toBuyJob = async (type) => {
    let { data, resumes } = this.state;
    console.log('_toBuyJob', resumes)
    if (data.buyFlag == 1) {
        return;
    }
    // if (resumes.length <= 0) {
    //     // utils.showToast('请完善简历')
    //     message.error('请完善简历')
    //     return;
    // }
    // utils.showLoading()
    try {
        let result = await callPost(`/positions/${data.id}/preBuy`, {type})
        console.log('_toBuyJob res', result)
        let params = {};
        // data.subTitle = data.title;
        params.num = 1;
        // data.price = result.payAmount;
        // data.imageUrl = result.imageUrl;
        // data.total = utils.changeMoney(result.originalPrice) + ' * ' + result.num + '份';
        params.itemId = data.id;
        params.type = type;
        params.itemType = type;
        let orderResult = await callPost('/purchase', params)

        const alipayInfo = await callPost('/alipay/payOrder', {tradeType: 2, balance: orderResult.paidAmount, orderNumber: orderResult.orderNumber})
        console.log(' alipay info', alipayInfo)
        this.setState({
            type,
            payAmount: orderResult.paidAmount,
            orderNumber: orderResult.orderNumber,
            isShowBuyTip: false,
            isShowPayModal: true,
            payQrcode: alipayInfo.payParam
        }, ()=> this.refs.paymodal.pollPayResult(orderResult.orderNumber))
    } catch (err) {
        message.error(err.errorMessage)
    }
}

  renderBuyTip = () => {
    const { isShowBuyTip } = this.state;
    return (
      <Modal
        visible={isShowBuyTip}
        okText='确认'
        cancelText='取消'
        onCancel={() => {
          this.setState({
            isShowBuyTip: false,
          });
        }}
        closable={false}
        onOk={()=> {
          this.setState({
            isShowBuyTip: false,
          });
        }}
      >
        <div style={{
                justifyContent: 'center',
                alignItems: 'center'}}>
            <div style={{width: '100%', marginBottom: 5}}>
                <div style={{backgroundColor: '#fff', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, alignItems: 'center', marginTop: -1}}>
                    <span style={{color: '#FFC001', fontSize: 20, fontWeight: 'bold', justifyContent: 'center'}}>友情提示</span>
                    <div style={{ minHeight: 120}}>
                        <span style={{color: '#999', fontSize: 16, marginTop: 10}} >
                        如果平台或者学校通知您面试，您不去面试或者录用后，您不去上岗。平台将扣百分之五十作为违约金。请考虑自己的工作时间和对薪资的要求是否符合后，再确定是否平台优推。
                        </span>
                    </div>
                    <div style={{marginBottom: 15}}/>
                    <div style={{
                        display:'flex',
                        flexDirection: 'row',
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                        borderTopWidth: 1,
                        borderTopColor: '#eee',
                        height: 60,
                        alignItems: 'center', justifyContent: 'center',
                    }}>
                        <div onClick={()=> {
                            this._hideBuyTip()
                            this._toBuyJob(1)
                        }
                            } style={{height: 40, marginHorizontal: 10, marginRight: 10, flex: 1}}>
                                <div style={{display: 'flex', height: 40, alignItems: 'center', borderRadius: 20, justifyContent: 'center', backgroundColor: '#FFC001', flex: 1}}>
                                    <span style={{color: '#fff', }}>购买优培优推服务</span>
                                </div>
                        </div>
                        <div onClick={()=> {
                            this._hideBuyTip()
                            this._toBuyJob(0)
                        }
                            } style={{height: 40, marginHorizontal: 10, marginLeft: 10, flex: 1}}>
                            <div style={{display: 'flex', height: 40, alignItems: 'center', borderRadius: 20, border: '1px solid #FFC001', justifyContent: 'center', backgroundColor: 'white', flex: 1}}>
                                <span style={{color: '#FFC001', }}>只购买优推服务</span>
                            </div>
                        </div>
                    </div>
                    
                    <div style={{alignItems: 'center',  justifyContent: 'center', flexDirection: 'row', marginTop: 10, marginBottom: 10}}>
                        <span style={{color: '#666', fontSize:12}}>购买即代表已同意</span>
                        <div onClick = {()=> {
                            window.open('https://img.syyc.top/best_recommend_protocal.html');
                        }}><span style = {{ color: '#FFC001',fontSize:12 }} >《平台优推服务协议》</span></div>
                    </div>
                </div>
            </div>
        </div>
        
      </Modal>
    );
  };

  renderPayModal = () => {
    const { isShowPayModal, type, payAmount, payQrcode, orderNumber } = this.state;
    return (
        <PayModal
         ref="paymodal"
         isShowPayModal={isShowPayModal}
         payAmount={payAmount}
         payQrcode={payQrcode}
         orderNumber={orderNumber}
         title={type == 0 ? '购买优推服务' : '购买优培优推服务'}
         desc={type == 0 ? '购买优推服务' : '购买优培优推服务'}
         onClose={() => {
            this.setState({
              isShowPayModal: false,
            });
          }}
          callback={()=> {
            this.setState({
              isShowPayModal: false,
            });
            this._toLoadDetail()
          }}
         />
    );
  };

  _renderClassIntroduction = () => {
    let {data} = this.state;
    // console.log('_renderClassIntroduction ', data)
    return (
        <div>
            {/* {this.state.data && this.state.data.groupPurchases && this.state.data.groupPurchases.map(
                item => <PurchasesItem
                    owner={item.owner}
                    title={item.title}
                    groupPrice={item.groupPrice}
                    remainCount={item.remainCount}
                    current={item.current}
                    expiry={item.expiry}
                    participated={item.participated}
                />)} */}

            {
                data.organization && <div>
                    <div className='org'>
                        <img className='org_avatar' src={data.organization.avatarUrl || '' } alt='icon'/>
                        <div style={{marginLeft: 6}}>
                            <p
                                style={{color: '#363636', fontSize: 12}}>{this.state.data.organization.name}</p>
                            <p style={{
                                color: '#959595',
                                fontSize: 10,
                                marginTop: 5
                            }}>{this.state.data.organization.introduction}</p>
                        </div>
                    </div>
                    <div className='line'/>
                </div>
            }

            <div className='tag'>
                {
                    this.state.data && this.state.data.tags && this.state.data.tags.map(item => <div style={{
                        backgroundColor: '#FFC600',
                        borderRadius: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 20
                    }}>
                        <p style={{
                            color: '#000000',
                            fontSize: 8,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}>{item}</p>
                    </div>)
                }
            </div>

            <div className='line'/>

            <div>
                {/* <img source={book} style={{width: 12, height: 12 / 58 * 45}}/> */}
                <p style={{color: '#363636', fontSize: 12, marginLeft: 8}}>课程简介</p>
            </div>
            <div>
                {/* <HTMLView
                    value={this.state.data.description}
                /> */}
                {data.description}
            </div>
        </div>
    );
}
}
