import React from "react";
import "./index.scss";
import { message, Button} from "antd";
import * as utils from 'utils/utils';
import {getUser} from 'utils/authority'
import { callGet, callPost, getAddress as getAddressRemote } from "service/api";
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'
import DistributionModal from 'components/distributionModal'

// @connect(({ course,login, loading }) => ({
//   course,
//   login,
//   loading: loading.effects['course/getList']
// }))
export default class GoodsDetailPage extends React.Component {
  constructor(props) {
    super(props);
    // console.log(' GoodsDetailPage ', props, props.location);
    const {id} = props.match.params;
    this.state = {
      data: {productId: id},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      isShowPayModal: false
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {

    // const script = document.createElement("script")
    // script.src = "https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js"
    // // script.async = true

    // const flexibleScript = document.createElement("script")
    // flexibleScript.src = "http://player.alicdn.com/resource/player/lib/flexible.min.js";
    
    // const zeptoScript = document.createElement('script')
    // zeptoScript.src = 'https://cdn.jsdelivr.net/npm/zepto/zepto.min.js';
    // document.body.appendChild(flexibleScript)
    // document.body.appendChild(script)
    // document.body.appendChild(zeptoScript)

    this._toLoadDetail()
  }

  _toLoadDetail = async () => {
    let {data} = this.state;
    console.log('load detail', data.productId)
    let result
    try {
        result = await callGet(`/public/products/${data.productId}`)
        this.setState({
          data: result || {},
        })
    } catch (e) {
        console.log('e', e)
        //utils.showToast(e.errorMessage)
    }
    }

    _back = ()=> {
        this.props.history.goBack()
    }

    _toBuy = async () => {

        let {data} = this.state;
        try {

            let add = undefined ; //getAddressCache()
            if (!add) {
                add =  await getAddressRemote()
               if (!add) {
                   message.error('购买前请先移步至‘我的’页，完善资料，添加收货地址。')
                   return;
               }
            }
            let orderResult;
            let params = {};
            params.itemId = data.productId;
            params.num = 1;

            // let result = await callPost('/products/' + data.productId + '/preBuy')

            params.addressId = add.id;
            params.itemType = 5;
            orderResult = await callPost('/purchase', params)
            const alipayInfo = await callPost('/alipay/payOrder', {tradeType: 2, balance: orderResult.paidAmount, orderNumber: orderResult.orderNumber})
            // console.log(' alipay info', alipayInfo)
            this.setState({
                // type,
                payAmount: orderResult.paidAmount,
                orderNumber: orderResult.orderNumber,
                isShowPayModal: true,
                payQrcode: alipayInfo.payParam
            }, ()=> this.refs.paymodal.pollPayResult(orderResult.orderNumber))
        } catch (e) {
            console.log(' to buy', e)
            message.error(e.errorMessage)
        }
    }

    _to88vipPay = ()=> {
        this.props.history.push('/user/vippay')
    }

  render() {
    // const { course, loading} = this.props;
    const {sources, data, currentIndex } = this.state;
    const user = getUser()
    console.log('render', data)
    return (
      <div>
        {/* <UserLearningNavBar /> */}
        {/* <UserBanner banners={banners}/> */}
        <BackButton/>
        
        <div className="user-learning-page-background">
          <div className="user-learning-page-container">
            {/* <div  className="prism-player" id='Ali_Player' /> */}
            {/* {sources && <Player sources={sources} />} */}
            <img src={data.imageUrl} alt="" style={{width: '100%'}}></img>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: 1}}>
                <div className='course'>
                    <div className='tab'>
                            <div className='tab_item' onClick={() => this._classTabOnClick(0)}>
                                <p className={currentIndex === 0 ? 'tab_item_select' : 'tab_item_normal'}>{'课程介绍'}</p>
                                {
                                    currentIndex === 0 &&
                                    <div className='split'/>
                                }
                            </div>
                        <div className='split_line'/>
                        {
                            data.type === 1 &&
                            <div className='tab_item' onClick={() => this._classTabOnClick(1)}>
                                <p className={currentIndex === 1 ? 'tab_item_select' : 'tab_item_normal'}>{'课程目录'}</p>
                                {
                                    currentIndex === 1 &&
                                    <div className='split'/>
                                }
                            </div>
                        }
                        {
                            data.type === 1 && <div className='split_line'/>
                        }
                    </div>

                    <div>
                        <div style={{marginBottom: 24}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 12, marginRight: 12, marginTop: 10}}>
                                <div style={{flex:1, flexShrink: 1}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <p style={{
                                            color: '#000',
                                            fontWeight: 'bold',
                                            fontSize: 17
                                        }}>{this.state.data.title}</p>
                                        {
                                            this.state.data.type === 1 && <div className='serial_tag'>
                                                <p style={{
                                                    color: '#fff',
                                                    fontSize: 10,
                                                    padding: 5
                                                }}>系列课</p></div>
                                        }

                                    </div>
                                    <div
                                        style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                                        <p style={{color: '#000000', fontSize: 10}}>原价：</p><p style={{
                                            textDecorationLine: 'line-through',
                                            color: '#959595',
                                            fontSize: 14
                                        }}>{utils.changeMoney(this.state.data.originalPrice)}</p>
                                        <p style={{color: '#000000', fontSize: 10, marginLeft: 12}}>会员价：</p>
                                        <p style={{
                                                color: '#E8B400',
                                                fontSize: 17
                                            }}>{utils.changeMoney(this.state.data.vipPrice)}</p>
                                        <Button onClick={this.share} style={{marginLeft: 20}} type="primary" size="small">分销</Button>
                                    </div>
                                </div>
                                <div className='vip' onClick={()=>this._to88vipPay()}>
                                    {user && user.isVip === 1 ? '立即续费' : '开通88会员'}
                                </div>
                            </div>
                            <div className='line'/>

                            {
                                this.state.currentIndex === 0 && this._renderClassIntroduction()

                            }
                            {
                                this.state.currentIndex === 1 && this._renderClassChapters()
                            }
                            {
                                this.state.currentIndex === 2 && this._renderClassComments()
                            }
                        </div>
                    </div>

                    {
                        data &&
                        <div className='buyWrap'>
                            <div className='buy' onClick={this._toBuy}>
                                立即购买
                                {/* <ImageBackground source={btnRed} style={[{
                                    width: utils.getScreenWidth(),
                                    height: utils.getScreenWidth() / 1011 * 219
                                }, globalStyles.center, globalStyles.center2]}>
                                    <p style={{color: '#fff', fontSize: 15, fontWeight: 'bold'}}>立即购买</p>
                                </ImageBackground> */}
                            </div>
                        </div>
                    }

                </div>
            </div>
          </div>
        </div>
        {this.renderPayModal()}
        {this.renderShareModal()}
      </div>
    );
  }

  share= async ()=> {
    let {data, offline, shareImage} = this.state;
    if (shareImage) {
        this.setState({
            showDistribution: true
        })
        return;
    }
    try {
        let params = {
            id: data.id,
            type: offline ? 3 : data.type == 1 ? 2 : 1
        };
        let result = await callGet('/public/share', params)
        this.setState({
            // type,
            shareImage: result.shareImage,
            showDistribution: true
        })
    } catch (e) {
        console.log(' to buy', e)
        message.error(e.errorMessage)
    }
  }

  hideShare =()=> {
    this.setState({
        showDistribution: false
    })
  }

  renderShareModal = () => {
    const { showDistribution, shareImage } = this.state;
    return (
        <DistributionModal
          showDistribution={showDistribution}
          shareImage={shareImage}
          close={this.hideShare}
        />
    );
  };

  renderPayModal = () => {
    const { isShowPayModal, data, payAmount, payQrcode, orderNumber } = this.state;
    return (
        <PayModal
         ref="paymodal"
         isShowPayModal={isShowPayModal}
         payAmount={payAmount}
         payQrcode={payQrcode}
         orderNumber={orderNumber}
         title={'购买书籍'}
         desc={'购买书籍' + data.title}
         onClose={() => {
            this.setState({
              isShowPayModal: false,
            });
          }}
          callback={()=> {
            this.setState({
              isShowPayModal: false,
            });
            this._toLoadDetail()
            message.success('已成功付款，我们将尽快为你安排发货！')
          }}
         />
    );
  };

  _renderClassIntroduction = () => {
    let {data} = this.state;
    // console.log('_renderClassIntroduction ', data)
    return (
        <div>
            {/* {this.state.data && this.state.data.groupPurchases && this.state.data.groupPurchases.map(
                item => <PurchasesItem
                    owner={item.owner}
                    title={item.title}
                    groupPrice={item.groupPrice}
                    remainCount={item.remainCount}
                    current={item.current}
                    expiry={item.expiry}
                    participated={item.participated}
                />)} */}

            {
                data.organization && <div>
                    <div className='org'>
                        <img className='org_avatar' src={data.organization.avatarUrl || '' } alt='icon'/>
                        <div style={{marginLeft: 6}}>
                            <p
                                style={{color: '#363636', fontSize: 12}}>{this.state.data.organization.name}</p>
                            <p style={{
                                color: '#959595',
                                fontSize: 10,
                                marginTop: 5
                            }}>{this.state.data.organization.introduction}</p>
                        </div>
                    </div>
                    <div className='line'/>
                </div>
            }

            <div className='tag'>
                {
                    this.state.data && this.state.data.tags && this.state.data.tags.map(item => <div style={{
                        backgroundColor: '#FFC600',
                        borderRadius: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 20
                    }}>
                        <p style={{
                            color: '#000000',
                            fontSize: 8,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}>{item}</p>
                    </div>)
                }
            </div>

            <div className='line'/>

            <div>
                {/* <img source={book} style={{width: 12, height: 12 / 58 * 45}}/> */}
                <p style={{color: '#363636', fontSize: 12, marginLeft: 8}}>课程简介</p>
            </div>
            <div>
                {/* <HTMLView
                    value={this.state.data.description}
                /> */}
                {data.description}
            </div>
            {
                data.tableOfContentUrls && data.tableOfContentUrls.length > 0 && data.tableOfContentUrls.map((item, index) => 
                    <img key={'catalog'+index} style={{width: '100%'}} src={item.imageUrl} alt=''/>
                )
            }
        </div>
    );
}

_renderClassChapters = () => {
    let { data } = this.state;
    console.log('_renderClassChapters', data)
    return (
        // <View style={globalStyles.marginLeft20}>
        //     <View style={[globalStyles.horizontal_start_container, globalStyles.center2]}>
        //         <Image source={chapters} style={{width: 20, height: 20 / 48 * 52}}/>
        //         <Text style={{color: '#363636', fontSize: 12, marginLeft: 10}}>课程目录</Text>
        //     </View>

        //     {
        //         data && data.chapters && data.chapters.length > 0 &&
        //         <SectionList
        //             style={{backgroundColor: '#FFFFFF'}}
        //             ref="sectionList"
        //             initialNumToRender={20}
        //             renderSectionHeader={this._renderSectionHeader}
        //             renderItem={this._renderSectionItem.bind(this)}
        //             sections={data.chapters}
        //             getItemLayout={this._getItemLayout}
        //             keyExtractor={(item, index) => index.toString()}
        //             ItemSeparatorComponent={this._separatorComponent}
        //             ListFooterComponent={this._listFooterComponent}
        //         />
        //     }
        // </View>
        <div></div>
    );
}

}
