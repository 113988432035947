const initialized = {
    name: '南京市',
    code: '3201'
}

export const city = (state = initialized, action ) => {
    switch (action.type) {
        case 'UPDATE_USER':
            return {
                ...state,
                ...action.data
            }
        case 'UPDATE_CITY':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }
}

export const user = (state = {}, action ) => {
    switch (action.type) {
        case 'UPDATE_USER':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }
}