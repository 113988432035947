import React from "react";
import "./index.scss";
import { message, Button} from "antd";
import * as utils from 'utils/utils';
import {getUser} from 'utils/authority'
import { callGet, callPost, getAddress as getAddressRemote } from "service/api";
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'
import DistributionModal from 'components/distributionModal'
import CommissionButton from 'components/commissionButton'
import PayMethodModal from 'components/payMethodModal'
import constants from "../../../../utils/constants";
import CardPayAvaliable from "components/cardPayAvaliable";

// @connect(({ course,login, loading }) => ({
//   course,
//   login,
//   loading: loading.effects['course/getList']
// }))
export default class FileDetailPage extends React.Component {
  constructor(props) {
    super(props);
    // console.log(' GoodsDetailPage ', props, props.location);
    const {id} = props.match.params;
    this.state = {
      data: {productId: id},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      isShowPayModal: false
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {
    this._toLoadDetail()
  }

  _toLoadDetail = async () => {
    let {data} = this.state;
    console.log('load detail', data.productId)
    let result
    try {
        result = await callGet(`/public/quizFiles/${data.productId}`)
        this.setState({
          data: result || {},
        })
    } catch (e) {
        console.log('e', e)
        //utils.showToast(e.errorMessage)
    }
    }

    _back = ()=> {
        this.props.history.goBack()
    }

    isBuyed = () => {
        let userInfo = getUser()
        let {data} = this.state;
        if (data && (data.buyFlag === 1 || data.originalPrice === 0 || (userInfo.isVip === 1 && data.vipPrice === 0))) {
            return true;
        }
        return false;
    }

    download = async()=> {
        let {data} = this.state;
        let {type=1} = this.props.location.state || {};
        if (!this.isBuyed()) {
            this._toBuy()
            return;
        }
        try {
            let result = await callGet(`/qzuiFiles/${data.id}/download`, {type})
            window.open(result.url)
        } catch (e) {
            console.log(' to buy', e)
            message.error(e.errorMessage)
        }
    }

    _toBuy = async () => {
        this.setState({
            isShowPayMethod: true
        })
      }

      _toPay = async () => {
        let add = undefined ; //getAddressCache()
        if (!add) {
            add =  await getAddressRemote()
           if (!add) {
               message.error('购买前请先移步至‘我的’页，完善资料，添加收货地址。')
               return;
           }
        }

        let {data} = this.state;
        const isVIPCardPay = this.refs.paymethodmodal.isPayCard();
        try {
            let orderResult;
            let params = {};
            params.itemId = data.id;
            params.num = 1;
            params.itemType = constants.products.DOCUMENTS;
            params.payment = isVIPCardPay ? '2' : '0'
            params.addressId = add.id;

            if (isVIPCardPay) {
                let cardInfo = this.refs.paymethodmodal.getVIPCardInfo();
                params.cardNo = cardInfo.cardVipId
                params.cardPassword = cardInfo.cardVipPwd
            }

            orderResult = await callPost('/purchase', params)
            if (!isVIPCardPay) {
                const alipayInfo = await callPost('/alipay/payOrder', {tradeType: 2, balance: orderResult.paidAmount, orderNumber: orderResult.orderNumber})
                // console.log(' alipay info', alipayInfo)
                this.setState({
                    // type,
                    payAmount: orderResult.paidAmount,
                    orderNumber: orderResult.orderNumber,
                    isShowPayModal: true,
                    isShowPayMethod: false,
                    payQrcode: alipayInfo.payParam
                }, ()=> this.refs.paymodal.pollPayResult(orderResult.orderNumber))
            } else {
                message.success('购买成功')
                this.setState({
                    isShowPayMethod: false
                })
                this._toLoadDetail()
            }
        } catch (e) {
            console.log(' to buy', e)
            message.error(e.errorMessage)
        }
    }

    _toBuy2 = async () => {
        let {data} = this.state;
        try {
            let add = undefined ; //getAddressCache()
            if (!add) {
                add =  await getAddressRemote()
               if (!add) {
                   message.error('购买前请先移步至‘我的’页，完善资料，添加收货地址。')
                   return;
               }
            }
            let orderResult;
            let params = {};
            params.itemId = data.id;
            params.num = 1;
            params.addressId = add.id;
            params.itemType = 10;
            orderResult = await callPost('/purchase', params)
            const alipayInfo = await callPost('/alipay/payOrder', {tradeType: 2, balance: orderResult.paidAmount, orderNumber: orderResult.orderNumber})
            // console.log(' alipay info', alipayInfo)
            this.setState({
                // type,
                payAmount: orderResult.paidAmount,
                orderNumber: orderResult.orderNumber,
                isShowPayModal: true,
                payQrcode: alipayInfo.payParam
            }, ()=> this.refs.paymodal.pollPayResult(orderResult.orderNumber))
        } catch (e) {
            console.log(' to buy', e)
            message.error(e.errorMessage)
        }
    }

    _to88vipPay = ()=> {
        this.props.history.push('/user/vippay')
    }

  render() {
    // const { course, loading} = this.props;
    const {sources, data, currentIndex } = this.state;
    const user = getUser()
    console.log('render', data)
    return (
        <div className="user-learning-page-background">
          <div className="user-learning-page-container">

          <BackButton/>
            <div style={{marginTop: 10}}>
            {/* <img src={data.imageUrl} alt="" style={{width: 120}}></img> */}
            <p>{data.title}</p>
            </div>
            <div className='course'>
                <div style={{marginBottom: 24}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
                        <div style={{flex:1, flexShrink: 1}}>
                            <div
                                style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                                <p style={{color: '#000000', fontSize: 10}}>原价：</p><p style={{
                                    textDecorationLine: 'line-through',
                                    color: '#959595',
                                    fontSize: 14
                                }}>{utils.changeMoney(this.state.data.originalPrice)}</p>
                                <p style={{color: '#000000', fontSize: 10, marginLeft: 12}}>会员价：</p>
                                <p style={{
                                        color: '#E8B400',
                                        fontSize: 17
                                    }}>{utils.changeMoney(this.state.data.vipPrice)}</p>
                                {data.commission != undefined && data.commission != '' && data.commission > 0 && <CommissionButton commission={data.commission} onClick={this.share}/>}
                                {data.payCardFlag === 1 && <CardPayAvaliable />}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <div className='vip' onClick={()=> this._to88vipPay()}>
                                {user && user.isVip === 1 ? '会员续费' : '开通88会员'}
                            </div>
                            <div className='buy' onClick={this.download}>
                                {this.isBuyed() ? '立即下载' : '购买'}
                            </div>
                        </div>
                    </div>
                    <div className='line'/>

                    {
                        this._renderClassIntroduction()
                    }
                </div>
{/* 
                    {
                        data &&
                        <div className='buyWrap'>
                            <div className='buy' onClick={this.download}>
                                {this.isBuyed() ? '立即下载' : '购买'}
                            </div>
                        </div>
                    } */}

                </div>
        </div>
        {this.renderPayMethodModal()}
        {this.renderPayModal()}
        {this.renderShareModal()}
      </div>
    );
  }

  renderPayMethodModal = () => {
    const { isShowPayMethod, data } = this.state;
    return (
    <PayMethodModal
        ref="paymethodmodal"
        type={constants.products.DOCUMENTS}
        payCardFlag={data.payCardFlag}
        isShowPayModal={isShowPayMethod}
        title={'选择支付方式'}
        onClose={() => {
           this.setState({
            isShowPayMethod: false,
           });
         }}
        onOk={(value)=> {
            // this.setState({
            //     isShowPayMethod: false,
            //    });
            this._toPay()
        }}
        />
    );
  };

  share= async ()=> {
    let {data, offline, shareImage} = this.state;
    if (shareImage) {
        this.setState({
            showDistribution: true
        })
        return;
    }
    try {
        let params = {
            id: data.id,
            type: 5
        };
        let result = await callGet('/public/share', params)
        this.setState({
            // type,
            shareImage: result.shareImage,
            showDistribution: true
        })
    } catch (e) {
        console.log(' to buy', e)
        message.error(e.errorMessage)
    }
  }

  hideShare =()=> {
    this.setState({
        showDistribution: false
    })
  }

  renderShareModal = () => {
    const { showDistribution, shareImage } = this.state;
    return (
        <DistributionModal
          showDistribution={showDistribution}
          shareImage={shareImage}
          close={this.hideShare}
        />
    );
  };

  renderPayModal = () => {
    const { isShowPayModal, data, payAmount, payQrcode, orderNumber } = this.state;
    return (
        <PayModal
         ref="paymodal"
         isShowPayModal={isShowPayModal}
         type={1}
         payAmount={payAmount}
         payQrcode={payQrcode}
         orderNumber={orderNumber}
         title={'购买书籍'}
         desc={'购买书籍' + data.title}
         onClose={() => {
            this.setState({
              isShowPayModal: false,
            });
          }}
          callback={()=> {
            this.setState({
              isShowPayModal: false,
            });
            this._toLoadDetail()
            message.success('已成功付款，我们将尽快为你安排发货！')
          }}
         />
    );
  };

  _renderClassIntroduction = () => {
    let {data} = this.state;
    // console.log('_renderClassIntroduction ', data)
    return (
        <div>
            {/* {this.state.data && this.state.data.groupPurchases && this.state.data.groupPurchases.map(
                item => <PurchasesItem
                    owner={item.owner}
                    title={item.title}
                    groupPrice={item.groupPrice}
                    remainCount={item.remainCount}
                    current={item.current}
                    expiry={item.expiry}
                    participated={item.participated}
                />)} */}

            {
                data.organization && <div>
                    <div className='org'>
                        <img className='org_avatar' src={data.organization.avatarUrl || '' } alt='icon'/>
                        <div style={{marginLeft: 6}}>
                            <p
                                style={{color: '#363636', fontSize: 12}}>{this.state.data.organization.name}</p>
                            <p style={{
                                color: '#959595',
                                fontSize: 10,
                                marginTop: 5
                            }}>{this.state.data.organization.introduction}</p>
                        </div>
                    </div>
                    <div className='line'/>
                </div>
            }

            <div className='tag'>
                {
                    this.state.data && this.state.data.tags && this.state.data.tags.map(item => <div style={{
                        backgroundColor: '#FFC600',
                        borderRadius: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 20
                    }}>
                        <p style={{
                            color: '#000000',
                            fontSize: 8,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}>{item}</p>
                    </div>)
                }
            </div>

            <div className='line'/>

            <div>
                {/* <img source={book} style={{width: 12, height: 12 / 58 * 45}}/> */}
                <p style={{color: '#363636', fontSize: 12, marginLeft: 8}}>课件简介</p>
            </div>
            <div>
                {/* <HTMLView
                    value={this.state.data.description}
                /> */}
                {data.description}
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {
                data.chapters && data.chapters.map((item, index) => this.renderListitem(item, index))
                }
            </div>
            {
                data.tableOfContentUrls && data.tableOfContentUrls.length > 0 && data.tableOfContentUrls.map((item, index) => 
                    <img key={'catalog'+index} style={{width: '100%'}} src={item.imageUrl} alt=''/>
                )
            }
        </div>
    );
}

parseFilename(url='') {
    let segs = url.split('/');
    console.log(' segs ', segs)
    let last;
    if (segs && segs.length > 0) {
        last = segs[segs.length -1]
    }
    return this.parseExtension(last)
}

parseExtension(name) {
    let last = name.split('.')
    let filename, extension;
    if (last && last.length > 0) {
        filename = last[0]
        extension = last[1]
    }
    if (!filename) {
        filename = new Date.getTime()
    }
    if (!extension) {
        extension = 'pdf'
    }
    return {filename, extension}
}

  getFileSize( bytes ) {
    if (isNaN(bytes)) {
        return ''
    }
    if (bytes / (1024 * 1024 * 1024) >= 1) {
        // return (bytes / (1024 * 1024 * 1024)).toFixed(2)
        return (parseFloat(bytes / (1024 * 1024 * 1024))).toFixed(2) + 'G'
    } else if (bytes / (1024 * 1024) >= 1) {
        return (parseFloat(bytes / (1024 * 1024))).toFixed(2) + 'M'
    } else {
        return (parseFloat(bytes / (1024))).toFixed(2) + 'K'
    }
  }


renderListitem = (item, index) => {
    let file = this.parseExtension(item.title)
    let extensionImg
    switch (file.extension) {
      case 'doc':
      case 'docx':
        extensionImg = 'https://img.syyc.top/h5/doc.png'
        break;
      case 'pdf':
        extensionImg = 'https://img.syyc.top/h5/pdf.png'
        break;
      case 'ppt':
      case 'pptx':
        extensionImg = 'https://img.syyc.top/h5/ppt.png'
        break;
      default:
        extensionImg = 'https://img.syyc.top/h5/jpg.png'
    }
    let data = this.state.data;
    return (
            <div key={'catalog' + index} style={{backgroundColor: '#fff', display: 'flex', flexDirection: 'column'}}>
                <div style={{ paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <img style={{width: 30, height: 30 / 132 * 151}} src={extensionImg}/>
                        <span style={{color: '#363636', fontSize: 15, marginLeft: 10}}>{item.title}</span>
                    <div style={{flex: 1}}></div>
                    { item.fileSize && item.fileSize > 0 && <span style={{color: '#888', fontSize: 10, marginLeft: 10}}>{this.getFileSize(item.fileSize)}</span>}
                </div>
                {
                    index !== (data.chapters.length - 1) && <div style={{height: 2, backgroundColor: '#f5f5f5'}}/>
                }
        </div>
    )
}
}
