import React from "react";
import {
  Card,
  Tabs,
  Button,
  Row,
  Col,
  Breadcrumb,
  Icon,
  Divider,
  Pagination
} from "antd";
import UserJobNavBar from "components/job-navbar";
import UserBanner from "components/banner";
import LogoImage from 'images/logo/syyc-logo.png';

const { TabPane } = Tabs;

export default class UserJobCareerKitPage extends React.Component {
  render() {
    return (
      <div>
        <UserJobNavBar tabkey='3'/>
        <UserBanner />
        <div className="user-job-page-background">
          <div className="user-job-page-container">
          <Card bordered={false} style={{width:'70%', margin:'auto'}}>

             <Row gutter={16}>
              <Col span={4} >  <img src={LogoImage} alt="" style={{width:'60px',height:'60px'}}/></Col>
              <Col span={4}> <br/><p style={{fontSize:'20px'}}>链课号</p></Col>
              <Col span={16} ><p style={{paddingTop:'13px'}}>链课旨在帮助安徽就开始大恒科技瓦大大哈安抚读卡和温度而恢复，安慰法爱车屋反浩克符合擦大武口的呼呼大发掘，无法阿汗库尔合啊诶. &nbsp; &nbsp;<Button size="small" type="primary">了解更多</Button></p> </Col>
            
            </Row>
          <Divider type="horizontal" />
          <Row gutter={16}>
              <Col span={4} >  <img src={LogoImage} alt="" style={{width:'60px',height:'60px'}}/></Col>
              <Col span={4}> <br/><p style={{fontSize:'20px'}}>辅答</p></Col>
              <Col span={16} ><p style={{paddingTop:'13px'}}>链课旨在帮助安徽就开始大恒科技瓦大大哈安抚读卡和温度而恢复，安慰法爱车屋反浩克符合擦大武口的呼呼大发掘，无法阿汗库尔合啊诶. &nbsp; &nbsp;<Button size="small" type="primary">了解更多</Button></p> </Col>
            
            </Row>
          </Card>

         
           
          </div>
        </div>
      </div>
    );
  }
}
