import React from "react";
import {
  Menu,
  Icon,
  Row,
  Col,
} from "antd";
import "./index.scss";
import { Link } from "react-router-dom";


export default class UserLearningNavBar extends React.Component {
  render() {
    return (
      <div>
          <div className="user-learning-navbar" 
          style={{
                  lineHeight: "40px",
                  width: "100%",
                  backgroundColor: "#FFA70F",
                  color: "#ffffff",
                }}>

            <Row>

              <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys={[this.props.tabkey || '1']}
                style={{
                  lineHeight: "40px",
                   maxWidth:'1100px',
                   margin:'auto',
                  backgroundColor: "#FFA70F",
                  color: "#ffffff",
                  textAlign:'center'
                }}
              >
                <Menu.Item className="layout-menu-item" key="1">
                  <Link to="/user/learning">
                    线上视频课
                  </Link>
                </Menu.Item>

                <Menu.Item className="layout-menu-item" key="2">
                  <Link to="/user/learning/onsite">
                    线下面授课
                  </Link>
                </Menu.Item>

                <Menu.Item className="layout-menu-item" key="3">
                  <Link to="/user/learning/quizfile">
                    考试题库
                  </Link>
                </Menu.Item>
{/* 
                <Menu.Item className="layout-menu-item" key="4">
                  <Link to="/user/learning/quiz">
                    刷题练习
                  </Link>
                </Menu.Item> */}

                <Menu.Item className="layout-menu-item" key="5">
                  <Link to="/user/learning/shop">
                    教材商城
                  </Link>
                </Menu.Item>

              </Menu>
            </Row>
          </div>
          </div>
    );
  }
}


