import React, { Component } from 'react';
// import { connect } from 'dva';
// import { FormattedMessage } from 'umi/locale';
// import router from 'umi/router';
// import Link from 'umi/link';
import { Alert, message } from 'antd';
import Login from 'components/Login';
import './Login.less';
import { Link, Route, Router } from "react-router-dom";
import {callGet} from 'service/api'
import {createHash} from "utils/md5";
import {setToken, setUser} from 'utils/authority'
import BackButton from 'components/backButton'
import { updateUser } from '../../../reduxs/actions';
import { connect } from "react-redux";

const { UserName, Password, Submit } = Login;

// @connect(({ login, loading }) => ({
//   login,
//   submitting: loading.effects['login/login'],
// }))
class LoginPage extends Component {
  state = {
    type: 'account',
    // autoLogin: true,
    submitting: false
  };

  onTabChange = type => {
    this.setState({ type });
  };

  handleSubmit = async (err, values) => {
    if (!err) {
      this.setState({
        submitting: true
      })
      try {
        const result = await callGet('/login', {
          type: 1,
          username: values.userName,
          password: createHash(values.password),
        })
        if (result) {
          console.log(' to save user', result)
          setUser(result.user);
          setToken(result.token);
          this.props.toUpdateUser(result.user)
        }
        this.props.history.goBack();
      } catch (e) {
        message.error(e.errorMessage)
      }
      this.setState({
        submitting: false
      })
    }
  };

  // changeAutoLogin = e => {
  //   this.setState({
  //     autoLogin: e.target.checked,
  //   });
  // };

  renderMessage = content => (
    <Alert style={{ marginBottom: 24 }} message={content} type="error" showIcon />
  );

  render() {
    // const { submitting } = this.props;
    const { type, submitting } = this.state;
    console.log(' render submitting', submitting)
    return (
      <div className='main'>
        <BackButton/>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <h3>
            {/* <FormattedMessage id="app.register.register" /> */}
            登录
          </h3>
        </div>
        <Login
          defaultActiveKey={type}
          onTabChange={this.onTabChange}
          onSubmit={this.handleSubmit}
          ref={form => {
            this.loginForm = form;
          }}
        >
          {/* <Tab key="account" tab={formatMessage({ id: 'app.login.tab-login-credentials' })}>
            {login.status === 'error' &&
              login.type === 'account' &&
              !submitting &&
              this.renderMessage('账户或密码错误（admin/888888）')}
            <UserName name="userName" placeholder="admin/user" />
            <Password
              name="password"
              placeholder="888888/123456"
              onPressEnter={() => this.loginForm.validateFields(this.handleSubmit)}
            />
          </Tab> */}
          <UserName name="userName" placeholder="请输入手机号" enter={this.handleSubmit} />
          <Password name="password" placeholder="请输入密码" enter={this.handleSubmit} />
          {/* <Tab key="mobile" tab={formatMessage({ id: 'app.login.tab-login-mobile' })}>
            {login.status === 'error' &&
              login.type === 'mobile' &&
              !submitting &&
              this.renderMessage('验证码错误')}
            <Mobile name="mobile" />
            <Captcha name="captcha" countDown={120} onGetCaptcha={this.onGetCaptcha} />
          </Tab> */}
          {/* <div>
            <Checkbox checked={autoLogin} onChange={this.changeAutoLogin}>
              <FormattedMessage id="app.login.remember-me" />
            </Checkbox>
            <a style={{ float: 'right' }} href="">
              <FormattedMessage id="app.login.forgot-password" />
            </a>
          </div> */}
          <Submit loading={submitting}>
            {/* <FormattedMessage id="app.login.login" /> */}
            登录
          </Submit>
          <div className='other'>
            {/* <FormattedMessage id="app.login.sign-in-with" /> */}
            {/* <Icon type="alipay-circle" className={styles.icon} theme="outlined" />
            <Icon type="taobao-circle" className={styles.icon} theme="outlined" />
            <Icon type="weibo-circle" className={styles.icon} theme="outlined" /> */}
            <Link className='register' to="/user/resetPassword" style={{marginLeft: 10, marginRight: 10}}>
              {/* <FormattedMessage id="app.login.signup" /> */}
              忘记密码
            </Link>
            <Link className='register' to="/user/register">
              {/* <FormattedMessage id="app.login.signup" /> */}
              注册
            </Link>
          </div>
        </Login>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {} = state;
  return {
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toUpdateUser: user => dispatch(updateUser(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (LoginPage);