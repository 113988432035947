import React, { PureComponent, Fragment } from 'react';
// import FileBase64 from 'react-file-base64';
// import { connect } from 'dva';

import { Button, Icon, message } from 'antd';
import styles from './index.less';

// @connect(({ fileupload }) => ({
//   fileupload,
// }))
class FileBase extends PureComponent {
  state = {};

  componentDidMount() {}

  onDone = e => {
    const { dispatch, onChange } = this.props;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64 = reader.result ? reader.result.split(',')[1] : ''; // base64就是图片的转换的结果
      if(!base64) {
        message.error(`上传失败,请重新上传`);
        onChange('')
        return
      }
      dispatch({
        type: 'fileupload/violationProofUpload',
        payload: {
          proofImage: base64,
        },
      }).then(response => {
        if (response) {
          message.success('上传成功');
          onChange(response);
        }
      });
    };
  };

  render() {
    const { value } = this.props;
    return (
      <Fragment>
        <a href="javascript:;" className={styles.file}>
          选择文件
          <input
            accept="image/*"
            id="FileBasInput"
            name="upimage"
            value=""
            onChange={this.onDone}
            type="file"
          />
        </a>
        <div style={{ display: 'flex', lineHeight: '16px', fontSize: 14, color: '#9E9E9E' }}>
          <a style={{ flex: 1 }} href={value} target="_blank" rel="noopener noreferrer">{value}</a>
          {value && (
            <Icon
              type="close"
              style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
              theme="outlined"
              onClick={() => this.props.onChange('')}
            />
          )}
        </div>
      </Fragment>
    );
  }
}
export default FileBase;
