import React, { Component, PureComponent } from 'react';
import { Col } from 'antd';
import './index.scss';
import {changeMoney} from 'utils/utils'

export default class Goods extends PureComponent{
    render() {
      const {id, imageUrl, title, description, originalPrice, soldCount, onClick=()=>{} } = this.props;
        return (
          <Col onClick={onClick} xs={24} md={6} xl={5}  key={`course-item-${id}`}>
            <img src={imageUrl} alt="" style={{ height:'200px',width:'200px',borderRadius: '8px', marginTop:'10px'}}/>
            <div style={{width: '200px', display: 'flex', flexDirection: 'column'}}>
              <span className='twolinetxt' style={{color:'#212121', fontWeight: 'bold', letterSpacing: '2px'}}>{title}</span>
              <span className='twolinetxt'>{description}</span>
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <p style={{color:'#FFA70F',fontWeight: 'bold'}}>{changeMoney(originalPrice)}</p>
                <p>{`${soldCount}人购买`}</p>
              </div>
            </div>
          </Col>
        );
    }
}