import React, { PureComponent } from 'react';
import { Carousel } from 'antd';
import './index.scss';

export default class UserBanner extends PureComponent{

  onItemClick = (item)=> {
    const {url} = item.params
    if (url) {
      window.open(url)
    }
  }

  render() {
    const {banners=[]} = this.props;
    console.log(' banner', this.props);
      return (
          <Carousel autoplay={false} className='carousel'>
          {
            banners && banners.map(item =>
              <div key={'banner' + item.id} style={{width: '100%', height: '100%'}} onClick={()=> this.onItemClick(item)}>
                <img src={item.imageUrl} alt="" style={{ height: '300px', width: '100%', margin: '0 auto', objectFit: 'cover'}}/>
              </div>
            )
          }
          {/* <div className={styles.carousel}>
            <h3>1</h3>
          </div>
          <div className={styles.carousel}>
            <h3>2</h3>
          </div>
          <div className={styles.carousel}>
            <h3>3</h3>
          </div>
          <div className={styles.carousel}>
            <h3>4</h3>
          </div> */}
        </Carousel>
      );
  }
}