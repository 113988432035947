import React, { PureComponent, Fragment } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

class AdRangePicker extends PureComponent {
  state = {};

  componentDidMount() {
    console.log('valuexxx');
  }

  onChange = (momentTime, date) => {
    const { onChange } = this.props;
    const dateTime = [`${date[0]}`, `${date[1]}`];
    onChange(dateTime, momentTime);
  };

  render() {
    const { value } = this.props;
    const defaultValue = value ? [moment(value[0]), moment(value[1])] : '';
    return <RangePicker showTime style={{width:'100%'}} format="YYYY-MM-DD HH:mm:ss" value={defaultValue} allowClear={false} onChange={this.onChange} />;
  }
}
export default AdRangePicker;
