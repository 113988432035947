import React from "react";
import "./index.scss";
import { Card, Tabs, Button, Row, Pagination, Input} from "antd";
import UserLearningNavBar from "components/learning-navbar";
import QuizFile from "components/quizFile";
import MyTags from 'components/myTags'
import constants from "utils/constants";
import { getUser, getToken } from 'utils/authority'
import { callGet } from 'service/api'
import UserJobNavBar from "components/job-navbar";
import { connect } from 'react-redux'

import _ from 'lodash'
const Search = Input.Search

class UserQuizFilePage extends React.Component {

  state ={
    pageSize: 20,
    data: [],
    search: ''
  }

  componentDidMount() {
    this._toLoadDetail()
  }

  componentWillReceiveProps(nextProps) {
    // console.log('newprops', newProps)
    // console.log(' props ', this.props)
    if (nextProps.jobCategory.id != this.props.jobCategory.id) {
      this.setState({
        city: nextProps.city
      }, this._toLoadDetail)
    }
  }

  handleSubmit = async (values) => {
    this._toLoadDetail()
  };

  handleFormReset = () => {
    for (let i = 1; i< 4; i++) {
      this.refs["querytag" + i].reset()
    }
    this._toLoadDetail()
  };

  handlePageChange = async (page, pageSize)=> {
    console.log(' page ', page, pageSize)
    this._toLoadDetail(page)
  }

  _toLoadDetail = async (pageNo = 1) => {
    let { city, pageSize, search } = this.state;
    let {type=1} = this.props.location.state || {};
    let params =  { pageNo: pageNo -1, pageSize, type};

    for (let i = 1; i< 4; i++) {
      const selectValue = this.refs['querytag' + i].getValue()
      if (selectValue && selectValue.value != -1) {
        params[selectValue.key] = selectValue.value
      }
    }
    if (search) {
      params.keyword = search
    }
    let result
    try {
      result = await callGet('/public/web/quizFiles', params)
      this.setState({
        data: result,
      })
    } catch (e) {
      console.log('e', e)
      //utils.showToast(e.errorMessage)
    }
  }

  onCourseClick = (item) => {
    console.log(' course click', item)
    if (!getToken()) {
      this.props.history.push('/user/login')
    } else {
      let {type=1} = this.props.location.state || {};
      let path = {
        pathname: `/user/learning/fileDetail/${item.id}`,
        state: {
          id: item.id,
          type
        }
      }
      this.props.history.push(path)
    }
  }

  searchChange = (event)=> {
    console.log(' search change ', event)
    this.setState({
      search: event.target.value
    }, this._toLoadDetail)
  }

  render() {
    let {data, pageSize} = this.state;
    let {type=1} = this.props.location.state || {};
    return (
      <div>
        {type == 1 ? <UserLearningNavBar tabkey='3'/> : <UserJobNavBar tabkey='3'/>}
        {/* <UserBanner /> */}
        <div className="user-learning-page-background">
          <div className="user-learning-page-container">

          <Search
            placeholder="输入关键字搜索文档"
            // onSearch={this.searchChange}
            // handleChange={this.searchChange}
            onChange={this.searchChange}
            allowClear={true}
            style={{ width: 200 }}
          />
          <MyTags ref="querytag1" queryKey='quizStageType' title='年级阶段' tags={constants.GRADE}/>
          <MyTags ref="querytag2" queryKey='quizSubjectType' title='选择科目' tags={type === 1 ? constants.SUBJECT_TEACHERS_ARRAY : constants.SUBJECT_ARRAY}/>
          <MyTags ref="querytag3" queryKey='fileType' title='选择课件类型' tags={constants.FILE_TYPE}/>

          <div style={{display:'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <span style={{  marginTop: 12, marginBottom: 24 }}>
            <Button style={{ marginLeft: 8 }} type="primary" onClick={this.handleSubmit}>
              查询
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={this.handleFormReset}>
              重置
            </Button>
          </span>
          </div>
          
          <Card bordered={true} className='row'>
            <Row gutter={8}>
              {data.list && data.list.length > 0 && data.list.map((item, index) => 
                <QuizFile key={'job' + item.id} index={index} {...item} onClick={() => this.onCourseClick(item)} />
              )}
            </Row>
          </Card>

          <Pagination
            className='pagination'
            total={data.totalElements || 0}
            showTotal={total => `共 ${data.totalElements || 0} 条`}
            pageSize={pageSize}
            defaultCurrent={1}
            onChange={this.handlePageChange}
          />
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  // console.log(' mapStateToProps', state)
  const { jobCategory } = state;
  return {
    jobCategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserQuizFilePage)
