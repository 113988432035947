
const CONFIG = {
  android: 'http://zhushou.360.cn/detail/index/soft_id/4171293', // com.xiaoxiao.shihaoo // com.syyc.app.syyc // https://sj.qq.com/myapp/detail.htm?apkName=com.syyc.app.syyc
  ios: 'https://apps.apple.com/cn/app/id1466189933',
  scheme: 'syyc://com.syyc.app.syyc'
};

const COMMONS = {
  orgAvatar: 'https://img.syyc.top/h5/org_avatar.png'
}

const GRADE = [
  {
      name: '全部', value: -1
  },
  {
      name: '幼儿园', value: 1
  },
  {
      name: '小学', value: 2
  },
  {
      name: '中学', value: 3
  },
  {
      name: '其他', value: 99
  }
]

const FILE_TYPE = [
  {
      name: '全部', value: -1
  },
  {
      name: 'WORD', value: 1
  },
  {
      name: 'PDF', value: 2
  },
  {
      name: 'PPT', value: 3
  },
]

const SALARY_ARRAY = [
  { name: '面议', value: 99 },
  {
      name: '3k以下', value: 1
  },
  {
      name: '3k-5k', value: 2
  },
  {
      name: '5k-10k', value: 3
  },
  {
      name: '10k-20k', value: 4
  },
  {
      name: '20k以上', value: 5
  },
]

const SORTBY_ARRAY = [
  {
      name: '综合', value: 99
  },
  {
      name: '时间', value: 2
  },
  {
      name: '薪资', value: 2
  },
  {
      name: '距离', value: 3
  },
]

const HIGHLIGHT_ARRAY = [
  {
      name: '职称评定',
      value: 1,
  },
  {
      name: '培训进修',
      value: 2,
  },
  {
      name: '靠近地铁',
      value: 3,
  },
  {
      name: '五险一金',
      value: 4,
  },
  {
    name: '包吃包住',
    value: 5,
  },
  {
    name: '企业年金',
    value: 6,
  },
]

const EDUCATION_ARRAY = [
  {
      name: '不限',
      value: 99
  },
  {
      name: '高中',
      value: 1
  },
  {
      name: '大专',
      value: 2
  },
  {
      name: '本科',
      value: 3
  },
  {
      name: '硕士',
      value: 4
  },
  {
      name: '博士',
      value: 5
  }
]

const WORK_YEARS_ARRAY = [
  {
      name: '不限',
      value: 99
  },
  {
      name: '大学生',
      value: 1
  },
  {
      name: '1年以内',
      value: 2
  },
  {
      name: '1-3年',
      value: 3
  },
  {
      name: '3-5年',
      value: 4
  },
  {
      name: '5-10年',
      value: 5
  },
  {
      name: '10年以上',
      value: 6
  }
]

const GENDER_ARRAY = [
  {
      name: '不限',
      value: 99,
  },
  {
      name: '男',
      value: 1,
  },
  {
      name: '女',
      value: 2,
  }
]

const JOB_TYPE_ARRAY = [
  {
      name: '不限',
      value: 99,
  },
  {
      name: '全职',
      value: 1
  },
  {
      name: '兼职',
      value: 2
  }
]

const ORG_TYPE_ARRAY = [
  {
      name: '公办', value: 1
  },
  {
      name: '民办', value: 2
  },
  // {
  //     name: '私企', value: 3
  // },
  // {
  //     name: '外企', value: 4
  // }
]

const ORG_SCALE_ARRAY = [
  {
      name: '0-20人', value: 1
  },
  {
      name: '20-50人', value: 2
  },
  {
      name: '50-100人', value: 3
  },
  {
      name: '100人以上', value: 4
  }
]

const SUBJECT_ARRAY = [
  {
    name: '不限', value: -1
  },
  {
      name: '语文', value: 1
  },
  {
      name: '数学', value: 2
  },
  {
      name: '英语', value: 3
  },
  {
      name: '物理', value: 4
  },
  {
      name: '生物', value: 5
  },
  {
      name: '化学', value: 6
  },
  {
      name: '体育', value: 7
  },
  {
      name: '音乐', value: 8
  },
  {
      name: '美术', value: 9
  },
  {
      name: '历史', value: 10
  },
  {
      name: '政治', value: 11
  },
  {
      name: '其他', value: 99
  },
]

const SUBJECT_TEACHERS_ARRAY = [
  {
      name: '全部科目',
      value: -1,
      code: -1
  },
  {
      name: '综合素质',
      value: 1,
      code: 1
  },
  {
      name: '教育知识与能力',
      value: 2,
      code: 2
  },
  {
      name: '保教知识与能力',
      value: 3,
      code: 3
  },
  {
      name: '学科知识与能力',
      value: 4,
      code: 4
  },
  {
      name: '教育教学知识与能力',
      value: 5,
      code: 5
  },
  {
      name: '其他',
      value: 99,
      code: 99
  },
]

const WORK_STATUS = [
  {
      name: '离职-随时到岗',
      value: 0
  },
  {
      name: '在职-月内到岗',
      value: 1
  },
  {
      name: '在职-考虑机会',
      value: 2
  },
  {
      name: '在职-暂不考虑',
      value: 3
  }
]

function getWorkYears(value) {
  let salary = '不限'
  WORK_YEARS_ARRAY.forEach(item => {
    if (item.value === value) {
      salary = item.name;
      return;
    }
  })
  return salary;
}

function getEducation(value) {
  let salary = '不限'
  EDUCATION_ARRAY.forEach(item => {
    if (item.value === value) {
      salary = item.name;
      return;
    }
  })
  return salary;
}

function getSalary(value) {
  let salary = '面议'
  SALARY_ARRAY.forEach(item => {
    if (item.value === value) {
      salary = item.name;
      return;
    }
  })
  return salary;
}

function getHighlights(tags) {
  let hights = []
  if (tags) {
    tags.forEach(tag => {
      HIGHLIGHT_ARRAY.forEach(item => {
        if (item.value == tag.highlights) {
          hights.push(item);
          return;
        }
      })
    })
  }
  return hights;
}

function getName(array, value) {
  if (array && array.length > 0) {
    let key;
    array.forEach(item => {
      if (item.value === value) {
        key = item.name;
        return;
      }
    })
    if (key == undefined) {
      return array[0].name;
    }
    return key;
  } else {
    return '';
  }
}

function getIndex(array, value) {
  if (array && array.length > 0) {
    let key;
    array.forEach((item, index) => {
      if (item.value === value) {
        key = index;
        return;
      }
    })
    if (key == undefined) {
      return 0;
    }
    return key;
  } else {
    return 0;
  }
}

/**
 * 
 * @param {*} subjects 整形数组
 */
function initSubjects(subjects) {
  return SUBJECT_ARRAY.map(item => {
    item.isSelected = subjects.includes(item.value)
  })
}

function chooseSubjects(subjects) {
  let subs = SUBJECT_ARRAY.filter(item => {
    return subjects.includes(item.value)
  })
  return subs || [];
}

function getSubjectSelectIndex(subjects) {
  let indexes = [];
  SUBJECT_ARRAY.forEach((item, index) => {
    if (subjects.includes(item.value)) {
      indexes.push(index);
    }
  })
  return indexes;
}

const EVENT_JOB_UPDATE = "event_job_update"
const EVENT_JOB_DELETE = "event_job_delete"
const EVENT_GOODS_BUY = "event_goods_buy"
const EVENT_VIDEO_BUY = "event_video_buy"
const EVENT_SCHOOL_UPDATE = "event_school_update"
const PAY_BUY_JOB = 1;
const PAY_VIDEO_COURSE = 0;
const PAY_OFFLINE_COURSE = 2;
const PAY_BUY_GOODS = 3;

const products = {
  JOB: 0,
  JOB_PLUS: 1,
  COURSE: 2,
  COURSE_SERIAL: 3,
  COURSE_OFFLINE: 4,
  GOODS: 5,
  MEMBERSHIP: 6,
  SCHOOL_VERIFY: 7,
  QUIZ: 9,
  DOCUMENTS: 10,
  COURSE_LIVE: 11
}

export default {
  CONFIG,
  COMMONS,
  WORK_STATUS,
  SORTBY_ARRAY,
  SALARY_ARRAY,
  getSalary,
  getHighlights,
  EDUCATION_ARRAY,
  HIGHLIGHT_ARRAY,
  getEducation,
  WORK_YEARS_ARRAY,
  getWorkYears,
  GENDER_ARRAY,
  JOB_TYPE_ARRAY,
  ORG_TYPE_ARRAY, 
  ORG_SCALE_ARRAY,
  SUBJECT_ARRAY,
  SUBJECT_TEACHERS_ARRAY,
  getName,
  getIndex,
  initSubjects,
  chooseSubjects,
  getSubjectSelectIndex,

  GRADE,
  FILE_TYPE,

  // event key
  EVENT_JOB_UPDATE,
  EVENT_JOB_DELETE,
  EVENT_GOODS_BUY,
  EVENT_SCHOOL_UPDATE,
  EVENT_VIDEO_BUY,

  // pay service
  PAY_BUY_JOB,
  PAY_OFFLINE_COURSE,
  PAY_BUY_GOODS,

  products
}
