import React, { PureComponent } from 'react';
import { Tag } from 'antd';
import './index.scss';

const { CheckableTag } = Tag;

export default class MyTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      selectedTags: [],
    };
    this.cleared = undefined
  }

  reset = () => {
    this.cleared = true
    this.setState({
      selectedTags: []
    })
  }

  getValue = ()=> {
    if (this.cleared) {
      return undefined
    }
    const {queryKey} = this.props;
    const {selectedTags} = this.state;
    if (selectedTags.length > 0 && selectedTags[0].value != 99) {
      return {
        key: queryKey,
        value: selectedTags[0].value
      }
    }
  }

  handleChange(tag, checked) {
    this.cleared = false
    const { selectedTags } = this.state;
    const {radio=true} = this.props;
    const nextSelectedTags = checked ?  radio ? [tag] : [...selectedTags, tag] : selectedTags.filter(t => t.name !== tag.name);
    console.log('You are interested in: ', nextSelectedTags);
    this.setState({ selectedTags: nextSelectedTags });
  }

  render() {
    const { selectedTags } = this.state;
    const {tags, title} = this.props;
    return (
      <div>
        <span style={{ marginRight: 8, fontWeight: 'bold' }}>{title}:</span>
        {tags.map(tag => (
          <CheckableTag
            key={tag.name}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => this.handleChange(tag, checked)}
          >
            {tag.name}
          </CheckableTag>
        ))}
      </div>
    );
  }
}