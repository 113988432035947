import React, { Component, PureComponent } from 'react';
import { Col } from 'antd';
import './index.scss';
import {changeMoney} from 'utils/utils'

export default class VideoCourse extends PureComponent{
    render() {
      const {id, courseImageUrl, organizationName, title, originalPrice, soldCount, onClick=()=>{}, type } = this.props;
        return (
          <Col onClick={onClick} xs={24} md={12} xl={6}  key={`course-item-${id}`}>
            <div className='cell' style={{position: 'relative'}}>
              <img src={courseImageUrl} alt="" className='cover'/>
              <div style={{width: '200px', display: 'flex', flexDirection: 'column'}}>
                <span style={{color:'#212121', fontWeight: 'bold', letterSpacing: '2px'}}>{title}</span>
                <span>{organizationName}</span>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <p style={{color:'#FFA70F',fontWeight: 'bold'}}>{changeMoney(originalPrice)}</p>
                  <p>{`${soldCount}人购买`}</p>
                </div>
                {type === 1 && <div style={{position: 'absolute', left: 0, top: 8, height: 20, background: '#FFA900', padding: '0 6px', color: '#fff', fontSize: 11, borderTopRightRadius: 10, borderBottomRightRadius: 10}}>系列课</div>}
              </div>
            </div>
          </Col>
        );
    }
}


// 'screen-xs': {
//   maxWidth: 575,
// },
// 'screen-sm': {
//   minWidth: 576,
//   maxWidth: 767,
// },
// 'screen-md': {
//   minWidth: 768,
//   maxWidth: 991,
// },
// 'screen-lg': {
//   minWidth: 992,
//   maxWidth: 1199,
// },
// 'screen-xl': {
//   minWidth: 1200,
//   maxWidth: 1599,
// },
// 'screen-xxl': {
//   minWidth: 1600,
// },