import React, { Component } from "react";
import {
  BrowserRouter,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

import UserLayout from "../components/layout";
import LoginLayout from "../components/login-layout";
import UserOnlineCoursePage from "./pages/learning";
import UserQuizFilePage from "./pages/learning/quizfile";
import UserOnsitePage from "./pages/learning/onsite";
import UserQuizPage from "./pages/learning/quiz";
import UserShopPage from "./pages/learning/shop";
import GoodsDetailPage from "./pages/learning/GoodsDetail";
import CoursesPage from "./pages/learning/courses";
import LiveCoursesPage from "./pages/learning/liveCourses";
import UserOnlineMoreCoursesPage from "./pages/learning/online-more-courses";
import CourseDetailPage from './pages/learning/courseDetail'
import FileDetailPage from './pages/learning/FileDetail'

import UserJobPage from "./pages/job";
import UserJobFreeCoursePage from "./pages/job/freecourse";
import UserJobQuickApplyPage from "./pages/job/quickapply";
import UserJobCareerKitPage from "./pages/job/careerkit";
import UserJobCareerCoursePage from "./pages/job/careercourse";
import UserJobDetailPage from "./pages/job/jobDetail";
import UserStudyPage from "./pages/study";
import UserCertPage from "./pages/cert";
import UserConfigPage from "./pages/config";
import UserMyJobPage from "./pages/myjob";
import UserMessagePage from "./pages/message";
import UserComplaintPage from "./pages/compaint";
import UserMyPage from "./pages/my";
import LoginPage from "./pages/user/Login";
import ResetPasswordPage from "./pages/user/ResetPassword";
import RegisterPage from "./pages/user/Register";
import VipPayPage from "./pages/my/vippay";
import MembershipPage from "./pages/membership";
import JoinusPage from "./pages/joinus";

import ResumeDetailPage from "./pages/my/resume/resumeDetail";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>)} /> 
    );


export default class Routes extends Component {
  render() {
    return (
  <div>
      <BrowserRouter>
        <Switch>
        <AppRoute exact path="/" layout={UserLayout} component={UserOnlineCoursePage} />
          {/* <AppRoute exact path="/admin" layout={AdminLayout} component={AdminHome} />
              <AppRoute exact path="/admin/vip" layout={AdminLayout} component={AdminVipPage} />
              <AppRoute exact path="/admin/order" layout={AdminLayout} component={AdminOrderPage} />
              <AppRoute exact path="/admin/shop" layout={AdminLayout} component={AdminShopPage} />
              <AppRoute exact path="/admin/course" layout={AdminLayout} component={AdminCoursePage} />
                   <AppRoute exact path="/admin/course/careercourse" layout={AdminLayout} component={AdminCareerCoursePage} />
                   <AppRoute exact path="/admin/course/recyclebin" layout={AdminLayout} component={AdminCourseRecycleBinPage} />
              <AppRoute exact path="/admin/quizfile" layout={AdminLayout} component={AdminQuizFilePage} />
              <AppRoute exact path="/admin/quiz" layout={AdminLayout} component={AdminQuizPage} />
              <AppRoute exact path="/admin/job" layout={AdminLayout} component={AdminJobPage} />
                     <AppRoute exact path="/admin/job/talentmatch" layout={AdminLayout} component={admin} />
                     <AppRoute exact path="/admin/job/matchdetail" layout={AdminLayout} component={AdminJobMatchDetailPage} />
                  <AppRoute exact path="/admin/job/candidate" layout={AdminLayout} component={AdminJobCandidatePage} />
                     <AppRoute exact path="/admin/job/candidate/recommend" layout={AdminLayout} component={AdminJobRecommendPositionPage} />
                     <AppRoute exact path="/admin/job/candidate/detail" layout={AdminLayout} component={AdminJobRecommendDetailPage} />
              <AppRoute exact path="/admin/event" layout={AdminLayout} component={AdminEventPage} />
                  <AppRoute exact path="/admin/event/center" layout={AdminLayout} component={AdminEventCenterPage} />
              <AppRoute exact path="/admin/user" layout={AdminLayout} component={AdminUserListPage} />
                  <AppRoute exact path="/admin/user/moment" layout={AdminLayout} component={AdminUserMomentPage} />
                  <AppRoute exact path="/admin/user/comment" layout={AdminLayout} component={AdminUserCommentPage} />
                  <AppRoute exact path="/admin/user/message" layout={AdminLayout} component={AdminUserMessagePage} />
              <AppRoute exact path="/admin/marketing" layout={AdminLayout} component={AdminMarketingPage} />
              <AppRoute exact path="/admin/finance" layout={AdminLayout} component={AdminFinancePage} />
              <AppRoute exact path="/admin/cert" layout={AdminLayout} component={AdminCertPage} />
              <AppRoute exact path="/admin/banner" layout={AdminLayout} component={AdminBannerPage} />
              <AppRoute exact path="/admin/verify" layout={AdminLayout} component={AdminCompanyVerifyPage} />
                  <AppRoute exact path="/admin/verify/user" layout={AdminLayout} component={AdminUserVerifyPage} />
              <AppRoute exact path="/admin/compliant" layout={AdminLayout} component={AdminComplaintPage} />
              <AppRoute exact path="/admin/system" layout={AdminLayout} component={AdminSystemPage} />


          <AppRoute exact path="/company" layout={CompanyLayout} component={CompanyHomePage} />
              <AppRoute exact path="/company/resume" layout={CompanyLayout} component={CompanyResumePage} />
              <AppRoute exact path="/company/job" layout={CompanyLayout} component={CompanyJobPage} />
              <AppRoute exact path="/company/dashboard" layout={CompanyLayout} component={CompanyDashboardPage} />
                   <AppRoute exact path="/company/dashboard/order/shop" layout={CompanyLayout} component={CompanyDashboardShopOrderPage} />
                   <AppRoute exact path="/company/dashboard/order/course" layout={CompanyLayout} component={CompanyDashboardCourseOrderPage} />
                   <AppRoute exact path="/company/dashboard/shop/available" layout={CompanyLayout} component={CompanyDashboardShopAvailablePage} />
                   <AppRoute exact path="/company/dashboard/shop/unavailable" layout={CompanyLayout} component={CompanyDashboardShopUnavailablePage} />
                   <AppRoute exact path="/company/dashboard/shop/recyclebin" layout={CompanyLayout} component={CompanyDashboardShopRecycleBinPage} />
                   <AppRoute exact path="/company/dashboard/course/learning" layout={CompanyLayout} component={CompanyDashboardLearningCoursePage} />
                   <AppRoute exact path="/company/dashboard/course/career" layout={CompanyLayout} component={CompanyDashboardPage} />
                   <AppRoute exact path="/company/dashboard/quizfile" layout={CompanyLayout} component={CompanyDashboardQuizFilePage} />
                   <AppRoute exact path="/company/dashboard/quiz/available" layout={CompanyLayout} component={CompanyDashboardQuizAvailablePage} />
                   <AppRoute exact path="/company/dashboard/quiz/unavailable" layout={CompanyLayout} component={CompanyDashboardQuizUnavailablePage} />
                   <AppRoute exact path="/company/dashboard/quiz/recyclebin" layout={CompanyLayout} component={CompanyDashboardQuizRecycleBinPage} />
                   <AppRoute exact path="/company/dashboard/finance" layout={CompanyLayout} component={CompanyDashboardFinancePage} />
                   <AppRoute exact path="/company/dashboard/event" layout={CompanyLayout} component={CompanyDashboardEventPage} />
                        <AppRoute exact path="/company/dashboard/event/add" layout={CompanyLayout} component={CompanyDashboardAddEventPage} />
                   <AppRoute exact path="/company/dashboard/subaccount" layout={CompanyLayout} component={CompanyDashboardSubaccountPage} />
              <AppRoute exact path="/company/message" layout={CompanyLayout} component={CompanyMessagePage} />
              <AppRoute exact path="/company/my" layout={CompanyLayout} component={CompanyMyPage} />
              <AppRoute exact path="/company/cert" layout={CompanyLayout} component={CompanyCertPage} /> */}

          <AppRoute exact path="/user" layout={UserLayout} component={UserOnlineCoursePage} />
              <AppRoute exact path="/user/learning" layout={UserLayout} component={UserOnlineCoursePage} />
                  <AppRoute exact path="/user/learning/online-more" layout={UserLayout} component={UserOnlineMoreCoursesPage} />
                  <AppRoute exact path="/user/learning/onsite" layout={UserLayout} component={UserOnsitePage} />
                  <AppRoute exact path="/user/learning/quizfile" layout={UserLayout} component={UserQuizFilePage} />
                  <AppRoute exact path="/user/learning/quiz" layout={UserLayout} component={UserQuizPage} />
                  <AppRoute exact path="/user/learning/shop" layout={UserLayout} component={UserShopPage} />
                  <AppRoute exact path="/user/learning/goodsDetail/:id" layout={UserLayout} component={GoodsDetailPage} />
                  <AppRoute exact path="/user/learning/courses" layout={UserLayout} component={CoursesPage} />
                  <AppRoute exact path="/user/learning/liveCourses" layout={UserLayout} component={LiveCoursesPage} />
                  <AppRoute exact path="/user/learning/courseDetail/:id" layout={UserLayout} component={CourseDetailPage} />
                  <AppRoute exact path="/user/learning/fileDetail/:id" layout={UserLayout} component={FileDetailPage} />
              <AppRoute exact path="/user/job" layout={UserLayout} component={UserJobPage} />
                  <AppRoute exact path="/user/job/quickapply" layout={UserLayout} component={UserJobQuickApplyPage} />
                  <AppRoute exact path="/user/job/freecourse" layout={UserLayout} component={UserJobFreeCoursePage} />
                  <AppRoute exact path="/user/job/careerkit" layout={UserLayout} component={UserJobCareerKitPage} />
                  <AppRoute exact path="/user/job/careercourse" layout={UserLayout} component={UserJobCareerCoursePage} />
                  <AppRoute exact path="/user/job/jobDetail/:id" layout={UserLayout} component={UserJobDetailPage} />
              <AppRoute exact path="/user/study" layout={UserLayout} component={UserStudyPage} /> 
              <AppRoute exact path="/user/myjob" layout={UserLayout} component={UserMyJobPage} />
              <AppRoute exact path="/user/message" layout={UserLayout} component={UserMessagePage} />
              <AppRoute exact path="/user/my" layout={UserLayout} component={UserMyPage} />
              <AppRoute exact path="/user/config" layout={UserLayout} component={UserConfigPage} />
              <AppRoute exact path="/user/cert" layout={UserLayout} component={UserCertPage} />
              <AppRoute exact path="/user/membership" layout={UserLayout} component={MembershipPage} />
              <AppRoute exact path="/user/joinus" layout={UserLayout} component={JoinusPage} />
              <AppRoute exact path="/user/complaint" layout={UserLayout} component={UserComplaintPage} />
              <AppRoute exact path="/user/login" layout={LoginLayout} component={LoginPage} />
              <AppRoute exact path="/user/register" layout={LoginLayout} component={RegisterPage} />
              <AppRoute exact path="/user/resetPassword" layout={LoginLayout} component={ResetPasswordPage} />
              <AppRoute exact path="/user/vippay" layout={LoginLayout} component={VipPayPage} />
              <AppRoute exact path="/user/my/resume" layout={UserLayout} component={ResumeDetailPage} />
        </Switch>
     </BrowserRouter>
    
     </div>
    );
  }
}