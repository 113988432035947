import React from "react";
import {
  Divider,
  Icon,
  Row,
  Col,
  Card,
  Modal,
  Descriptions,
  message
} from "antd";
import "./index.scss";
import { callGet } from 'service/api'

import Logo from 'images/logo/syyc-logo.png';

class MyFooter extends React.Component {

  state = {
    visible: false,
    imgurl: '',
    links: []
  }

  componentDidMount() {
    this._toLoadList()
  }

  _toLoadList = async () => {
    try {
      const listInfo = await callGet('/public/partnerLinks', { type: 1})
      console.log(' /public/partnerLinks ', listInfo)
      this.setState({
        links: listInfo,
      })
    } catch (e) {
      console.log('e', e)
      message.error(e.errorMessage)
    }
  }

  showWechat = ()=> {
    this.setState({
      visible: true,
      imgurl: 'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/syyc_wechat_qrcode.jpeg'
    })
  }

  showWeibo = ()=> {
    this.setState({
      visible: true,
      imgurl: 'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/syyc_weibo_qrcode.png'
    })
  }

  renderWechat = () => {
    const { visible, imgurl } = this.state;
    return (
      <Modal
        visible={visible}
        width={300}
          centered
        onCancel={() => {
          this.setState({
            visible: false,
          });
        }}
        closable={true}
        onOk={()=> {
          this.setState({
            visible: false,
          });
        }}
        footer={
          <div style={{width: '100%', textAlign: 'center'}}>
            <span style={{color: '#999', fontSize: 12, marginTop: 10, }} >
            扫码关注
            </span>
          </div>
        }
      >
        <img src={imgurl} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} alt=""></img>
      </Modal>
    );
  };

  render() {
    let {links} = this.state;
    return (
      <div className="footer-background">
        <Divider />
        <div className="containner">
        <div  className="footer">
        <div>
              <Row gutter={16}>
                <Col xs={24} md={8}>
                  <Card title="师业有成" style={{ marginBottom: '10px' }} bordered={false}>
                    
                    <Descriptions>
                      <Descriptions.Item label=""><img className="footer-syyc-logo" src={Logo} style={{ 'width': 100 }} alt=''/></Descriptions.Item>
                      <Descriptions.Item label="">  </Descriptions.Item>
                      <Descriptions.Item label="">
                        <div className='mgf_tips_icons'>
                          <span>关于我们</span>
                          <div className='float_tips_area'>
                            <div className='text_title'>关于我们</div>
                            <div className='text_desc'>
                            师业有成是全国领先的(准)教师人才社交平台，提供教师证、普通话、教师考编辅导以及教师直推名校就业服务，覆盖全国高校和大中小学校、优质教育机构；师业有成致力于打造“一站式教师发展平台”，帮助教师了解行业信息，拓展职业方向，咨询行业专家，优选学习课程，提升关键能力，连接工作机会，用师业有成，找朋友，找工作(找人才)、考证学习，走好职业发展每一步。特色功能:考证学习，职场进修，一步到位；求职招聘，精准、高效、快速、可靠！遇见同道中人，遇见更好的自己，关注感兴趣的人；在师业有成，更快成师，更好成业，快速事业有成！
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className='mgf_tips_icons2'>
                          <span>联系我们</span>
                          <div className='float_tips_area2'>
                            <div className='text_title'>联系我们</div>
                            <div className='text_desc'>
                            全国官方客服咨询热线：400-990-6001
                            </div>
                          </div>
                        </div>
                        <br />关注我们<br /><br />
                        <Icon style={{ fontSize: '26px' }} type="wechat" onClick={this.showWechat} />&nbsp;&nbsp;&nbsp;&nbsp;<Icon onClick={this.showWeibo} style={{ fontSize: '26px' }} type="weibo" /></Descriptions.Item>
                      <Descriptions.Item label=""> </Descriptions.Item>
                      <Descriptions.Item label="">

                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
                <Col xs={24} md={8}>
                  <Card title="友情链接" style={{ marginBottom: '10px' }} bordered={false}>
                    <Descriptions >
                      {
                        links.length > 0 && links.map((item, index) => 
                          <Descriptions.Item label=""><span className='link' onClick={() => window.open(item.url)}>{item.name}</span></Descriptions.Item>
                        )
                      }
                      {/* <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.linkke.com')}>链课</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.lkhlkh.com')}>链课号</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.lksxy.com')}>链课商学院</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.syycvip.com')}>师业有成商学院</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.zimingxiao.com')}>自名校</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.zimingshi.com')}>自名师</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.zimingke.com')}>自名课</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.chutiren.com')}>出题人培优</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.zijingying.com')}>自精英助学金</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.grpp.cn')}>个人品牌官网</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.ziminghui.com')}>自名会</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.zimingpai.com/')}>自名牌</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.kebenke.com')}>课本课</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.jsgrpp.com ')}>教师个人品牌</span></Descriptions.Item>
                      <Descriptions.Item label=""><span className='link' onClick={() => window.open('http://www.ziwuqi.com')}>自武器</span></Descriptions.Item> */}
                    </Descriptions>
                  </Card>
                </Col>
                <Col xs={24} md={8}>
                  <Card title="下载" style={{ marginBottom: '10px' }} bordered={false}>
                    <Descriptions >
                      <Descriptions.Item label="">&nbsp;&nbsp;小程序下载</Descriptions.Item>
                      <Descriptions.Item label="">&nbsp;&nbsp;App下载</Descriptions.Item>
                      <Descriptions.Item label=""></Descriptions.Item>
                      <Descriptions.Item label=""><img className="footer-syyc-logo" src={'https://img.syyc.top/syyc_miniapp_wechat.jpeg'} style={{ 'width': '100px' }} /></Descriptions.Item>
                      <Descriptions.Item label=""><img className="footer-syyc-logo" src={'https://img.syyc.top/syyc_app_qrcode.png'} style={{ 'width': '100px' }} /></Descriptions.Item>
                      <Descriptions.Item label=""></Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
              </Row>
            </div>
            <div style={{ textAlign: "center" }}>
                <span >师业有成 ©{new Date().getFullYear()} </span>
                <a href="https://beian.miit.gov.cn/" target="_blank"> 苏ICP备17025753号-19</a>
            </div>
          </div> 
        </div>
        {this.renderWechat()}
      </div>
    );
  }
}

export default MyFooter;
