import React from "react";
import styles from "./index.scss";
import { Card, DatePicker, Button, Row, Col, Radio, Pagination, Table, message } from "antd";
import JobItem from 'components/job';
import { Link } from "react-router-dom";
// import router from 'umi/router';
import * as utils from 'utils/utils';
import dateutils from 'utils/dateutils'
import { getUser, getToken } from 'utils/authority'
import constants from 'utils/constants'
import { callGet, callPost, syncUserInfo } from 'service/api'
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'
import WalletBackgroundImage from "images/wallet_page_bg.png";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

const { MonthPicker } = DatePicker;

const columns = [
  {
    title: "订单编号",
    dataIndex: "orderNumber",
    key: "orderNumber",
    // render: text => <a href="javascript:;">{text}</a>
  },
  {
    title: "订单类型",
    dataIndex: "itemLabel",
    key: "itemLabel"
  },
  {
    title: "商品名称",
    dataIndex: "itemTitle",
    key: "itemTitle"
  },
  {
    title: "支付方式",
    dataIndex: "paymentType",
    key: "paymentType",
    render: paymentType => (
      <span>
        {paymentType == 1 ? '支付宝' : paymentType == 2 ? '微信' : paymentType == 3 ? '钱包' : 'Apple Pay'}
      </span>
    )
  },
  {
    title: "支付时间",
    key: "orderedTime",
    dataIndex: "orderedTime",
  },
  {
    title: "交易金额",
    key: "paidAmount",
    dataIndex: "paidAmount",
    render: paidAmount => (
      <span>
        {changeMoney(paidAmount)}
      </span>
    )
  },
  {
    title: "订单状态",
    key: "status",
    dataIndex: "status",
    render: status => (
      <span>
        {getOrderStatus(status)}
      </span>
    )
  }
];

const changeMoney = (num)=> {
  const regexp = /(?:\.0*|(\.\d+?)0+)$/
  if (num > 1000000) {
      num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
      return '¥' + num + '万'
  } else {
      num = (num / 100).toFixed(2)
      num = num.replace(regexp, '$1')
      return '¥' + num
  }
}

const getOrderStatus = (type)=> {
  switch (type) {
      case 0:
          return '未支付'
      case 1:
          return '已支付'
      case 2:
          return '失败'
      default:
          return '--'
  }
}

const MONTH_FORMAT = 'yyyy/MM'

// @connect(({ job, login, loading }) => ({
//     job,
//     login,
// }))
export default class OrdersPage extends React.Component {
  constructor(props) {
    super(props);
    console.log(' JobDetailPage ', props);
    // const {id} = props.match.params;
    let now = new Date()
    console.log(' month ', dateutils.formatDate(now, MONTH_FORMAT))
    this.state = {
      levels: [],
      // data: {id},
      data: {},
      tableData: {},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      positionNum: 0,
      recommends: [],
      isApplied: false,
      isShowBuyTip: false,
      isShowPayModal: false,
      type: 1,
      payAmount: 0,
      orderNumber: '',
      levelIndex: 0,
      count: 0,
      selectDate: dateutils.formatDate(now, MONTH_FORMAT),
      pagenation: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {
    this._toLoadList()
  }

  _toLoadList = async (pageNo = 0) => {
    if (!getToken()) {
      return;
    }
    let { selectDate } = this.state;
    // if (!selectDate) {
    //   selectDate = new Date()
    // }
    let date = selectDate

    try {
      const listInfo = await callGet('/web/user/orders', { pageNo, pageSize: 20 })
      console.log(' order list ', listInfo)
      this.setState({
        tableData: listInfo,
        pagenation: {

        }
      })
    } catch (e) {
      console.log('e', e)
      message.error(e.errorMessage)
    }
  }

  handlePageChange = async (page, pageSize) => {
    console.log(' page ', page, pageSize)
    // const { dispatch } = this.props;
    this._toLoadList(page - 1);
    // let {filters: values} = this.state;

    // // let {offline = false, isSerialSingle = false, courseCategory='', official = true} = this.props.location.query;
    // let result = await dispatch({type: 'course/getOnlineCourses', payload: {
    //     courseCategory,
    //     directFlag: official ? '1' : '0',
    //     sortBy: values.sortBy || '',
    //     subject: values.subject || '',
    //     type: values.type || '',
    //     stage: values.stage || '',
    //     pageNo: page -1,
    //     pageSize
    // }})
    // console.log(' page change reqest', result)
    // this.setState({
    //     data: result
    // })
  }

  _toLoadPageData = async () => {

  }

  handleDateChange = (date, dateString) => {
    console.log(`selected ${date}`, dateString);
    this.setState({
      selectDate: dateString
    }, this._toLoadList)
  }

  onRowClick = (item, index, event) => {
    console.log(' on row click', item, index)

    let path;
    // 0=职位优推，1=职位优培,2=单个视频课程，3=系列视频课程，4=线下面授课，5=教材，6=会员,7=校区认证,8=功能套餐,9=刷题,10=题库,11=直播
    if (item.itemType == 0 || item.itemType == 1) {
      path = {
        pathname: '/user/job/jobDetail/' + item.itemId,
      }
    } else if (item.itemType == 2 || item.itemType == 3 || item.itemType == 4) {
      path = {
        pathname: `/user/learning/courseDetail/${item.itemId}`,
        state: {
          id: item.itemId,
          liveCourse: item.itemType == 11,
          type: item.itemType == 3 ? 1 : 2,
          offline: item.itemType == 4
        }
      }
    } else if (item.itemType == 5) {
      path = {
        pathname: `/user/learning/GoodsDetail/${item.itemId}`,
      }
    } else if (item.itemType == 10) {
      path = {
        pathname: `/user/learning/fileDetail/${item.itemId}`,
        state: {
          id: item.itemId,
          // type
        }
      }
    }
    
    if (path) {
      console.log(' context this', this)
      this.props.props.history.push(path)
    }
  }

  render() {
    // const { course, loading} = this.props;
    const { sources, data, income, outcome, tableData } = this.state;
    const { isMobile } = this.props;
    console.log('render', sources)

    return (
      <div style={{ paddingTop: 12 }}>
        <div>
          <Table
            bordered
            columns={columns}
            style={{ marginTop: "10px" }}
            dataSource={tableData.list || []}
            onRowClick={this.onRowClick}
          />
          {/* <Pagination
            className='pagination'
            total={tableData.totalElements || 0}
            showTotal={total => `共 ${tableData.totalElements || 0} 条`}
            pageSize={20}
            defaultCurrent={1}
            onChange={this.handlePageChange}
          /> */}
        </div>
      </div>
    );
  }
}