import React, { PureComponent, Fragment } from 'react';
// import { connect } from 'dva';
import { Form, Input, Icon, Button, Card, Table, Modal, Row, Col,Upload,message } from 'antd';
import './index.less';
import getComponent from '../QsForm/getComponent';
import {getToken,getUser} from 'utils/authority'

const FormItem = Form.Item;

// @Form.create()
// @connect(({ driverConsultation, loading }) => ({
//   driverConsultation,
//   loading: loading.effects['driverConsultation/getList'],
// }))
class AdSearchForm extends PureComponent {
  state = {
    expandForm: true,
  };

  componentDidMount() {}

  handleSubmit = e => {
    e.preventDefault();
    const { form, handleSubmit } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        handleSubmit(values);
        // console.log('Received values of form: ', values);
      }
    });
  };

  importBillSubmit = () => {
    const { form, handleSubmit } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        handleSubmit(values);
        // console.log('Received values of form: ', values);
      }
    });
  };

  formExportBill = e => {
    e.preventDefault();
    const { form, exportBill } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        let paramStr = `access_token=${encodeURIComponent(getToken())}&sid=${getUser().Sid}`
        Object.keys(values).forEach(key => {
          if(key === 'time' && values.time) {
            paramStr+=`&start_time=${values[key][0]}&end_time=${values[key][1]}`;
          }else {
            paramStr+=`&${key}=${values[key]}`;
          }
        });
        exportBill(paramStr);
      }
    });
  }

  

  formMultiBegin = e => {
    e.preventDefault();
    const {multiBegin } = this.props;
    multiBegin()
  }

  formMultiRecommit = e => {
    e.preventDefault();
    const {multiRecommit } = this.props;
    multiRecommit()
  }


  handleFormReset = () => {
    const { form, handleFormReset } = this.props;
    handleFormReset();
    form.resetFields();
  };

  toggleForm = () => {
    const { expandForm } = this.state;
    this.setState({
      expandForm: !expandForm,
    });
  };

  renderFormItem = (start, end) => {
    const { form, formData } = this.props;
    const { getFieldDecorator } = form;
    if (formData) {
      const list = formData.slice(start, end);
      return list.map((val, index) => (
        <Col md={val.md || 8} sm={24} key={index}>
          <FormItem label={val.label}>
            {this.getFormItem(val, getFieldDecorator, index)}
            {/* {getFieldDecorator(val.name,{
              initialValue: val.initialValue,
              rules: val.rules,
            },
          )
            (val.component)
          } */}
          </FormItem>
        </Col>
      ));
    }
    return '';
  };

  getFormItem = (item, getFieldDecorator, key) => {
    const component =
      typeof item.component === 'string' ? { name: item.component } : item.component;
    if (item.field && getFieldDecorator) {
      return getFieldDecorator(item.field, {
        validateFirst: true,
        ...item.options,
        rules: item.rules,
        initialValue: item.initialValue,
      })(getComponent(component, key));
    }
    return getComponent(component, key);
  };

  renderSimpleForm = () => {
    const { exportBill } = this.props;

    return (
      <Fragment>
        <Row gutter={{ md: 8, lg: 24 }}>
          {this.renderFormItem(0, 2)}
          <Col md={8} sm={24}>
            <span className="submitButtons">
              {exportBill && (
                <Button type="primary" ghost onClick={this.formExportBill}>
                  导出
                </Button>
              )}
              <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">
                查询
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleFormReset}>
                重置
              </Button>
              <a style={{ marginLeft: 8 }} onClick={this.toggleForm}>
                展开 <Icon type="down" />
              </a>
            </span>
          </Col>
        </Row>
      </Fragment>
    );
  };

  renderAdvancedForm = () => {
    const { exportBill,multiBegin, multiRecommit,importBill } = this.props;
    const paramStr = `access_token=${encodeURIComponent(getToken())}&sid=${getUser().Sid}`
    const action = `${importBill}?${paramStr}`
    return (
      <Fragment>
        <Row gutter={{ md: 8, lg: 24 }}>{this.renderFormItem(0, 3)}</Row>
        <Row gutter={{ md: 8, lg: 24 }}>{this.renderFormItem(3, 6)}</Row>
        <Row gutter={{ md: 8, lg: 24 }}>{this.renderFormItem(6, 9)}</Row>
        <Row gutter={{ md: 8, lg: 24 }}>{this.renderFormItem(9, 12)}</Row>
        <Row gutter={{ md: 8, lg: 24 }}>{this.renderFormItem(12, 15)}</Row>
        <div style={{ overflow: 'hidden' }}>
          <span style={{ float: 'right',marginBottom: 24 }}>
            {
            importBill && (
              <Upload
                style={{marginRight:10}}
                name='order_file'
                action={action}
                showUploadList={false}
              //   headers={
              //   {authorization: 'authorization-text'}
              // }
                onChange={(info) => {
                if (info.file.status !== 'uploading') {
                  console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                  message.success(`${info.file.name} 上传成功`);
                  this.importBillSubmit()
                } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} 上传失败`);
                }
              }
            }
              >
                <Button>
                  批量导入
                </Button>
              </Upload>
            )
          }
            {exportBill && (
              <Button type="primary" ghost onClick={this.formExportBill}>
                导出
              </Button>
            )}
            
            {multiBegin && (
              <Button type="primary" style={{ marginLeft: 8 }} ghost onClick={this.formMultiBegin}>
                批量处理
              </Button>
            )}
            {multiRecommit && (
              <Button type="primary" style={{ marginLeft: 8 }} ghost onClick={this.formMultiRecommit}>
                批量改派
              </Button>
            )}
            
            <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">
              查询
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={this.handleFormReset}>
              重置
            </Button>
           
            {/* <a style={{ marginLeft: 8 }} onClick={this.toggleForm}>
              收起 <Icon type="up" />
            </a> */}
          </span>
        </div>
      </Fragment>
    );
  };

  renderForm() {
    const { expandForm } = this.state;
    return expandForm ? this.renderAdvancedForm() : this.renderSimpleForm();
  }

  render() {
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit} className='searchForm'>
          {this.renderForm()}
        </Form>
      </Fragment>
    );
  }
}
export default Form.create()(AdSearchForm);
