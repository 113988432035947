import React, { Component } from "react";
import {
  Card,
  Tabs,
  Button,
  Row,
  Col,
  Pagination,
  Divider,
  Icon,
  Tag,
  Menu,
  Input,
  Dropdown,
  DatePicker,
  Switch,
  message
} from "antd";
import "./index.scss";
import vipid from 'images/vip/vipid.png'
import shareIcon from 'images/vip/vip_share.png'
import job from 'images/vip/job.png'
import classIcon from 'images/vip/class.png'
import leftIcon from 'images/line_left.png'
import rightIcon from 'images/line_right.png'
import {getUser, getToken} from 'utils/authority'
import { enquireScreen } from 'enquire-js';
import {syncUserInfo, callPost} from 'service/api'


export default class MembershipPage extends Component {
  state = {
    nineIcon: [
      {
          icon: vipid,
          name: '尊贵身份',
          desc: '会员尊贵身份\n题库会员可下载'
      },
      {
          icon: shareIcon,
          name: '分享赚酬金',
          desc: '会员身份分享\n课程和商品得酬金'
      },
      {
          icon: job,
          name: '优推/优培服务',
          desc: '会员优先获得\n优质岗位就职'
      },
      {
          icon: classIcon,
          name: '会员自购省钱',
          desc: '海量线上课程\n自购会员价购买'
      },
  ],
  }

  componentDidMount() {
    this.enquireHandler = enquireScreen(mobile => {
      const { isMobile } = this.state;
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });
  }

  goVipPay = ()=> {
    this.props.history.push('/user/vippay')
  }


  _activationCard = async () => {
    if (!this.vipIdRef || !this.vipPwdRef) {
      return;
    }
    if (!this.vipIdRef.state.value) {
      message.error('请填写会员序列号')
      return;
    }
    if (!this.vipPwdRef.state.value) {
      message.error('请填写会员卡密')
      return;
    }
    try {
        const result = await callPost('/user/vip/card', {cardNo: this.vipIdRef.state.value, password: this.vipPwdRef.state.value})
        await syncUserInfo()
        if (result) {
          message.success('卡已激活')
        }
        console.log(' result ', result)
    } catch (e) {
        console.log('e', e)
        message.error(e.errorMessage)
    }
}

  render() {
    let user = getUser()
    const {isMobile} = this.state;
    return (
      <div className="user-config-page-background">
        <div className="user-config-page-container">
          <Card bordered={false} style={{ minHeight: "200px" }}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <span style={{fontSize: 25, color: '#FFA900'}}>会员专享</span>
              <div style={isMobile ? {display: 'flex', flexDirection: 'column', margin: 10, padding:10} : {display: 'flex', flexDirection: 'row', margin: 10, padding:10}}>
                  {
                      this.state.nineIcon && this.state.nineIcon.map((item, index) =>
                      (
                        <div style={isMobile ? {display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 10} : {display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10}}>
                          <img src={item.icon} style={{width: 36, height: 36}} alt=""/>
                          <div style={isMobile ? {marginLeft: 12} : {marginTop: 12, textAlign: 'center'}}>
                          <div style={{color: '#FFA900'}}>{item.name}</div>
                          <div style={{color: '#666666', fontSize: 12}}>{item.desc}</div>
                          </div>
                        </div>
                      ))
                  }
              </div>
                <Button onClick={this.goVipPay} shape="round" type="primary" size="large" style={{width: 287}}>{user && user.isVip === 1 ? '点我立即续费' : '点我立即开通师业会员'}</Button>

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}} >
                  <img src={leftIcon} alt="" style={{ width: 60}}></img>
                  <div style={{textAlign: 'center', marginLeft: 12, marginRight: 12}}>
                    <div>会员激活</div>
                    <div>会员卡密在此激活会员</div>
                  </div>
                  <img src={rightIcon} alt="" style={{ width: 60}}></img>
                </div>

                <Row gutter={16} style={{minWidth: 350, marginTop: 20}}>
                  <Col span={isMobile ? 24 : 6} style={{ textAlign: "left", height: '100%', verticalAlign: 'middle'}}>
                  会员序列号
                  </Col>
                  <Col span={isMobile ? 24 : 18}><Input ref={(ref)=> this.vipIdRef = ref} size="large" placeholder='请填写会员卡序列号' /></Col>
                </Row>
                <Row gutter={16} style={{minWidth: 350, marginTop: 20}}>
                  <Col span={isMobile ? 24 : 6} style={{ textAlign: "left" }}>
                  会员密码
                  </Col>
                  <Col span={isMobile ? 24 : 18}><Input ref={(ref)=> this.vipPwdRef = ref} size="large" placeholder='请填写会员卡卡密' /></Col>
                </Row>

                <Button onClick={this._activationCard} shape="round" type="primary" size="large" style={{marginTop: 20, width: 287}}>立即激活</Button>

                <span style={{margin: 20, fontSize: 10, color: '#5f5f5f'}}>温馨提示：获取卡密更低价格激活会员身份，可联系或升级为师业有成超级会员</span>

            </div>
          </Card>
        </div>
      </div>
    );
  }
}
