import React, { Component } from "react";
import {
  Tabs,
  Button,
  Card,
  Pagination,
  Row,
  Col,
  Divider,
  Icon,
  Switch,
} from "antd";

import JobItem from 'components/job'
import {callGet} from 'service/api'
import { getToken } from 'utils/authority'

import "./index.scss";

const { TabPane } = Tabs;

function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

export default class UserMyJobPage extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      applies: {},
      favs: {},
      pageSize: 20
    }
  }

  async componentDidMount() {
    this.loadApplies(0)
    this.loadFavs(0)
  }

  loadApplies = async (pageNo)=> {
    if (!getToken()) {
      return;
    }
    const {pageSize} = this.state;
    try {
      const applies = await callGet('/web/userPositionApplications', {pageSize, pageNo})
      this.setState({
        applies: {
          list: applies.list,
          totalElements: applies.totalElements
        }
      })
    } catch (e) {
      console.log('e', e)
    }
  }

  loadFavs = async (pageNo)=> {
    if (!getToken()) {
      return;
    }
    const {pageSize} = this.state;
    try {
      const favs = await callGet('/web/user/favoritePositions', {pageSize, pageNo})
      this.setState({
        favs: {
          list: favs.list,
          totalElements: favs.totalElements
        }
      })
    } catch (e) {
      console.log('e', e)
    }
  }

  onJobClick = (item)=> {
    console.log('item click', item)
    let path = {
      pathname: '/user/job/jobDetail/' + item.id,
      query: {
        id: item.id
      }
    }
    this.props.history.push(path)
  }

  render() {
    const {applies, favs, pageSize} = this.state;
    return (
      <div className="user-myjob-page-background">
        <div className="user-myjob-page-container">
        <Card bordered={false} style={{  minHeight: '500px'}}>
            <Tabs defaultActiveKey="1" tabPosition='left'>
                <TabPane tab="已申请职位" key="1">

                      {/* <Card title='已投递' bordered={true} className='row'> */}
                        <Row gutter={8}>
                            {applies.list && applies.list.length > 0 && applies.list.map((item, index) => <JobItem index={index} appliedTime={item.appliedTime} { ...item.position} onClick={()=> this.onJobClick(item.position)}/>)}
                        </Row>
                      {/* </Card> */}
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      {/* <Pagination defaultCurrent={1} defaultPageSize={9} total={15} /> */}
                      <Pagination
                            className='pagination'
                            total={applies.totalElements || 0}
                            showTotal={total => `共 ${applies.totalElements || 0} 条`}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            onChange={this.handlePageChange}
                        />
                    </div>
              </TabPane>
              <TabPane tab="收藏职位" key="2">
                  {/* {positions} */}
                  {/* <Card title='已投递' bordered={true} className='row'> */}
                    <Row gutter={8}>
                        {favs.list && favs.list.length > 0 && favs.list.map((item, index) => <JobItem index={index} {...item.position} onClick={()=> this.onJobClick(item.position)}/>)}
                    </Row>
                  {/* </Card> */}
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                {/* <Pagination defaultCurrent={1} defaultPageSize={9} total={15} />  */}
                <Pagination
                      className='pagination'
                      total={favs.totalElements || 0}
                      showTotal={total => `共 ${favs.totalElements || 0} 条`}
                      pageSize={pageSize}
                      defaultCurrent={1}
                      onChange={this.handlePageChange}
                  />
                </div>
                  </TabPane>
                  {/* <TabPane tab="设置职位偏好" key="3">
                  <Card title="开启职位偏好给HR(对本公司保密)" 
                  extra={<p>
                      <Switch defaultChecked onChange={onChange}  style={{marginRight:'10px'}}/>
                      <Button  style={{marginRight:'10px'}}>保存</Button>
                      <Button type="dashed">取消</Button>
                  
                  </p>} >
                <p>Card content</p>
                <p>Card content</p>
                <p>Card content</p>
                </Card>
              </TabPane> */}
          </Tabs>
          </Card>
        </div>
      </div>
    );
  }
}
