import React from "react";
import  "./index.scss";
import { Card, Tabs, Button, Row, Col , Pagination, Breadcrumb, message} from "antd";
import AdSearchForm from 'components/AdSearchForm';
import LiveCourse from 'components/liveCourse'
import BackButton from 'components/backButton'
import {getUser, getToken} from 'utils/authority'
import {callGet} from 'service/api'
import { enquireScreen } from 'enquire-js';
// @connect(({ course,login, loading }) => ({
//   course,
//   login,
//   loading: loading.effects['course/getList']
// }))
export default class CoursesPage extends React.Component {
  constructor(props) {
    super(props);
    // console.log(' CoursesPage ', props);
    this.state = {
      // data: {id: props.location.query.id},
      data: {},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      pageSize: 20,
      isMobile: false,
      filters: {}
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {
    this._toLoadDetail()

    this.enquireHandler = enquireScreen(mobile => {
      const { isMobile } = this.state;
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });
  }

  _toLoadDetail = async (pageNo = 1) => {
    let {filters, pageSize} = this.state;
    let {moduleType = 1} = this.props.location.state || {};
    let result
    try {
        result = await callGet('/public/web/liveStreamCourses', {
          moduleType,
          pageNo: pageNo -1,
          pageSize,
          keyword: filters.keyword || '',
        })
        console.log(' result ', result);
        this.setState({
          data: result || {},
        })
    } catch (e) {
        console.log('e', e)
        message.error(e.errorMessage)
        //utils.showToast(e.errorMessage)
    }
  }

  onCourseClick = (item)=> {
      console.log(' course click', item)
      if (!getToken()) {
        this.props.history.push('/user/login')
        return;
      }
      let path = {
        pathname: `/user/learning/courseDetail/${item.liveStreamCourseId}`,
        state: {
          id: item.liveStreamCourseId,
          liveCourse: true
        }}
      // router.push(path);
      this.props.history.push(path)
  }

  handleSubmit = async (values) => {
    this.setState({
      filters: values,
    }, ()=> this._toLoadDetail(true))
  };

  handleFormReset = () => {
    this.setState({
      filters: {},
    }, ()=> this._toLoadDetail(true))
  };

  handlePageChange = async (page, pageSize)=> {
    console.log(' page ', page, pageSize)
    this._toLoadDetail(page)
    // const { dispatch } = this.props;
    // let {filters: values} = this.state;

    // let {offline = false, isSerialSingle = false, courseCategory='', official = true} = this.props.location.query;
    // let result = await dispatch({type: 'course/getOnlineCourses', payload: {
    //     courseCategory,
    //     directFlag: official ? '1' : '0',
    //     sortBy: values.sortBy || '',
    //     subject: values.subject || '',
    //     type: values.type || '',
    //     stage: values.stage || '',
    //     pageNo: page -1,
    //     pageSize
    // }})
    // console.log(' page change reqest', result)
    // this.setState({
    //     data: result
    // })
  }

  render() {
    // const { course, loading} = this.props;
    const {sources, data=[], courses=[], pageSize, isMobile } = this.state;
    console.log(' render ', data)
    let formData = [
        {
          label: '综合排序',
          field: 'keyword',
          component: {
            name: 'Search',
            placeholder: '请输入关键字搜索',
          },
          initialValue: ''
        },
    ];
    return (
      <div style={{padding: isMobile ? 10 : 0}}>
        {/* <UserLearningNavBar /> */}
        {/* <UserBanner banners={banners}/> */}
        <BackButton/>

        <div className='content'>
          <AdSearchForm
            className='search'
            formData={formData}
            handleFormReset={this.handleFormReset}
            handleSubmit={this.handleSubmit}
          />
            <Row className='row' gutter={8}>
                {data.list && data.list.length > 0 && data.list.map(item => <LiveCourse {...item} onClick={()=> this.onCourseClick(item)}/>)}
            </Row>

            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Pagination
                  className='pagination'
                  total={data.totalElements || 0}
                  showTotal={total => `共 ${data.totalElements || 0} 条`}
                  pageSize={pageSize}
                  defaultCurrent={1}
                  onChange={this.handlePageChange}
              />
            </div>
        </div>
      </div>
    );
  }

}
