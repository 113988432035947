import React from 'react';

const getOption = (option = [], { prefix = '', idStr, nameStr, callback = () => {} } = {}) => {
  let newArr;
  if (Array.isArray(option)) {
    if (option.length > 0) {
      const keys = Object.keys(option[0]);
      const id = idStr || keys.filter(_ => ['id', 'value', 'key'].includes(_))[0];
      const name = nameStr || keys.filter(_ => ['name', 'label', 'title'].includes(_))[0];
      if (id && name) {
        newArr = option.map(item => ({
          name: 'Option',
          key: String(item[id]),
          value: item[id],
          title: (prefix && `${prefix} : `) + item[name],
          children: item[name],
          disabled: item.disabled,
          ...callback(item),
        }));
      } else throw new Error(`getOption: ${keys} have not id or name`);
    } else return newArr;
  } else throw new Error(`getOption: ${option} is not arr`);
  return newArr;
};

const transformFromItem = itemObj => {
  const item = { ...itemObj };
  if (item.component) {
    const name = typeof item.component === 'string' ? item.component : item.component.name;
    if (name) {
      const { option } = item.component;
      if (['Select'].includes(name) && option) {
        item.type = 'select';
      } else if (['Input', 'AutoComplete'].includes(name)) {
        item.type = 'input';
      } else if (['UploadButton', 'PhotoWall'].includes(name)) {
        item.type = 'img';
      } else if (name === 'TimeQuantum') {
        const { isRequired, startRequired } = item.component;
        if (!isRequired + !startRequired === 1) {
          item.type = 'timeQuantum';
        }
      }
    }
    switch (item.type) {
      case 'input':
        if (Array.isArray(item.rules)) {
          item.rules.some(itm => {
            if (itm.required && !itm.transform) {
              itm.transform = value => (`${value}` || '').trim();// eslint-disable-line
              return true;
            }
            return false;
          });
        }
        break;
        /* eslint-disable */


      case 'select':
        const { option, ...other } = item.component;
        let optionArr;
        let optionConfig;
        if (Array.isArray(option)) {
          optionArr = option;
        } else if (typeof option === 'object') {
          ({ data: optionArr, ...optionConfig } = option);
        }
        const children = getOption(optionArr, optionConfig);
        item.component = {
          name: 'Select',
          optionLabelProp: 'title',
          defaultActiveFirstOption: false,
          children,
          ...other,
        };
        break;
      case 'timeQuantum':
        const rules = Array.isArray(item.rules) ? item.rules : [];
        const { isRequired, startRequired } = item.component;
        /* eslint-enable */
        if (isRequired) {
          rules.push({
            required: true,
            validator: (_, value, callback) => {
              if (!value) {
                /* eslint-disable */ callback('请选择时间段');
              } else if (!value[0]) {
                callback('请选择起始时间');
              } else if (!value[1]) {
                callback('请选择结束时间');
              }
              callback();
            },
          });
        } else if (startRequired) {
          rules.push({
            required: true,
            validator: (_, value, callback) => {
              if (!value || !value[0]) {
                callback('请选择起始时间');
              }
              callback();
              /* eslint-disable */
            },
          });
        }
        item.rules = rules;
        break;
      default:
        break;
    }
  }
  return item;
};

const transformView = (value, type) => {
  switch (type) {
    case 'img':
      return <PhotoWall value={value} />;
    default:
      return value;
  }
};

export { transformFromItem, transformView, getOption };
