import React from "react";
import styles from "./index.scss";
import { Card, Tabs, Button, Row, Col ,Icon, Breadcrumb, message} from "antd";
import UserLearningNavBar from "components/learning-navbar";
import Goods from 'components/Goods';
// import VideoPlayer from '@/components/videoplayer';
import { Link } from "react-router-dom";
import {callGet} from 'service/api'
import { connect } from "react-redux";

// @connect(({ course,login, loading }) => ({
//   course,
//   login
//   // loading: loading.effects['course/getList'],
// }))
class ShopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    this._toLoadDetail()
  }

  componentWillReceiveProps(nextProps) {
    console.log(' componentWillReceiveProps ', nextProps);
    if (nextProps.jobCategory.id != this.props.jobCategory.id) {
      this._toLoadDetail()
    }
  }

  _toLoadDetail = async () => {
    try {
      const result = await callGet('/public/products')
      console.log(' result ', result)
      this.setState({
        data: result || []
      })
    } catch (e) {
      message.error(e.errorMessage)
    }
}

onCourseClick = (item)=> {
  // console.log(' course click', getToken())
  // sessionStorage.setItem('token', res.data.data.token);
  // sessionStorage.getItem('token')
  // if (!getToken()) {
  //   this.props.history.push('login')
  //   return;
  // }
  let path = {
    pathname: `/user/learning/GoodsDetail/${item.productId}`,
    query: {
      id: item.poductId
    }}
  // router.push(path);
  this.props.history.push(path)
}

  render() {
    // const { course, loading} = this.props;
    const {sources, data=[], courses=[] } = this.state;
    console.log('render', sources)
    return (
      <div>
        <UserLearningNavBar tabkey='5'/>
        {/* <UserBanner banners={banners}/> */}
        {/* <Breadcrumb>
            <Breadcrumb.Item onClick={()=> router.goBack()}>
                返回
            </Breadcrumb.Item>
        </Breadcrumb> */}

        {/* <AdSearchForm
          formData={formData}
          handleFormReset={this.handleFormReset}
          handleSubmit={this.handleSubmit}
          exportBill={this.exportBill}
          multiRecommit={getUser() && getUser().Sid === 3 && this.openMultiRecommit}
          multiBegin={this.multiBegin}
        /> */}

        <Row>
            {data.length > 0 && data.map(item => <Goods {...item} onClick={()=> this.onCourseClick(item)}/>)}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {jobCategory} = state;
  return {
    jobCategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (ShopPage);