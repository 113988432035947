// import fetch from 'dva/fetch';
// import axios, { post }  from 'axios';
import { notification, message } from 'antd';
// import router from 'umi/router';
import { createBrowserHistory } from 'history';
import {getUser, getToken, setToken, setUser, getJobCategory} from './authority'
const BASE_URL = process.env.NODE_ENV == 'development' ? 'https://api.syyc.top' : 'https://api.syyc.top'
const history = createBrowserHistory();

const codeMessage = {
  200: '服务器成功返回请求的数据',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据,的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
};

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = codeMessage[response.status] || response.statusText;
  notification.error({
    message: `请求错误 ${response.status}: ${response.url}`,
    description: errortext,
  });
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
}

function logout() {
  message.error('请重新登录')
  setToken('');
  setUser({});
  history.push('/user/login');
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

export default function request(url, options) {
  // console.log('request', url, getToken())
  let jobCategory = getJobCategory()
  // console.log(' call get ', jobCategory, window, global)
  const defaultOptions = {
    // credentials: 'true', // include
    headers: {
      'Authorization': `Bearer ${getToken()}`,
      appVer: '1.0.0',
    }
  };
  if (jobCategory && jobCategory.id) {
    defaultOptions.headers.channelId = jobCategory.id
  }
  const newOptions = { ...defaultOptions, ...options };
  if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'PATCH') {
    newOptions.headers = {
      // Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...newOptions.headers,
    };
    const strBody = {
      t: `${new Date().getTime()}`,
      // sid: getUser() && getUser().Sid ? `${getUser().Sid}` : '',
    };
    Object.keys(newOptions.body).map(key => {
      strBody[key] = `${newOptions.body[key]}`;
      return '';
    });
    newOptions.body = JSON.stringify(strBody);
  }
  // const questurl = (getUser() && getUser().Sid === 7) ? `https://api.test.syyc.top${url}` : url
  const questurl = `${BASE_URL}${url}`
  // const questurl = url
  return fetch(questurl, newOptions)
    .then(checkStatus)
    .then(response => {
      if (newOptions.method === 'DELETE' || response.status === 204) {
        return response.text();
      }
      if (!response || !response.json || !response.text) {
        // window.g_app._store.dispatch({
        //   type: 'login/logout',
        // });
        this.logout()
        return;
      }
      // 登录Token失效
      const jsonRes = () =>
        new Promise((resolve, reject) => {
          response.json().then(json => {
            console.log(' response json',json)
            if (json.code !== '200') {
              if (json.code === '401') {
                // window.g_app._store.dispatch({
                //   type: 'login/logout',
                // });
                logout()
              }
              reject(json);
            } else {
              resolve(json.data);
            }
          });
        });
      return jsonRes();
    })
    // .catch(e => {
    //   console.log(' request e', e)
    //   if (e && e.errorMessage) {
    //     message.error(e.errorMessage);
    //   }
    //   let errorMsg = '';
    //   try {
    //     // errorMsg = JSON.stringify(window.g_app._store.getState().login.logs);
    //     // window.localStorage.setItem('log', errorMsg);
    //     // window.aliLogger.push('netError', errorMsg);
    //     // window.aliLogger.logger();
    //   } catch (error) {
    //     console.log(error);
    //   }
    // })
    // .catch(e => {
    //   const status = e.name;
    //   if (status === 401) {
    //     // window.g_app._store.dispatch({
    //     //   type: 'login/logout',
    //     // });
    //     logout()
    //     return;
    //   }
    //   if (status === 403) {
    //     // router.push('/exception/403');
    //     return;
    //   }
    //   if (status <= 504 && status >= 500) {
    //     // router.push('/exception/500');
    //   }
    //   // if (status >= 404 && status < 422) {
    //   //   dispatch(routerRedux.push('/exception/404'));
    //   // }
    // });
}
