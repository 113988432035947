export const updateCity = (city)=> {
    console.log('update city ', city)
    return {
        type: 'UPDATE_CITY',
        data: city
    }
}

export const updateJobCategory = (jobCategory)=> {
    console.log('update job ', jobCategory)
    return {
        type: 'UPDATE_JOB_CATEGORY',
        data: jobCategory
    }
}

export const updateUser = (user)=> {
    console.log('update user ', user)
    return {
        type: 'UPDATE_USER',
        data: user
    }
}