import React, { PureComponent } from 'react';
import {withRouter} from 'react-router-dom';
import * as utils from 'utils/utils';

class UserAvatar extends PureComponent{

    back = ()=> {
      // console.log(' back button', this.props)
    }

    render() {
        let { avatarUrl, onClick, isVip, size = 'normal', borderWidth = 3} = this.props
        let avatarSize = size == 'normal' ? 40 : 60
        let vipSize= size == 'normal' ? 12 : 16;
        let inset = size == 'normal' ? 5: 8

        // let {avatarSize= 40, vipSize= 16, avatarUrl, onPress=()=>{}, isVip, borderWidth = 3} = this.props

        const avatarStyle = {
            width: avatarSize,
            height: avatarSize,
            borderRadius: avatarSize / 2,
            borderColor: '#fff',
            borderWidth,
            overflow: 'hidden'
        }
        return (
          <div onClick={onClick ? onClick : this.back} style={{position: 'relative'}}>
            <div style={avatarStyle}>
                <img src={avatarUrl || 'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/avatar.png'}
                    style={{width: avatarSize, height: avatarSize }}/>
            </div>
            {isVip === 1 &&
            <div style={{
                position: 'absolute',
                bottom: - inset,
                right: - inset
            }}>
                <img src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/vip.png'} style={{
                    width: vipSize,
                    height: vipSize,
                }}/>
            </div>
            }
        </div>
        );
    }
}

export default withRouter(UserAvatar)