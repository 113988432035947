import React from "react";
import "./index.scss";
import { Card, Tabs, Button, Row, Col ,Icon, Pagination,Breadcrumb} from "antd";
import UserLearningNavBar from "components/learning-navbar";
import UserBanner from "components/banner";
import { width } from "window-size";

const { TabPane } = Tabs;

const operations = (
  <p>
    <Button type="primary" size="small">
      更多
    </Button>
    &nbsp;&nbsp;&nbsp;&nbsp;
  </p>
);

export default class UserOnlineMoreCoursesPage extends React.Component {
  render() {
    return (
      <div>
        <UserLearningNavBar />
        <div className="user-learning-page-background">
          <div className="user-learning-page-container">

          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>
            <a href="">在线视频课程</a>
            </Breadcrumb.Item>
        </Breadcrumb>

             <div style={{textAlign:'center', marginTop:'20px'}}>
             <Pagination
              defaultCurrent={1}
              defaultPageSize={9}
              total={15}
            />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
