import React, { Component } from "react";
import { Tabs, Card, Comment, Tooltip, List } from "antd";

import "./index.scss";
import moment from "moment";

const { TabPane } = Tabs;

const data = [
  {
    actions: [<span>Reply to</span>],
    author: "Han Solo",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>
          {moment()
            .subtract(1, "days")
            .fromNow()}
        </span>
      </Tooltip>
    )
  },
  {
    actions: [<span>Reply to</span>],
    author: "Han Solo",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment()
          .subtract(2, "days")
          .format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>
          {moment()
            .subtract(2, "days")
            .fromNow()}
        </span>
      </Tooltip>
    )
  }
];

const systemData = [
  {
    title: "Ant Design Title 1"
  },
  {
    title: "Ant Design Title 2"
  },
  {
    title: "Ant Design Title 3"
  },
  {
    title: "Ant Design Title 4"
  }
];

export default class UserMessagePage extends Component {
  render() {
    return (
      <div className="user-message-page-background">
        <div className="user-message-page-container">
          <Card bordered={false}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="我的聊天" key="1">
                <Tabs tabPosition="left">
                  <TabPane tab="联系人" key="1">
                    <List
                      className="comment-list"
                      header={`${data.length} 条消息`}
                      itemLayout="horizontal"
                      dataSource={data}
                      renderItem={item => (
                        <li>
                          <Comment
                            actions={item.actions}
                            author={item.author}
                            avatar={item.avatar}
                            content={item.content}
                            datetime={item.datetime}
                          />
                        </li>
                      )}
                    />
                  </TabPane>
                  <TabPane tab="学习提醒" key="2">
                    <List
                      className="comment-list"
                      header={`${data.length} 条消息`}
                      itemLayout="horizontal"
                      dataSource={data}
                      renderItem={item => (
                        <li>
                          <Comment
                            actions={item.actions}
                            author={item.author}
                            avatar={item.avatar}
                            content={item.content}
                            datetime={item.datetime}
                          />
                        </li>
                      )}
                    />
                  </TabPane>
                  <TabPane tab="职位沟通" key="3">
                    <List
                      className="comment-list"
                      header={`${data.length} 条消息`}
                      itemLayout="horizontal"
                      dataSource={data}
                      renderItem={item => (
                        <li>
                          <Comment
                            actions={item.actions}
                            author={item.author}
                            avatar={item.avatar}
                            content={item.content}
                            datetime={item.datetime}
                          />
                        </li>
                      )}
                    />
                  </TabPane>
                  <TabPane tab="简历被查看" key="4">
                    <List
                      className="comment-list"
                      header={`${data.length} 条消息`}
                      itemLayout="horizontal"
                      dataSource={data}
                      renderItem={item => (
                        <li>
                          <Comment
                            actions={item.actions}
                            author={item.author}
                            avatar={item.avatar}
                            content={item.content}
                            datetime={item.datetime}
                          />
                        </li>
                      )}
                    />
                  </TabPane>
                </Tabs>
              </TabPane>

              <TabPane tab="系统消息" key="2">
                <Tabs tabPosition="left">
                  <TabPane tab="我的通知" key="1">
                    <List
                      itemLayout="horizontal"
                      dataSource={systemData}
                      renderItem={item => (
                        <List.Item>
                          <List.Item.Meta
                            title={<a href="#">{item.title}</a>}
                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                          />
                        </List.Item>
                      )}
                    />
                  </TabPane>
                </Tabs>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
}
