import React, { Component } from "react";
import {
  Card,
  Tabs,
  Button,
  Row,
  Col,
  Pagination,
  Divider,
  Icon,
  Tag,
  Menu,
  Input,
  Dropdown,
  DatePicker,
  Switch,
  message
} from "antd";
import "./index.scss";

const { TabPane } = Tabs;

export default class UserConfigPage extends Component {
  render() {
    return (
      <div className="user-config-page-background">
        <div className="user-config-page-container">
          <Card bordered={false} style={{ minHeight: "200px" }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="消息推送设置" key="1">
                <Card
                  bordered={false}
                  style={{ paddingTop: "50px" }}
                >
                  <div>
                    <Row gutter={8}>
                      <Col style={{ textAlign: "right" }} span={11}>
                        <p>职位消息推送</p>
                      </Col>
                      <Col span={2} />
                      <Col style={{ textAlign: "left" }} span={11}>
                        <Switch />
                      </Col>
                    </Row>
                  </div>
                </Card>
              </TabPane>
              <TabPane tab="关于师业有成" key="2">
              Content of Tab Pane 3
            
              </TabPane>
              <TabPane tab="隐私条款" key="3">
                Content of Tab Pane 3
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
}
