import React from "react";
import "./index.scss";
import { Card, Tabs, Button, Row, Col , Pagination, message, Dropdown, Menu, Icon} from "antd";
import JobNavBar from "components/job-navbar";
import JobItem from 'components/job';
// import VideoPlayer from '@/components/videoplayer';
import { Link } from "react-router-dom";
import {getUser} from 'utils/authority'
import {callGet} from 'service/api'
import { connect } from 'react-redux'

function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

class QuickJobsPage extends React.Component {
  constructor(props) {
    super(props);
    console.log(' CourseDetailPage ', props);
    this.state = {
      data: [],
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      pageSize: 20,
      filters: {}
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {

    this._toLoadDetail()
  }

  componentWillReceiveProps(nextProps) {
    console.log(' componentWillReceiveProps ', nextProps);
    if (nextProps.jobCategory.id != this.props.jobCategory.id) {
      this._toLoadDetail()
    }
  }

  _toLoadDetail = async (pageNo=0) => {
    let {data, pageSize} = this.state;
    // let {offline = false, isSerialSingle = false, courseCategory='', official = true} = this.props.location.query;
    let result
    try {
        result = await callGet('/public/web/quickPositions', {pageNo, pageSize})
        console.log(' result ', result);
        this.setState({
          data: result,
        })
    } catch (e) {
        console.log('e', e)
        //utils.showToast(e.errorMessage)
    }
}

onCourseClick = (item)=> {
    console.log(' course click', item)
    let path = {
      pathname: '/user/job/jobDetail/'+item.id,
      query: {
        id: item.id
      }}
      this.props.history.push(path)
    // router.push(path);
  }

  handleSubmit = async (values) => {
    const { dispatch,urgent } = this.props;

    // let {pageSize} = this.state;
    // // let {offline = false, isSerialSingle = false, courseCategory='', official = true} = this.props.location.query;
    // let result = await dispatch({type: 'course/getOnlineCourses', payload: {
    //     courseCategory,
    //     directFlag: official ? '1' : '0',
    //     sortBy: values.sortBy,
    //     subject: values.subject,
    //     type: values.type,
    //     stage: values.stage,
    //     pageSize
    // }})
    // this.setState({
    //     filters: values,
    //     data: result
    // })
  };

  handleFormReset = () => {
    const { dispatch,urgent } = this.props;
    // const time = getTimeString('today')
    dispatch({
      type: 'vio/getList',
      payload: {
        status: '0',
        mobile: '',
        car_no: '',
        ticket: '',
        trade_no: '',
        page: 0,
        num: 10,
        time: null,
        urgent,
        expire: '',
      },
    });
  };

  handlePageChange = async (page, pageSize)=> {
    console.log(' page ', page, pageSize)
    // const { dispatch } = this.props;
    this._toLoadDetail(page - 1);
    // let {filters: values} = this.state;

    // // let {offline = false, isSerialSingle = false, courseCategory='', official = true} = this.props.location.query;
    // let result = await dispatch({type: 'course/getOnlineCourses', payload: {
    //     courseCategory,
    //     directFlag: official ? '1' : '0',
    //     sortBy: values.sortBy || '',
    //     subject: values.subject || '',
    //     type: values.type || '',
    //     stage: values.stage || '',
    //     pageNo: page -1,
    //     pageSize
    // }})
    // console.log(' page change reqest', result)
    // this.setState({
    //     data: result
    // })
  }

  render() {
    // const { course, loading} = this.props;
    const {sources, data=[], courses=[], pageSize } = this.state;
    console.log(' render ', data)
    let formData = [
        {
          label: '综合排序',
          field: 'urge',
          component: {
            name: 'Select',
            children: [{
              value: '',
              label: '综合排序',
            },{
              value: 'createdAt',
              label: '时间',
            },{
              value: 'soldCount',
              label: '热度'
            },{
                value: 'vipPrice',
                label: '价格'
            }],
            placeholder: '请选择',
          },
          initialValue: '',
        },
        {
          label: '全部科目',
          field: 'subject',
          component: {
            name: 'Select',
            children: [
              {
                value: '',
                label: '全部',
              },
              {
                value: '1',
                label: '综合素质',
              },
              {
                value: '2',
                label: '教育知识与能力',
              },
              {
                value: '3',
                label: '保教知识与能力',
              },
              {
                value: '4',
                label: '学科知识与能力',
              },
              {
                value: '5',
                label: '教育教学知识与能力',
              },
              {
                value: '99',
                label: '其他',
              },
            ],
          },
          initialValue: '',
        },
        {
            label: '全部课程',
            field: 'type',
            component: {
              name: 'Select',
              children: [
                {
                  value: '',
                  label: '全部',
                },
                {
                  value: '1',
                  label: '系列课程',
                },
                {
                  value: '2',
                  label: '精品单课',
                },
              ],
            },
            initialValue: '',
          },
          {
            label: '全部阶段',
            field: 'stage',
            component: {
              name: 'Select',
              children: [
                {
                  value: '',
                  label: '全部',
                },
                {
                  value: '1',
                  label: '幼儿',
                },
                {
                  value: '2',
                  label: '小学',
                },
                {
                    value: '3',
                    label: '中学',
                  },
                {
                  value: '99',
                  label: '其他',
                },
              ],
            },
            initialValue: '',
          },];
    const user = getUser()
    return (
      <div>
        <JobNavBar tabkey='2'/>
        {/* <UserBanner banners={banners}/> */}
        <div className='content'>
        <Card bordered={true} style={{width: '100%'}}>
              <Card bordered={false}>
                <p>
                  <b>工作区域：</b>
                  &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;鼓楼区&nbsp;&nbsp;&nbsp;&nbsp;六合区&nbsp;&nbsp;&nbsp;&nbsp;秦淮区&nbsp;&nbsp;&nbsp;&nbsp;浦口区
                  &nbsp;&nbsp;&nbsp;&nbsp;江宁区&nbsp;&nbsp;&nbsp;&nbsp;栖霞区&nbsp;&nbsp;&nbsp;&nbsp;溧水区&nbsp;&nbsp;&nbsp;&nbsp;高淳区&nbsp;&nbsp;&nbsp;&nbsp;玄武区&nbsp;&nbsp;&nbsp;&nbsp;
                  雨花台区&nbsp;&nbsp;&nbsp;&nbsp;建邺区
                </p>
                <p>
                  <b>职位亮点：</b>
                  &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;地铁周边&nbsp;&nbsp;&nbsp;&nbsp;免费体检&nbsp;&nbsp;&nbsp;&nbsp;五险一金&nbsp;&nbsp;&nbsp;&nbsp;定期团建
                </p>
                <p>
                  <b>每月薪资：</b>
                  &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;面议&nbsp;&nbsp;&nbsp;&nbsp;3k以下&nbsp;&nbsp;&nbsp;&nbsp;3k-5k&nbsp;&nbsp;&nbsp;&nbsp;5k-10k&nbsp;&nbsp;&nbsp;&nbsp;
                  10k-20k&nbsp;&nbsp;&nbsp;&nbsp;20-50k&nbsp;&nbsp;&nbsp;&nbsp;50k以上
                </p>
                <p>
                  <b>学历水平：</b>
                  &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;中专&nbsp;&nbsp;&nbsp;&nbsp;高中&nbsp;&nbsp;&nbsp;&nbsp;大专&nbsp;&nbsp;&nbsp;&nbsp;本科&nbsp;&nbsp;&nbsp;&nbsp;
                  研究生&nbsp;&nbsp;&nbsp;&nbsp;硕士&nbsp;&nbsp;&nbsp;&nbsp;博士
                </p>
                <p>
                  <b>工作经验：</b>
                  &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;应届生&nbsp;&nbsp;&nbsp;&nbsp;1年以内&nbsp;&nbsp;&nbsp;&nbsp;1-3年&nbsp;&nbsp;&nbsp;&nbsp;3-5年&nbsp;&nbsp;&nbsp;&nbsp;
                  5-10年&nbsp;&nbsp;&nbsp;&nbsp;10年以上
                </p>
                {/* <p>
                  <b>其他要求：</b>&nbsp;&nbsp; 
                  <Dropdown overlay={menu}>
                        <Button  style={{marginRight: "20px" }}>
                          全职 <Icon type="down" />
                        </Button>
                      </Dropdown>
                      <Dropdown overlay={menu}>
                        <Button  style={{marginRight: "20px" }}>
                          学校类型 <Icon type="down" />
                        </Button>
                      </Dropdown>
                      <Dropdown overlay={menu}>
                        <Button  style={{marginRight: "20px" }}>
                          性别 <Icon type="down" />
                        </Button>
                      </Dropdown>  
                </p> */}
              </Card>
            </Card>

            <Card bordered={true} className='row'>
              <Row gutter={8}>
                  {data.list && data.list.length > 0 && data.list.map((item, index) => <JobItem key={'job'+item.id} index={index} {...item} onClick={()=> this.onCourseClick(item)}/>)}
              </Row>
            </Card>

            <Pagination
                  className='pagination'
                  total={data.totalElements || 0}
                  showTotal={total => `共 ${data.totalElements || 0} 条`}
                  pageSize={pageSize}
                  defaultCurrent={1}
                  onChange={this.handlePageChange}
              />
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => {
  // console.log(' mapStateToProps', state)
  const { city, jobCategory } = state;
  return {
    city, jobCategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickJobsPage)