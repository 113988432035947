import React, { Component, PureComponent } from 'react';
import { Col, Row, Icon } from 'antd';
import {getSalaryRange} from 'utils/utils'
import constants from 'utils/constants'
import dateutils from 'utils/dateutils'

export default class JobItem extends PureComponent{

    concatTags = (item) => {
      const companyType = constants.getName(constants.ORG_TYPE_ARRAY, item.type);
      const experience = constants.getName(constants.WORK_YEARS_ARRAY, item.experienceRequire);
      const education = constants.getName(constants.EDUCATION_ARRAY, item.educationDegreeRequire);
      const jobType = constants.getName(constants.JOB_TYPE_ARRAY, item.jobType)

      const tags = [];
      if (companyType) {
          tags.push(companyType);
      }
      tags.push(experience);
      tags.push(education);
      tags.push(jobType)
      return tags;
    }

    render() {
      const {index, id, organizationAvatar, organizationIsVip, organizationName, title, refreshTime, appliedTime, onClick=()=>{} } = this.props;
      const tags = this.concatTags(this.props);
        return (
            <div onClick={onClick} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '12px 6px', borderTop: index !== 0 ? '1px solid #f5f5f5' : '1px solid white'}} key={`job-item-${id}`}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <img src={organizationAvatar || constants.COMMONS.orgAvatar} alt="" style={{width:'60px',height:'60px'}}/>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: '10px'}}>
                <span><b>{title}&nbsp;&nbsp;&nbsp;&nbsp;{getSalaryRange(this.props)}</b> <br/></span>
                    <span>
                      {
                        tags && tags.map(item => `${item}  `)
                      }
                      {/* 民办&nbsp;&nbsp;&nbsp;&nbsp;经验不限&nbsp;&nbsp;&nbsp;&nbsp;本科&nbsp;&nbsp;&nbsp;&nbsp;全职 */}
                    </span>
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between'}}>
                    <span style={{fontSize:'16px'}}>{organizationName} { organizationIsVip === 1 && <Icon style={{color:'#FFA70F',fontSize:'20px'}} type="safety-certificate" />}</span>
                <span>{appliedTime ? '投递于' : '更新于'}{dateutils.getFriendlyTime(refreshTime)}</span>
              </div>
            </div>
        );
    }
}


// 'screen-xs': {
//   maxWidth: 575,
// },
// 'screen-sm': {
//   minWidth: 576,
//   maxWidth: 767,
// },
// 'screen-md': {
//   minWidth: 768,
//   maxWidth: 991,
// },
// 'screen-lg': {
//   minWidth: 992,
//   maxWidth: 1199,
// },
// 'screen-xl': {
//   minWidth: 1200,
//   maxWidth: 1599,
// },
// 'screen-xxl': {
//   minWidth: 1600,
// },