import { stringify } from 'qs';
import request from 'utils/request';
// import { handler } from './handler';
// import {useHistory} from 'react-router-dom';
import { getToken, getShop, getGrey, setToken, setUser, setAddress as setAddressCache, getJobCategory } from 'utils/authority';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

export async function callApi(key, params) {
  console.log('callapi', key)
  if (!key) {
    console.error(`请输入正确的请求key: ${key}`);
    return;
  }
  const shopInfo = getShop();
  const url = `${getGrey() === 'true' ? '/grey' : ''}${key}`;
  return request(url, {
    method: 'POST',
    body: {
      access_token: getToken() || '',
      sid: shopInfo ? `${shopInfo.Id}` : '',
      ...params,
    },
  });
}

export async function callLogin() {
  console.log(' logout ', history)
  history.push('/user/login');
}

export async function callLogout() {
  setToken('');
  setUser({});
  history.push('/user/login');
}

export async function callPost(path, params, method='POST') {
  console.log('callPost', path)
  // if (!handler[key]) {
  //   console.error(`请输入正确的请求key: ${key}`);
  //   return;
  // }
  let url = `${getGrey() === 'true' ? '/grey' : ''}${path}`;
  console.log('callPost url', url)
  // if (suffix) {
  //   url += concatParams(suffix)
  // }
  return request(url, {
    method,
    body: {
      // access_token: getToken() || '',
      ...params,
    },
  });
}

export async function callPatch(path, params, suffix) {
  console.log('callPatch', path)
  // if (!handler[key]) {
  //   console.error(`请输入正确的请求key: ${key}`);
  //   return;
  // }
  let url = `${getGrey() === 'true' ? '/grey' : ''}${path}`;
  console.log('callPatch url', url)
  if (suffix) {
    url += concatParams(suffix)
  }
  return request(url, {
    method: 'PATCH',
    body: {
      // access_token: getToken() || '',
      ...params,
    },
  });
}

export async function callGet(key, params, suffix='') {
  const concatParam = concatParams(params);
  const url = `${getGrey() === 'true' ? '/grey' : ''}${key}${suffix}${concatParam !== '' ? '?' : ''}${concatParam}`;

  // console.log(' get ', headers, suffix, url)
  return request(url, {
    method: 'GET',
  });
}

export async function queryProjectNotice() {
  return request('/api/project/notice');
}

export async function queryActivities() {
  return request('/api/activities');
}

export async function queryRule(params) {
  return request(`/api/rule?${stringify(params)}`);
}

export async function removeRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'delete',
    },
  });
}

export async function addRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'post',
    },
  });
}

export async function updateRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'update',
    },
  });
}

export async function fakeSubmitForm(params) {
  return request('/api/forms', {
    method: 'POST',
    body: params,
  });
}

export async function fakeChartData() {
  return request('/api/fake_chart_data');
}

export async function queryTags() {
  return request('/api/tags');
}

export async function queryBasicProfile() {
  return request('/api/profile/basic');
}

export async function queryAdvancedProfile() {
  return request('/api/profile/advanced');
}

export async function queryFakeList(params) {
  return request(`/api/fake_list?${stringify(params)}`);
}

export async function removeFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'delete',
    },
  });
}

export async function addFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'post',
    },
  });
}

export async function updateFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'update',
    },
  });
}

export async function fakeAccountLogin(params) {
  return request('/api/login/account', {
    method: 'POST',
    body: params,
  });
}

export async function fakeRegister(params) {
  return request('/api/register', {
    method: 'POST',
    body: params,
  });
}

export async function queryNotices() {
  return request('/api/notices');
}

export async function getFakeCaptcha(mobile) {
  return request(`/api/captcha?mobile=${mobile}`);
}

export async function getAddress() {
  return new Promise((resolve, reject) => {

    request('/user/addresses', {
      method: 'GET',
    }).then(result =>  {
      let add
      if (result.length > 0) {
        let filters = result.filter(item => item.defaultFlag == 1) || []
        if (filters.length <= 0 ) {
          add = result[0]
        } else {
          add = filters[0]
        }
        setAddressCache(add)
      }
      resolve(add);
    }).catch (e => {
      reject(e)
    })
     
  });
}

export async function syncUserInfo() {
  return new Promise((resolve, reject) => {

    request('/users/info', {
      method: 'GET',
    }).then(result =>  {
      if (result) {
        setUser(result)
      }
      resolve(result);
    }).catch (e => {
      reject(e)
    })
     
  });
}

function concatParams(params) {
  let array = []
  let key
  for (key in params) {
    if (params[key] !== undefined) {
      array.push(key + '=' + params[key])
    }
  }
  let concatParam = ''
  
  for (var item of array) {
    if (concatParam != '') {
      concatParam += '&'
    }
    concatParam += item
  }
  return concatParam
}


