import React, { PureComponent } from 'react';
import {withRouter} from 'react-router-dom';
import * as utils from 'utils/utils';

class CardPayAvaliable extends PureComponent{

    back = ()=> {
      // console.log(' back button', this.props)
    }

    render() {
        const {onClick, commission, style={}} = this.props;
        let styles = {
          display: 'flex',
          color: '#fff',
          fontSize: 13,
          backgroundColor: '#E8B400',
          height: 24,
          textAlign: 'center',
          borderRadius: 12,
          marginLeft: 12,
          paddingLeft: 8,
          paddingRight: 8,
          cursor: 'pointer',
          ...style
          }
        return (
          <div style={styles}>
            <span>可卡付</span>
          </div>
        );
    }
}

export default withRouter(CardPayAvaliable)