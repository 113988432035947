import React, { PureComponent } from 'react';
import {withRouter} from 'react-router-dom';
import * as utils from 'utils/utils';

class CommissionButton extends PureComponent{

    back = ()=> {
      // console.log(' back button', this.props)
    }

    render() {
        const {onClick, commission, style={}} = this.props;
        let styles = {
          color: '#E8B400',
          fontSize: 13,
          border: '1px solid #E8B400',
          height: 24,
          textAlign: 'center',
          borderRadius: 12,
          marginLeft: 12,
          paddingLeft: 8,
          paddingRight: 8,
          cursor: 'pointer',
          ...style
          }
        return (
          <span
            onClick={onClick ? onClick : this.back}
            style={styles}>推广赚{utils.changeMoney(commission)}</span>
        );
    }
}

export default withRouter(CommissionButton)