import React, { PureComponent } from 'react';
import { Modal, message } from 'antd';
import {callGet} from 'service/api'
import dateutils from 'utils/dateutils'
import './index.scss';
const TIMEOUT = 10 * 60; // 10分钟

export default class PayModal extends PureComponent{

  state = {
    count: 0,
    timer: undefined,
    timeTick: '10:00'
  }

  componentDidMount() {
    // this.pollPayResult(this.props.orderNumber)
  }

  _updateTime() {
    this.setState({
        timeTick: dateutils.getCountdownTime(TIMEOUT - this.state.count)
    })
  }

  clearTimer() {
    let {timer} = this.state;
    clearTimeout(timer)
    timer = null;
  }

  pollPayResult = async(orderNo) => {
    console.log('orderNo')
    let {timer, count} = this.state;
    let {callback} = this.props;
    let self = this;
    self.clearTimer();
    let success
    try {
        let res = await callGet('/user/orders/result', {orderNo});
        console.log('pollPayResult', res);
        if (res.status == 1) {
            success = true;
        }
    } catch (e) {
        console.log('order poll error', e)
    }
    if (count >= TIMEOUT || success) {
        message.success('支付已完成')
        callback && callback()
        self.clearTimer()
    } else {
        timer = setTimeout(() => {
            this.pollPayResult(orderNo)
            this._updateTime()
        }, 1000);
        this.setState({
            timer,
            count: count + 1,
        })
    }
  }

    render() {
      let {timer, timeTick} = this.state;
      const { isShowPayModal, type, payAmount, payQrcode, title, desc, onClose } = this.props;
      return (
        <Modal
          visible={isShowPayModal}
          okText='确认'
          cancelText='取消'
          onCancel={onClose}
          closable={false}
          onOk={onClose}
          afterClose={()=> {
            console.log(' after close callback')
            if (timer) {
              this.clearTimer()
            }
          }}
        >
          <div style={{
                  justifyContent: 'center',
                  alignItems: 'center'}}>
              <div style={{width: '100%', marginBottom: 5}}>
                  <div style={{backgroundColor: '#fff', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, alignItems: 'center', marginTop: -1}}>
                      <span style={{color: '#FFC001', fontSize: 20, fontWeight: 'bold', justifyContent: 'center'}}>{title}</span>
                      <div style={{ minHeight: 120}}>
                          <span style={{color: '#999', fontSize: 16, marginTop: 10}} >
                          {desc}，您需支付{parseFloat(payAmount/100)}元，请用支付宝手机app扫码完成付款，支付结束倒计时{timeTick}！
                          </span>
                      </div>
                      <div style={{marginBottom: 15}}/>
                      <div style={{
                          display:'flex',
                          flexDirection: 'row',
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                          borderTopWidth: 1,
                          borderTopColor: '#eee',
                          alignItems: 'center', justifyContent: 'center',
                      }}>
                          <img src={payQrcode} alt='' style={{width: 'auto', height: 'auto'}}/>
                      </div>
                  </div>
              </div>
          </div>
      </Modal>
    );
    }
}