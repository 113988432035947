import React from "react";
import styles from "./index.scss";
import { Card, DatePicker, Button, Row, Col, Select, Table, message} from "antd";
import JobItem from 'components/job';
import { Link } from "react-router-dom";
// import router from 'umi/router';
import * as utils from 'utils/utils';
import dateutils from 'utils/dateutils'
import {getUser, getToken} from 'utils/authority'
import constants from 'utils/constants'
import {callGet, callPost, syncUserInfo} from 'service/api'
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'
import WalletBackgroundImage from "images/wallet_page_bg.png";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import UserAvatar from "components/companyAvatar/userAvatar";
import colors from 'utils/colors'
import globalStyles from 'utils/globalStyles'
import Player, {MessageContext, Layer} from 'griffith';

const { MonthPicker } = DatePicker;

const Title = (props) => (
  <div><span style={{color: colors.black34, fontSize: 15, fontWeight: 'bold', marginBottom: 10, marginTop: 10}}>{props.title}</span></div>
)

const ExpectationItem = (props) => (
      <div style={{...globalStyles.horizontal_container, ...globalStyles.center2, marginTop: props.key !== 0 ? 10 : 0}}>
      <span style={{color: colors.yellowFFB432, fontSize: 16, fontWeight: 'bold'}}>
          { `${props.positionName || ''} · ${ props.city && props.city.name ? props.city.name : '' }` }
      </span>

      {
          props.startSalary !== undefined && <span
              style={{
                  color: colors.yellowFFB432,
                  fontSize: 16,
                  fontWeight: 'bold'
              }}>{utils.getSalaryRange(props)}</span>
      }
  </div>
)

const WorkItem = (props) => (
  <div>
      <div style={{...globalStyles.horizontal_container, ...globalStyles.center2, flexWrap: 'wrap'}}>
          <div style={{...globalStyles.horizontal_start_container, ...globalStyles.center2}}>
              <img style={{width: 10, height: 10 / 37 * 48}} src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/experiences.png'}/>
              <span style={{
                  color: colors.black27,
                  fontSize: 16,
                  marginLeft: 10,
                  fontWeight: 'bold'
              }}>{props.schoolName}</span>
          </div>

          <div style={{ width: 200, marginLeft: 10, paddingRight:10}}>
              <span style={{color: colors.black27, fontSize: 16}}>{props.time}</span>
          </div>
      </div>
      <span style={{
          marginLeft: 20,
          color: colors.black27,
          fontSize: 16,
          marginTop: 10,
          marginBottom: 20
      }}>{props.jobName}</span>
      <span>{props.desc || ''}</span>
      {
          props.index !== (props.length - 1) && <div style={globalStyles.line}/>
      }
  </div>
)

const EducationItem = (props) => (
  <div>
      <div style={{...globalStyles.horizontal_container, ...globalStyles.center2}}>
          <div style={{...globalStyles.horizontal_start_container, ...globalStyles.center2}}>
              <img style={{width: 10, height: 10 / 46 * 48}} src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/experiences.png'}/>
              <span style={{
                  color: colors.black27,
                  fontSize: 16,
                  marginLeft: 10,
                  fontWeight: 'bold'
              }}>{props.schoolName}</span>
          </div>

          <span style={{color: colors.black27, fontSize: 16}}>{props.time}</span>
      </div>
      <span style={{...globalStyles.marginTop10, ...globalStyles.marginLeft20, 
          color: colors.black27,
          fontSize: 16,
      }}>
          {props.education} {props.major}
      </span>
      {
          props.index !== (props.length - 1) && <div style={globalStyles.line}/>
      }
  </div>
)

const VolunteerItem = (props) => (
  <div>
      {
          props.index !== 0 && <div style={globalStyles.line}/>
      }
      <div style={{...globalStyles.horizontal_container, ...globalStyles.center2}}>
          <div style={{...globalStyles.horizontal_start_container, ...globalStyles.center2}}>
              <img style={{width: 10, height: 10 / 46 * 48}} src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/school.png'}/>
              <span style={{
                  color: colors.black27,
                  fontSize: 16,
                  marginLeft: 10,
                  fontWeight: 'bold'
              }}>{props.organizationName}</span>
          </div>

          <span style={{color: colors.black27, fontSize: 16}}>{dateutils.splitWithPeriod(props.startDate)+ '~' + dateutils.splitWithPeriod(props.endDate)}</span>
      </div>
      <span style={{
          marginLeft: 20,
          color: colors.black27,
          fontSize: 16,
          marginTop: 10,
          marginBottom: 20
      }}>{props.positionName}</span>
      <span>{props.description || ''}</span>
  </div>
)


const MONTH_FORMAT = 'yyyy/MM'

const splitCredential = (credential)=> {
  if (!credential) {
      return ''
  }
  let arr = credential.split('|')
  return arr.join(' ')
}

// @connect(({ job, login, loading }) => ({
//     job,
//     login,
// }))
export default class ResumePage extends React.Component {
  constructor(props) {
    super(props);
    console.log(' JobDetailPage ', props);
    // const {id} = props.match.params;
    let now = new Date()
    console.log(' month ',dateutils.formatDate(now, MONTH_FORMAT) )
    this.state = {
      levels: [],
      // data: {id},
      data: {},
      tableData: {},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      positionNum: 0,
      recommends: [],
      isApplied: false,
      isShowBuyTip: false,
      isShowPayModal: false,
      type: 1,
      payAmount: 0,
      orderNumber: '',
      levelIndex: 0,
      count: 0,
      selectDate: dateutils.formatDate(now, MONTH_FORMAT),
      pagenation:{
        current: 1,
        pageSize: 10,
        total: 0
      }
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {

    // const script = document.createElement("script")
    // script.src = "https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js"
    // // script.async = true

    // const flexibleScript = document.createElement("script")
    // flexibleScript.src = "http://player.alicdn.com/resource/player/lib/flexible.min.js";
    
    // const zeptoScript = document.createElement('script')
    // zeptoScript.src = 'https://cdn.jsdelivr.net/npm/zepto/zepto.min.js';
    // document.body.appendChild(flexibleScript)
    // document.body.appendChild(script)
    // document.body.appendChild(zeptoScript)

    this._toLoadDetail()
    this._toLoadList()
  }

  // componentWillReceiveProps(newProps) {
  //     const {id} = newProps.match.params;
  //     const {data} = this.state;
  //     console.log(' componentWillReceiveProps ', newProps, this.state)
  //     if (id !== this.state.data.id) {
  //         data.id = id;
  //         this.setState({
  //           data
  //         }, this._toLoadDetail)
  //     }
  // }

  _toLoadDetail = async () => {
    if (!getToken()) {
      return;
    }
    let {resume} = this.state;
    // console.log('load detail', data.id)
    if (!resume) {
      return;
    }
    try {
        let result = await callGet('/resumes/' + resume.resumeId)
        if (result) {
            this.setState({
                resume: result
            })
            if (result.videoId != undefined && result.videoId != '') {
              this._toPlay(result.videoId)
            }
        }
    } catch (e) {
        console.log('e', e.errorMessage)
        message.error(e.errorMessage)
    }
  }

  _toLoadList = async ()=> {
    if (!getToken()) {
      return;
    }

    try {
        const list = await callGet('/resumes', {pageNo: 0, pageSize: 1})
        this.setState({
            resume: list && list.length > 0 ? list[0] : undefined,
        }, this._toLoadDetail)
    } catch (e) {
        console.log('e', e)
        message.error(e.errorMessage)
    }
  }

  _toPlay = async (videoId)=> {
    try {
        const videoInfo = await callGet('/user/videoPlayInfos/' + videoId)
        if (!videoInfo.paid) {
            message.success('请购买后查看完整视频', 4);
        }
        let videoUrl = videoInfo.playInfo ? videoInfo.playInfo[0].playURL : '';
        this.setState({
            sources: {
              sd: {
                  play_url: videoUrl,
              },
            },
        })
    } catch (e) {
        console.log('e', e)
        message.error(e.errorMessage)
    }
  }

  _toLoadPageData = async ()=> {
    
  }

  handleDateChange = (date, dateString) => {
    console.log(`selected ${date}`, dateString);
    this.setState({
      selectDate: dateString
    }, this._toLoadList)
  }

  createResume = async ()=> {
    // try {
    //     const result = await callPost('/resumes');

    //     let path = {
    //       pathname: `/user/my/resume`,
    //       state: {
    //         id: result.resumeId,
    //         title: '创建简历'
    //       }}
    //     this.props.history.push(path)
    // } catch (e) {
    //     console.log('e', e.errorMessage)
    //     message.error(e.errorMessage)
    // }
    message.warn('请移步师业有成app创建简历')
  }

  goDetail = async ()=> {
    // const {resume} = this.state;
    // let path = {
    //   pathname: `/user/my/resume`,
    //   state: {
    //     id: resume.resumeId,
    //     title: '创建简历'
    //   }}
    //   console.log(' props ', this.props)
    // this.props.history.push(path)
    message.warn('请移步师业有成app完善简历')
  }

  render() {
    // const { course, loading} = this.props;
    const {sources, data, income, outcome, tableData, resume } = this.state;
    const {isMobile} = this.props;
    console.log('render', sources)

    return (
      <div style={{paddingTop: 12}}>
        {!resume ? <div>
          <Button onClick={this.createResume} type="primary" size="small">创建简历</Button>
          </div> : <div>
            <Card title={resume.name + '的简历'} extra={<Button type="primary" size="small" onClick={this.goDetail}>编辑</Button>}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <UserAvatar avatarUrl={resume.avatarUrl}/>
              <Col style={{flex: 1}}>
              <Row><span style={{paddingLeft: 12}}>{resume.name}</span></Row>
              <Row><span style={{paddingLeft: 12}}>{resume.description || '个人评价未填写'}</span></Row>
              <Row style={{display: 'flex', flexDirection: 'row'}}>
                <div><img className='resume-status-img' src={resume.gender === 1 ? 'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/man.png' : 'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/woman.png'}/><span>{resume.gender === 1 ? '男' : '女'}</span></div>
                <div><img className='resume-status-img' src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/experiences.png'}/><span>{dateutils.getWorkYears(resume.workTime)}</span></div>
                <div><img className='resume-status-img' src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/education.png'}/><span>{constants.getName(constants.EDUCATION_ARRAY, resume.degreeLevel)}</span></div>
                <div><img className='resume-status-img' src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/age.png'}/><span>{dateutils.getAge(resume.birthday)}</span></div>
                <div><img className='resume-status-img' src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/credential.png'}/><span>{splitCredential(resume.credential)}</span></div>
                <div><img className='resume-status-img' src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/resume/ic_wechat.png'}/><span>{resume.wechat || '微信号未填写'}</span></div>
              </Row>
              </Col>
              <div
                  style={{
                      padding: 10,
                      border: 'solid 1px #Ffa700',
                      borderRadius: 30
                  }}>
                  <span style={{
                      color: '#Ffa700',
                      fontSize: 14
                  }}>{constants.getName(constants.WORK_STATUS, resume.status)}</span>
              </div>
            </div>
            <div className='line' />
            <Title title='求职期望'/>
              {
                  resume.resumeExpectations && resume.resumeExpectations.map((item, index) =>
                      <ExpectationItem
                          key={index}
                          {...item}
                          index={index}
                          length={resume.resumeExpectations.length}
                      />)
              }
              <div className='line' />
              <Title title='工作经历'/>
              {
                resume.resumeWorkExperiences && resume.resumeWorkExperiences.map((item, index) =>
                    <WorkItem
                        key={index}
                        index={index}
                        length={resume.resumeWorkExperiences.length}
                        schoolName={item.organizationName}
                        jobName={item.positionName}
                        time={dateutils.splitWithPeriod(item.startDate)+ '~' + dateutils.splitWithPeriod(item.endDate)}
                        desc={item.description}
                        showMore={this._renderTruncatedFooter}
                        hideMore={this._renderRevealedFooter}
                    />)
              }
              <div className='line' />
              <Title title='教育经历'/>

              {
                  resume.resumeEducations && resume.resumeEducations.map((item, index) =>
                      <EducationItem
                          key={index}
                          index={index}
                          length={resume.resumeEducations.length}
                          schoolName={item.schoolName}
                          time={dateutils.splitWithPeriod(item.startDate)+ '~' + dateutils.splitWithPeriod(item.endDate)}
                          education={!item.degreeLevel ? '' : constants.EDUCATION_ARRAY[constants.getIndex(constants.EDUCATION_ARRAY, item.degreeLevel)].name}
                          major={item.major}
                      />)
              }
              <div className='line' />
              <Title title='志愿者经历'/>
              {
                  resume.resumeVolunteerExperience && resume.resumeVolunteerExperience.map((item, index) =>
                      <VolunteerItem
                          key={index}
                          index={index}
                          {...item}
                          showMore={this._renderTruncatedFooter}
                          hideMore={this._renderRevealedFooter}
                      />)
              }
              <div className='line' />
              <Title title='个人视频'/>
              {
                  sources ?
                    <div style={{maxWidth: 600, marginTop: 10}}><Player id={0} ref={this.player} sources={sources}/></div>
                      : <div>
                          <span style={{color: colors.grey7E, marginTop: 10}}>{data.gender === 1 ? '他' : '她'}没有上传个人视频哦</span>
                      </div>
              }

              <div className='line' />
              <Title title='证书展示'/>
              {
                  resume.certImageUrls && resume.certImageUrls.length > 0 ?
                      <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10}}>
                          {
                              resume.certImageUrls.map((item, index) => <div onClick={()=> window.open(item)}>
                                <img key={'cert' + index} src={item} style={{width: 100, height: 100, marginRight: 10, marginBottom: 10}}/>
                                </div>
                              )
                          }
                      </div>
                      : <div>
                          <span style={{color: colors.grey7E}}>{resume.gender === 1 ? '他' : '她'}还没有上传证书</span>
                      </div>
              }
            </Card>
          </div>
        }
      </div>
    );
  }
}