import React, { PureComponent } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

class AdDatePicker extends PureComponent {
  state = {};

  componentDidMount() {}

  onChange = (momentTime, date) => {
    const { onChange } = this.props;
    // const dateTime = [(`${date[0]} 00:00:00`),(`${date[1]} 23:59:59`)]
    onChange(date, momentTime);
  };

  render() {
    const { value } = this.props;
    const defaultValue = value ? moment(value) : null;
    return <DatePicker style={{ width: '100%' }} value={defaultValue} onChange={this.onChange} />;
  }
}
export default AdDatePicker;
