import React from "react";
import {
  Layout,
  Menu,
  Icon,
  Row,
  Col,
  Card,
  Input,
  Button,
  Dropdown,
  Avatar,
  Affix,
  Tabs
} from "antd";
import "./index.scss";
import { Link } from "react-router-dom";

const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

class LoginLayout extends React.Component {
  render() {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        <div>{this.props.children}</div>

      </div>
    );
  }
}

export default LoginLayout;
