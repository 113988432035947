import React, { Component, PureComponent } from 'react';
import { Col } from 'antd';
import './index.scss';
import {changeMoney} from 'utils/utils'

export default class OnSiteCourse extends PureComponent{
    render() {
      const {id, courseImageUrl, organizationName, title, originalPrice, vipPrice, enrollCount, onClick } = this.props;
        return (
          <Col onClick={onClick} xs={24} md={6} xl={5}  key={`onsite-course-item-${id}`}>
            <div className="cell">
            <div  style={{position: "relative"}}>
            <img src={courseImageUrl} alt="" style={{ height:'120px',width:'200px',borderRadius: '8px', marginTop:'10px'}}/>
            <div style={{position: 'absolute', display:'flex', justifyContent: 'center', width: '200px', bottom: 0, borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', backgroundColor: '#FF3434'}}>
        <text>{`已有${enrollCount}人报名`}</text>
            </div>
            </div>
            <div style={{width: '200px', display: 'flex', flexDirection: 'column'}}>
              <text style={{color:'#212121', fontWeight: 'bold', letterSpacing: '2px'}}>{title}</text>
              <text>{organizationName}</text>
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p style={{color:'#FFA70F',fontWeight: 'bold'}}>{'原价：' + changeMoney(originalPrice)}</p>
              <p style={{color:'#FFA70F',fontWeight: 'bold'}}>{'会员价：' + changeMoney(vipPrice)}</p>
              </div>
            </div>
            </div>
          </Col>
        );
    }
}