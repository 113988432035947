import React from "react";
import {
  Menu,
  Icon,
  Row,
  Col,
} from "antd";
import "./index.scss";
import { Link } from "react-router-dom";


export default  class UserJobNavBar extends React.Component {
  render() {
    return (
      <div>
          <div className="user-career-navbar" 
          style={{
                  lineHeight: "40px",
                  width: "100%",
                  backgroundColor: "#FFA70F",
                  color: "#ffffff",
                }}>

            <Row>

              <Menu
              selectedKeys={[this.props.tabkey || '1']}
                theme="dark"
                mode="horizontal"
                style={{
                  lineHeight: "40px",
                   maxWidth:'1100px',
                   margin:'auto',
                  backgroundColor: "#FFA70F",
                  color: "#ffffff",
                  textAlign:'center'
                }}
              >
                <Menu.Item className="layout-menu-item" key="1">
                  <Link to="/user/job">
                    职位推荐
                  </Link>
                </Menu.Item>

                <Menu.Item className="layout-menu-item" key="2">
                  <Link to="/user/job/quickapply">
                  平台优推
                  </Link>
                </Menu.Item>

                <Menu.Item className="layout-menu-item" key="3">
                  <Link to={{
                    pathname: "/user/learning/quizfile",
                    state: {
                      type: 2
                    }
                    }}>
                   教案课件
                  </Link>
                </Menu.Item>
{/* 
                <Menu.Item className="layout-menu-item" key="4">
                  <Link to="/user/job/careerkit">
                    职场必备工具
                  </Link>
                </Menu.Item> */}

                <Menu.Item className="layout-menu-item" key="5">
                  <Link to="/user/job/careercourse">
                    职场必修课
                  </Link>
                </Menu.Item>

              </Menu>
            </Row>
          </div>
          </div>
    );
  }
}


