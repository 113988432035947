import React from "react";
import "./index.scss";
import { Card, Tabs, Button, Row, Col , message} from "antd";
import UserLearningNavBar from "components/learning-navbar";
// import UserBanner from "@/components/banner";
import UserBanner from "components/banner";
import OnSiteCourse from 'components/OnsiteCourse';
import {getUser, getToken} from 'utils/authority'
import VideoCourse from 'components/videoCourse';
import { Link } from "react-router-dom";
// import { connect } from 'dva';
// import router from 'umi/router';
import {callGet} from 'service/api'
import { connect } from "react-redux";

const { TabPane } = Tabs;

// const operations = (
//   <p>
//     <Link to="/learning/onsite"><Button type="primary" size="small">
//      更多
//     </Button></Link>
//     &nbsp;&nbsp;&nbsp;&nbsp;
//   </p>
// );

// @connect(({ course,login, loading }) => ({
//   course,
//   login,
//   loading: loading.effects['course/getRecommendOnsiteCourseList']
// }))
class OnsitePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: ''
    }
  }

  operations = (index) => {
    return  <p onClick={()=> this.more(index)}>
      <Button type="primary" size="small">
       更多
      </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </p>
  }

  more = (index)=> {
    console.log('more', index)
    let path
    if (index === 0) {
        path = {
          pathname: '/user/learning/courses',
          state: {
            offline: true,
            official: index === 0,
          }}
    } else {
      path = {
        pathname: '/user/learning/courses',
        state: {
          offline: true,
          official: index === 0,
        }
      }
    }
    this.props.history.push(path)
  }

  componentDidMount() {
    // const { dispatch, course } = this.props;
    this.reload()
  }

  componentWillReceiveProps(nextProps) {
    console.log(' componentWillReceiveProps ', nextProps);
    if (nextProps.jobCategory.id != this.props.jobCategory.id) {
      this.reload()
    }
  }

  reload = ()=> {
    callGet('/public/web/recommendOnSiteCourses', {
      num: 10,
      page: 0
    }).then((res)=>{
        console.log('res',res)
        // let courseList = res.data.data.map()
        this.setState({
          officialOnsiteCourses: res.officialVideoCourses || [],
          thirdpartyOnsiteCourses: res.thirdPartyVideoCourses || []
        })
    }).catch( (err)=>{
      // message.error('获取数据失败，请稍后再试')
      message.error(JSON.stringify(err.errorMessage))
      console.log('err',err)
    });

    callGet('/public/banners', {
      platformType: 1,
      category: 2,
      deviceType: 4
    }).then((res)=>{
          console.log('res',res)
        // let courseList = res.data.data.map()
        this.setState({
          banners: res,
        })
      console.log('res',res)
    }).catch( (err)=>{
      message.error(JSON.stringify(err.errorMessage))
      console.log('err',err)
    });
  }

  onCourseClick = (item)=> {
    // if (!getToken()) {
    //   this.props.history.push('/user/login')
    //   return;
    // }
    let path = {
      pathname: `/user/learning/courseDetail/${item.id}`,
      state: {
        id: item.id,
        offline: true
      }}
    // router.push(path);
    this.props.history.push(path)
  }

  render() {
    const { course, loading} = this.props;
    const {officialOnsiteCourses=[], thirdpartyOnsiteCourses=[], banners=[]} = this.state
    console.log('render', course)
    return (
      <div>
        {/* <UserLearningNavBar /> */}
        <UserLearningNavBar tabkey="2"/>
        <UserBanner banners={banners}/>
        <div className="user-learning-page-background">
          <div className="user-learning-page-container">
            <Tabs defaultActiveKey="1" tabBarExtraContent={this.operations(0)}>
              <TabPane tab="直营面授课程" key="1">
              <Row>
                {officialOnsiteCourses.map(item => <OnSiteCourse {...item} onClick={() =>  this.onCourseClick(item)}/>)}
              </Row>
              </TabPane>
            </Tabs>

            <Tabs defaultActiveKey="1" tabBarExtraContent={this.operations(1)} style={{marginTop:'20px'}}>
              <TabPane tab="加盟面授课程" key="1" >
                <Row>
                  {thirdpartyOnsiteCourses.map(item => <OnSiteCourse {...item} onClick={() => this.onCourseClick(item)}/>)}
                </Row>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {jobCategory} = state;
  return {
    jobCategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (OnsitePage);