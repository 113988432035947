import utils from "./utils";

function getWorkYears(begintime) {
    if (!begintime || begintime == '') {
        return '未知';
    }
    let begin = new Date(begintime.replace(new RegExp('-' , 'g' ),'/'))
    let now = new Date()

    let oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
    let diffDays = Math.round(Math.abs((begin.getTime() - now.getTime())/(oneDay)));
    if (parseFloat(diffDays / 360) < 0.5) {
        return '不到半年'
    } else if (parseFloat(diffDays/360 < 1)) {
        return '不到一年'
    } else {
        return parseInt(diffDays / 360) + '年'
    }
}

function getAge(begintime) {
    if (!begintime || begintime == '') {
        return '未知';
    }
    let begin = new Date(begintime.replace(new RegExp('-' , 'g' ),'/'))
    let now = new Date()

    let oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
    let diffDays = Math.round(Math.abs((begin.getTime() - now.getTime())/(oneDay)));
    if (parseFloat(diffDays / 360) < 0.5) {
        return '不到半岁'
    } else if (parseFloat(diffDays/360 < 1)) {
        return '不到一岁'
    } else {
        return parseInt(diffDays / 360) + '岁'
    }
}


function jsCoreDateCreator(dateString) {
    if (dateString.includes('/')) {
        dateString = dateString.split('/')
        dateString = dateString.join('-')
    }
    // dateString *HAS* to be in this format "YYYY-MM-DD HH:MM:SS"  
    let dateParam = dateString.split(/[\s-:]/)  
    dateParam[1] = (parseInt(dateParam[1], 10) - 1).toString()  
    return new Date(...dateParam)  
  }

  function padLeftZero(str) {
    return ('00' + str).substr(str.length);
  }

   function formatDate(date, fmt) {
    // console.log('formatDate', date, fmt)
    if (!date) {
        return ""
    }
  
    if (!(date instanceof Date)) {
        // fix android release mode bug
        //date = new Date(date)
        date = jsCoreDateCreator(date)
    }
    
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
  
    // 遍历这个对象
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            // console.log(`${k}`)
            //console.log(RegExp.$1)
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
  };

function getFriendlyTime(time) {
    if (!time) {
        return '';
    }
    let date = jsCoreDateCreator(time)
    const now = new Date()
    // Make a fuzzy time
    let delta = Math.round((now - date) / 1000);

    let minute = 60,
        hour = minute * 60,
        day = hour * 24,
        week = day * 7;

    let fuzzy;

    if (delta < 30) {
        fuzzy = '刚刚';
    } else if (delta < minute) {
        fuzzy = delta + '秒前';
    } else if (delta < 2 * minute) {
        fuzzy = '1分钟前'
    } else if (delta < hour) {
        fuzzy = Math.floor(delta / minute) + '分钟前';
    } else if (Math.floor(delta / hour) == 1) {
        fuzzy = '1小时前'
    } else if (delta < day) {
        fuzzy = Math.floor(delta / hour) + '小时前';
    } else if (delta < day * 2) {
        fuzzy = '昨天';
    } else {
        if (date.year != now.year) {
            fuzzy = formatDate(date, 'yyyy-MM-dd hh:mm')
        } else {
            fuzzy = formatDate(date, 'MM-dd hh:mm')
        }
    }
    return fuzzy;
}

function getTimeTick(begin, end) {
    let delta = Math.round((end - begin) / 1000);
    // console.log('time tick', begin, end, delta)

    //计算出相差天数
    let days = Math.floor(delta / (24*3600));

    //计算出小时数
    let leave1 = delta % (24*3600);    //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600));
    //计算相差分钟数
    let leave2 = leave1 % (3600);        //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2/(60));

    let seconds = leave2

    let time = ''
    if (days > 0) {
        time += days + 'd '
    }
    if (hours > 0) {
        time += hours + ':'
    }
    if (minutes > 0) {
        time += minutes >= 10 ? `${minutes}:` : `0${minutes}:`
    } else {
        time += '00:'
    }
    if (seconds > 0) {
        time += seconds >= 10 ? seconds : '0' + seconds
    }
    return time
}

function getCountdownTime(seconds) {
    let min = Math.floor(seconds / 60);
    let sec = seconds % 60;
    let time = ''
    if (min > 0) {
        time += min >= 10 ? `${min}:` : `0${min}:`
    } else {
        time += '00:'
    }
    if (sec > 0) {
        time += sec >= 10 ? sec : '0' + sec
    }
    return time
}

/**英文句号分割 */
function splitWithPeriod(date) {
    if (!date) {
        return ''
    }
    if (date.includes('-')) {
        let arr = date.split('-')
        return arr.join('.')
    } else if (date.includes('/')) {
        let arr = date.split('/')
        return arr.join('.')
    } else {
        return date;
    }
}

function parseLivetime(live) {
    let dateBegin = formatDate(live.liveStreamTime, 'MM月dd日')
    let minutesBegin = formatDate(live.liveStreamTime, 'hh:mm')

    let dateEnd = formatDate(live.liveStreamEndTime, 'MM月dd日')
    let minutesEnd = formatDate(live.liveStreamEndTime, 'hh:mm')

    if (dateBegin == dateEnd) {
        return `${dateBegin} ${minutesBegin}-${minutesEnd}`
    } else {
        return `${dateBegin} ${minutesBegin}-${dateEnd} ${minutesEnd}`
    }
}

export default {
    getWorkYears,
    getAge,
    getFriendlyTime,
    formatDate,
    getTimeTick,
    getCountdownTime,
    splitWithPeriod,
    parseLivetime
}