import React from "react";
import "./index.scss";
import { Card, Tabs, Button, Row, Input, Pagination, message, Dropdown, Menu, Icon } from "antd";
import JobNavBar from "components/job-navbar";
import JobItem from 'components/job';
import MyTags from 'components/myTags'
// import VideoPlayer from '@/components/videoplayer';
import { Link } from "react-router-dom";
import { getUser, getCity } from 'utils/authority'
import { callGet } from 'service/api'
import { connect } from 'react-redux'
import constants from "utils/constants";
import _ from 'lodash'
const Search = Input.Search

function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

class JobsPage extends React.Component {
  constructor(props) {
    super(props);
    console.log(' Jobs page ', props);
    this.state = {
      city: props.city,
      data: [],
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      pageSize: 20,
      filters: {},
      search: ''
    }
    this.player = null; // 创建播放器实例变量

    this._toLoadDetail = _.debounce(this._toLoadDetail, 500)
  }

  componentDidMount() {
    // console.log('componentDidMount', this.props)
    this._toLoadDetail()
  }

  componentWillReceiveProps(nextProps) {
    // console.log('newprops', newProps)
    // console.log(' props ', this.props)
    if (nextProps.city.code !== this.props.city.code || nextProps.jobCategory.id != this.props.jobCategory.id) {
      this.setState({
        city: nextProps.city
      }, this._toLoadDetail)
    }
  }

  _toLoadDetail = async (pageNo = 0) => {
    let { city, pageSize, search } = this.state;
    console.log('  _toLoadDetail ', city)
    // let {offline = false, isSerialSingle = false, courseCategory='', official = true} = this.props.location.query;
    let params =  { pageNo, pageSize, city: city.code };

    for (let i = 1; i< 5; i++) {
      const selectValue = this.refs['querytag' + i].getValue()
      if (selectValue) {
        params[selectValue.key] = selectValue.value
      }
    }
    if (search) {
      params.keyword = search
    }
    let result
    try {
      result = await callGet('/public/web/positions', params)
      this.setState({
        data: result,
      })
    } catch (e) {
      console.log('e', e)
      //utils.showToast(e.errorMessage)
    }
  }

  onCourseClick = (item) => {
    console.log(' course click', item)
    let path = {
      pathname: `/user/job/jobDetail/${item.id}`,
      query: {
        id: item.id
      }
    }
    this.props.history.push(path)
    // router.push(path);
  }

  handleSubmit = async (values) => {
    this._toLoadDetail()
    // const { dispatch, urgent } = this.props;

    // let {pageSize} = this.state;
    // // let {offline = false, isSerialSingle = false, courseCategory='', official = true} = this.props.location.query;
    // let result = await dispatch({type: 'course/getOnlineCourses', payload: {
    //     courseCategory,
    //     directFlag: official ? '1' : '0',
    //     sortBy: values.sortBy,
    //     subject: values.subject,
    //     type: values.type,
    //     stage: values.stage,
    //     pageSize
    // }})
    // this.setState({
    //     filters: values,
    //     data: result
    // })
  };

  handleFormReset = () => {
    for (let i = 1; i<5; i++) {
      this.refs["querytag" + i].reset()
    }
    this._toLoadDetail()
  };

  handlePageChange = async (page, pageSize) => {
    console.log(' page ', page, pageSize)
    // const { dispatch } = this.props;
    this._toLoadDetail(page - 1);
    // let {filters: values} = this.state;

    // // let {offline = false, isSerialSingle = false, courseCategory='', official = true} = this.props.location.query;
    // let result = await dispatch({type: 'course/getOnlineCourses', payload: {
    //     courseCategory,
    //     directFlag: official ? '1' : '0',
    //     sortBy: values.sortBy || '',
    //     subject: values.subject || '',
    //     type: values.type || '',
    //     stage: values.stage || '',
    //     pageNo: page -1,
    //     pageSize
    // }})
    // console.log(' page change reqest', result)
    // this.setState({
    //     data: result
    // })
  }

  searchChange = (event)=> {
    console.log(' search change ', event)
    this.setState({
      search: event.target.value
    }, this._toLoadDetail)
  }

  componentWillReceiveProps(nextProps) {
    console.log(' componentWillReceiveProps ', nextProps);
    if (nextProps.jobCategory.id != this.props.jobCategory.id) {
      this._toLoadDetail()
    }
  }

  render() {
    // const { course, loading} = this.props;
    const { sources, data = [], courses = [], pageSize } = this.state;
    // console.log(' render ', data)
    return (
      <div>
        <JobNavBar tabkey='1' />
        {/* <UserBanner banners={banners}/> */}
        <div className='content'>
          <Card bordered={true} style={{ width: '100%' }}>
            <Card bordered={false}>
              
              {/* <p>
                <b>工作区域：</b>
                &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;鼓楼区&nbsp;&nbsp;&nbsp;&nbsp;六合区&nbsp;&nbsp;&nbsp;&nbsp;秦淮区&nbsp;&nbsp;&nbsp;&nbsp;浦口区
                &nbsp;&nbsp;&nbsp;&nbsp;江宁区&nbsp;&nbsp;&nbsp;&nbsp;栖霞区&nbsp;&nbsp;&nbsp;&nbsp;溧水区&nbsp;&nbsp;&nbsp;&nbsp;高淳区&nbsp;&nbsp;&nbsp;&nbsp;玄武区&nbsp;&nbsp;&nbsp;&nbsp;
                雨花台区&nbsp;&nbsp;&nbsp;&nbsp;建邺区
                </p>
              <p>
                <b>职位亮点：</b>
                &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;地铁周边&nbsp;&nbsp;&nbsp;&nbsp;免费体检&nbsp;&nbsp;&nbsp;&nbsp;五险一金&nbsp;&nbsp;&nbsp;&nbsp;定期团建
                </p> */}
              <Search
                placeholder="输入关键字搜索职位"
                // onSearch={this.searchChange}
                // handleChange={this.searchChange}
                onChange={this.searchChange}
                allowClear={true}
                style={{ width: 200 }}
              />
              <MyTags ref="querytag1" queryKey='sortBy' title='工作区域' tags={constants.SORTBY_ARRAY}/>
              <MyTags ref="querytag2" queryKey='salaryRange' title='每月薪资' tags={constants.SALARY_ARRAY}/>
              <MyTags ref="querytag3" queryKey='educationDegreeRequire' title='学历水平' tags={constants.EDUCATION_ARRAY}/>
              <MyTags ref="querytag4" queryKey='experienceRequire' title='工作经验' tags={constants.WORK_YEARS_ARRAY}/>

              <span style={{ float: 'right', marginTop: 12, marginBottom: 24 }}>
                <Button style={{ marginLeft: 8 }} type="primary" onClick={this.handleSubmit}>
                  查询
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={this.handleFormReset}>
                  重置
                </Button>
              </span>
              {/* <p>
                <b>每月薪资：</b>
                &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;面议&nbsp;&nbsp;&nbsp;&nbsp;3k以下&nbsp;&nbsp;&nbsp;&nbsp;3k-5k&nbsp;&nbsp;&nbsp;&nbsp;5k-10k&nbsp;&nbsp;&nbsp;&nbsp;
                10k-20k&nbsp;&nbsp;&nbsp;&nbsp;20-50k&nbsp;&nbsp;&nbsp;&nbsp;50k以上
                </p>
              <p>
                <b>学历水平：</b>
                &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;中专&nbsp;&nbsp;&nbsp;&nbsp;高中&nbsp;&nbsp;&nbsp;&nbsp;大专&nbsp;&nbsp;&nbsp;&nbsp;本科&nbsp;&nbsp;&nbsp;&nbsp;
                研究生&nbsp;&nbsp;&nbsp;&nbsp;硕士&nbsp;&nbsp;&nbsp;&nbsp;博士
                </p>
              <p>
                <b>工作经验：</b>
                &nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;应届生&nbsp;&nbsp;&nbsp;&nbsp;1年以内&nbsp;&nbsp;&nbsp;&nbsp;1-3年&nbsp;&nbsp;&nbsp;&nbsp;3-5年&nbsp;&nbsp;&nbsp;&nbsp;
                5-10年&nbsp;&nbsp;&nbsp;&nbsp;10年以上
                </p>
              <p>
                  <b>其他要求：</b>&nbsp;&nbsp; 
                  <Dropdown overlay={menu}>
                        <Button  style={{marginRight: "20px" }}>
                          全职 <Icon type="down" />
                        </Button>
                      </Dropdown>
                      <Dropdown overlay={menu}>
                        <Button  style={{marginRight: "20px" }}>
                          学校类型 <Icon type="down" />
                        </Button>
                      </Dropdown>
                      <Dropdown overlay={menu}>
                        <Button  style={{marginRight: "20px" }}>
                          性别 <Icon type="down" />
                        </Button>
                      </Dropdown>  
                </p> */}


            </Card>
          </Card>

          <Card bordered={true} className='row'>
            <Row gutter={8}>
              {data.list && data.list.length > 0 && data.list.map((item, index) => <JobItem key={'job' + item.id} index={index} {...item} onClick={() => this.onCourseClick(item)} />)}
            </Row>
          </Card>

          <Pagination
            className='pagination'
            total={data.totalElements || 0}
            showTotal={total => `共 ${data.totalElements || 0} 条`}
            pageSize={pageSize}
            defaultCurrent={1}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(' mapStateToProps', state)
  const { city, jobCategory } = state;
  return {
    city, jobCategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsPage)
