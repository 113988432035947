import React, { Component } from 'react';
// import { connect } from 'dva';
// import { formatMessage, FormattedMessage } from 'umi/locale';
// import Link from 'umi/link';
// import router from 'umi/router';
import { Link, Route, Router } from "react-router-dom";
import { Form, Input, Button, Select, Row, Col, Popover, Progress, message } from 'antd';
import {callPatch, callPost} from 'service/api';
import {createHash} from "utils/md5";
import './Register.less';
import BackButton from 'components/backButton'

const FormItem = Form.Item;
const { Option } = Select;
const InputGroup = Input.Group;

const passwordStatusMap = {
  ok: (
    <div className='success'>
      {/* <FormattedMessage id="validation.password.strength.strong" /> */}
      强度：强
    </div>
  ),
  pass: (
    <div className='warning'>
      {/* <FormattedMessage id="validation.password.strength.medium" /> */}
      强度：中
    </div>
  ),
  poor: (
    <div className='error'>
      {/* <FormattedMessage id="validation.password.strength.short" /> */}
      强度：太短
    </div>
  ),
};

const passwordProgressMap = {
  ok: 'success',
  pass: 'normal',
  poor: 'exception',
};

// @connect(({ login, loading }) => ({
//   login,
//   // submitting: loading.effects['register/submit'],
// }))
// @Form.create()
class ResetPassword extends Component {
  state = {
    count: 0,
    confirmDirty: false,
    visible: false,
    help: '',
    prefix: '86',
  };

  componentDidUpdate() {
    // const { form, register } = this.props;
    // const account = form.getFieldValue('mail');
    // if (register.status === 'ok') {
    //   router.push({
    //     pathname: '/user/register-result',
    //     state: {
    //       account,
    //     },
    //   });
    // }
    // To disable submit button at the beginning.
    // this.props.form.validateFields();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onGetCaptcha = async () => {
    const {dispatch, form} = this.props;
    const mobile = form.getFieldValue('mobile')
    console.log(' form ', mobile);
    let count = 59;
    this.setState({ count });
    this.interval = setInterval(() => {
      count -= 1;
      this.setState({ count });
      if (count === 0) {
        clearInterval(this.interval);
      }
    }, 1000);
    try {
      const result = await callPost(
        `/users/verifyCode?userName=${mobile}&case=resetPwd`,
      );
      if (result) {
        message.success('验证码已发送')
      }
  } catch (e) {
    message.error(e.errorMessage)
  }
  };

  getPasswordStatus = () => {
    const { form } = this.props;
    const value = form.getFieldValue('password');
    if (value && value.length > 9) {
      return 'ok';
    }
    if (value && value.length > 5) {
      return 'pass';
    }
    return 'poor';
  };

  handleSubmit = e => {
    console.log('handleSubmit', e)
    e.preventDefault();
    const { form, dispatch } = this.props;
    form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        const { prefix } = this.state;
        console.log('handleSubmit go')
        callPatch(
          '/accounts/password',
          {
            username: values.mobile,
            verifyCode: values.captcha,
            password: createHash(values.password),
          },
        ).then(res => {
          if (res) {
            message.success('密码已重置')
            // router.goBack()
            this.props.history.goBack()
          }
        }).catch(e => {
          message.success(e.errorMessage)
        });
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  checkConfirm = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('两次输入的密码不匹配!');
    } else {
      callback();
    }
  };

  checkPassword = (rule, value, callback) => {
    console.log('checkPassword')
    const { visible, confirmDirty } = this.state;
    if (!value) {
      this.setState({
        help: '请确认密码！',
        visible: !!value,
      });
      callback('error');
    } else {
      this.setState({
        help: '',
      });
      if (!visible) {
        this.setState({
          visible: !!value,
        });
      }
      if (value.length < 6) {
        callback('error');
      } else {
        const { form } = this.props;
        if (value && confirmDirty) {
          form.validateFields(['confirm'], { force: true });
        }
        callback();
      }
    }
  };

  changePrefix = value => {
    this.setState({
      prefix: value,
    });
  };

  renderPasswordProgress = () => {
    const { form } = this.props;
    const value = form.getFieldValue('password');
    const passwordStatus = this.getPasswordStatus();
    return value && value.length ? (
      <div className={`progress-${passwordStatus}`}>
        <Progress
          status={passwordProgressMap[passwordStatus]}
          className='progress'
          strokeWidth={6}
          percent={value.length * 10 > 100 ? 100 : value.length * 10}
          showInfo={false}
        />
      </div>
    ) : null;
  };

  render() {
    const { form, submitting } = this.props;
    const { getFieldDecorator } = form;
    const { count, prefix, help, visible } = this.state;
    return (
      <div className='main'>
        <BackButton/>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h3>
          {/* <FormattedMessage id="app.register.register" /> */}
          重置密码
        </h3>
        </div>
        
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            <InputGroup compact>
              <Select
                size="large"
                value={prefix}
                onChange={this.changePrefix}
                style={{ width: '20%' }}
              >
                <Option value="86">+86</Option>
                {/* <Option value="87">+87</Option> */}
              </Select>
              {getFieldDecorator('mobile', {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号！',
                  },
                  {
                    pattern: /^1\d{10}$/,
                    message: '请输入11位手机号',
                  },
                ],
              })(
                <Input
                  size="large"
                  style={{ width: '80%' }}
                  placeholder={'位手机号'}
                />
              )}
            </InputGroup>
          </FormItem>
          <FormItem>
            <Row gutter={8}>
              <Col span={16}>
                {getFieldDecorator('captcha', {
                  rules: [
                    {
                      required: true,
                      message: '请输入验证码！',
                    },
                  ],
                })(
                  <Input
                    size="large"
                    placeholder={'验证码'}
                  />
                )}
              </Col>
              <Col span={8}>
                <Button
                  size="large"
                  disabled={count}
                  className='getCaptcha'
                  onClick={this.onGetCaptcha}
                >
                  {count
                    ? `${count} s`
                    : '获取验证码'}
                </Button>
              </Col>
            </Row>
          </FormItem>
          <FormItem help={help}>
            <Popover
              content={
                <div style={{ padding: '4px 0' }}>
                  {passwordStatusMap[this.getPasswordStatus()]}
                  {this.renderPasswordProgress()}
                  <div style={{ marginTop: 10 }}>
                  请至少输入 6 个字符。请不要使用容易被猜到的密码。
                  </div>
                </div>
              }
              overlayStyle={{ width: 240 }}
              placement="right"
              visible={visible}
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    validator: this.checkPassword,
                  },
                ],
              })(
                <Input
                  size="large"
                  type="password"
                  placeholder={'至少6位密码，区分大小写'}
                />
              )}
            </Popover>
          </FormItem>
          <FormItem>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: '请确认密码！',
                },
                {
                  validator: this.checkConfirm,
                },
              ],
            })(
              <Input
                size="large"
                type="password"
                placeholder={'确认密码'}
              />
            )}
          </FormItem>

          <FormItem>
            <Button
              size="large"
              loading={submitting}
              className='submit'
              type="primary"
              htmlType="submit"
            >
              重置密码
            </Button>
            <Link className='login' to="/User/Login">
              使用已有账户登录
            </Link>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create({name: 'resetpassword'})(ResetPassword);
