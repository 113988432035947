import React, { PureComponent } from 'react';
import { Modal, message } from 'antd';

export default class PayModal extends PureComponent{

    render() {
      const { showDistribution, shareImage, close } = this.props;
      return (
        <Modal
          transparent
          width={300}
          centered
          visible={showDistribution}
          onCancel={close}
          closable={true}
          maskClosable={true}
          onOk={close}
          afterClose={()=> {
            console.log(' after close callback')
          }}
          footer={
              <div style={{width: '100%', textAlign: 'center'}}>
                <span style={{color: '#999', fontSize: 12, marginTop: 10, }} >
                图片右键保存到电脑，分享给朋友拿佣金~
                </span>
              </div>
          }
        >
          <div style={{
            justifyContent: 'center',
            alignItems: 'center'}}>
              <div style={{width: '100%', marginBottom: 5}}>
                <img src={shareImage} alt='' style={{width: '100%', height: 'auto'}}/>
              </div>
          </div>
      </Modal>
    );
    }
}