import React from "react";
import styles from "./index.scss";
import { Card, Tabs, Button, Row, Col , Pagination, Breadcrumb} from "antd";
import AdSearchForm from 'components/AdSearchForm';
import VideoCourse from 'components/videoCourse';
import OnsiteCourse from 'components/OnsiteCourse';
import BackButton from 'components/backButton';
// import VideoPlayer from '@/components/videoplayer';
import { Link } from "react-router-dom";
// import router from 'umi/router';
import {getUser, getToken} from 'utils/authority'
import {callGet} from 'service/api'

// @connect(({ course,login, loading }) => ({
//   course,
//   login,
//   loading: loading.effects['course/getList']
// }))
export default class CoursesPage extends React.Component {
  constructor(props) {
    super(props);
    // console.log(' CoursesPage ', props);
    this.state = {
      // data: {id: props.location.query.id},
      data: {},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      pageSize: 20,
      filters: {}
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {

    this._toLoadDetail()
  }

  _toLoadDetail = async (pageNo=1) => {
    let {data, pageSize, filters} = this.state;
    console.log('load detail', this.props.location.state)
    let {offline = false, isSerialSingle = false, courseCategory='', official = true, moduleType=1} = this.props.location.state || {};
    let result
    let params
    try {
        if (offline) {
            params = {
              directFlag: official ? '1' : '0',
              pageNo: pageNo -1,
              pageSize,
              moduleType
            }
            result = await callGet('/public/web/onsiteCourses', params)
        } else {
          params = {
            courseCategory,
            directFlag: official ? '1' : '0',
            sortBy: filters.sortBy || '',
            subject: filters.subject || '',
            type: filters.type || '',
            stage: filters.stage || '',
            pageNo: pageNo -1,
            pageSize,
            moduleType
          }
          result = await callGet('/public/web/videoCourses', params)
        }
        console.log(' result ', result);
        this.setState({
          data: result,
        })
    } catch (e) {
        console.log('e', e)
        //utils.showToast(e.errorMessage)
    }
  }

  onCourseClick = (item)=> {
      console.log(' course click', item)
      // if (!getToken()) {
      //   this.props.history.push('login')
      //   return;
      // }
      let {offline = false} = this.props.location.state || {};
      let path = {
        pathname: `/user/learning/courseDetail/${item.id}`,
        state: {
          id: item.id,
          type: item.type,
          offline
        }}
      // router.push(path);
      this.props.history.push(path)
  }

  handleSubmit = async (values) => {
    this.setState({
        filters: values,
    }, ()=> this._toLoadDetail(1))
  };

  handleFormReset = () => {
    // const time = getTimeString('today')
   this.setState({
     filters: {}
   }, ()=> this._toLoadDetail(1))
  };

  handlePageChange = async (page, pageSize)=> {
    console.log(' page ', page, pageSize)
    this._toLoadDetail(page)
  }

  render() {
    // const { course, loading} = this.props;
    const {sources, data=[], courses=[], pageSize } = this.state;
    let {offline = false} = this.props.location.state || {};
    console.log(' render ', data)
    let formData = [
        {
          label: '综合排序',
          field: 'urge',
          component: {
            name: 'Select',
            children: [{
              value: '',
              label: '综合排序',
            },{
              value: 'createdAt',
              label: '时间',
            },{
              value: 'soldCount',
              label: '热度'
            },{
                value: 'vipPrice',
                label: '价格'
            }],
            placeholder: '请选择',
          },
          initialValue: ''
        },
        {
          label: '全部科目',
          field: 'subject',
          component: {
            name: 'Select',
            children: [
              {
                value: '',
                label: '全部',
              },
              {
                value: '1',
                label: '综合素质',
              },
              {
                value: '2',
                label: '教育知识与能力',
              },
              {
                value: '3',
                label: '保教知识与能力',
              },
              {
                value: '4',
                label: '学科知识与能力',
              },
              {
                value: '5',
                label: '教育教学知识与能力',
              },
              {
                value: '99',
                label: '其他',
              },
            ],
          },
          initialValue: ''
        },
        {
            label: '全部课程',
            field: 'type',
            component: {
              name: 'Select',
              children: [
                {
                  value: '',
                  label: '全部',
                },
                {
                  value: '1',
                  label: '系列课程',
                },
                {
                  value: '2',
                  label: '精品单课',
                },
              ],
            },
            initialValue: ''
          },
          {
            label: '全部阶段',
            field: 'stage',
            component: {
              name: 'Select',
              children: [
                {
                  value: '',
                  label: '全部',
                },
                {
                  value: '1',
                  label: '幼儿',
                },
                {
                  value: '2',
                  label: '小学',
                },
                {
                    value: '3',
                    label: '中学',
                  },
                {
                  value: '99',
                  label: '其他',
                },
              ],
            },
            initialValue: ''
          },];
    const user = getUser()
    return (
      <div>
        {/* <UserLearningNavBar /> */}
        {/* <UserBanner banners={banners}/> */}
        <BackButton/>

        <div className={styles.content}>
        <AdSearchForm
          className={styles.search}
          formData={formData}
          handleFormReset={this.handleFormReset}
          handleSubmit={this.handleSubmit}
          exportBill={this.exportBill}
          multiRecommit={getUser() && getUser().Sid === 3 && this.openMultiRecommit}
          multiBegin={this.multiBegin}
        />
            <Row className={styles.row} gutter={8}>
                {data.list && data.list.length > 0 && data.list.map(item => offline ?  <OnsiteCourse {...item} onClick={()=> this.onCourseClick(item)}/>: <VideoCourse {...item} onClick={()=> this.onCourseClick(item)}/>)}
            </Row>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Pagination
                className={styles.pagination}
                total={data.totalElements || 0}
                showTotal={total => `共 ${data.totalElements || 0} 条`}
                pageSize={pageSize}
                defaultCurrent={1}
                onChange={this.handlePageChange}
            />
            </div>
        </div>
      </div>
    );
  }

}
