export default {
    transparent: 'rgba(0,0,0,0)',
    black50trans: 'rgba(0,0,0,.5)',
    orange: '#f5a220',
    black74: '#747474',
    black3a: '#3A3A3A',
    black: '#000',
    black36: '#363636',
    black34: '#343434',
    black47: '#474747',
    black27: '#272727',
    black72: '#727272',
    black56: '#565656',
    black5F: '#5f5f5f',
    black3B: '#3B3B3B',
    black33: '#333',
    black3D: '#3d3d3d',
    black2F: '#2F2F2F',
    white: '#fff',
    grey_line: '#f5f5f5',
    black61: '#616161',
    greyF8: '#F8F8F8',
    orangeFfa700: '#FFA700',
    orangeFFC600: '#FFC600',
    orangeFFC001: '#FFC001',
    orangeFEE951: '#FEE951',
    disabled: '#9c9c9c',
    disabledLight: '#ebebeb',
    grey6b: '#6B6B6B',
    greyA2: '#A2A2A2',
    grey6D: '#6d6d6d',
    grey93: '#939393',
    greyD4: '#d4d4d4',
    grey9C: '#9c9c9c',
    greyA9: '#a9a9a9',
    grey8B: '#8b8b8b',
    grayDE: '#dedede',
    greyC1: '#c1c1c1',
    greyC8: '#c8c8c8',
    greyF2: '#f2f2f2',
    greyAc: '#acacac',
    greyAb: '#ababab',
    greyEb: '#ebebeb',
    greya3: '#A3A3A3',
    greyF7F8FC: '#F7F8FC',
    grey7E: '#7e7e7e',
    grey73: '#737373',
    grey5F: '#5f5f5f',

    redFF6B56: '#FF6B56',
    redF65E2D: '#F65E2D',
    redFF6550: '#FF6550',
    redFF687B: '#FF687B',
    redF97321: '#F97321',
    greyEf: '#efefef',
    orangeF07F1D: '#F07F1D',
    green00B67A: '#00B67A',
    green00B378: '#00B378',
    green5CCB4E: '#5CCB4E',
    orangeFFC65A: '#FFC65A',
    orangeFFC75E: '#FFC75E',
    yellowFFB432: '#FFB432',
    yellowFFF2BE: '#FFF2BE',
    goldD3963E: '#D3963E',
    goldE8C27C: '#E8C27C',
    blue53AEFF: '#53AEFF',

    windowTint: 'rgba(0, 0, 0, 0.4)',
    theme:"#f5a220",
}