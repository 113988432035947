import React from "react";
import "./index.scss";
import {
  Card,
  Tabs,
  Button,
  Row,
  Col,
  Breadcrumb,
  Icon,
  Divider,
  Pagination
} from "antd";
import UserLearningNavBar from "components/learning-navbar";

const { TabPane } = Tabs;

export default class UserQuizPage extends React.Component {
  render() {
    return (
      <div>
        <UserLearningNavBar />
        <div className="user-learning-page-background">
          <div className="user-learning-page-container">
            <Breadcrumb>
              <Breadcrumb.Item>习题练习</Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="">公开习题</a>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Row gutter={24} style={{ marginTop: "20px" }}>
              <Col span={8}>
                <Card bordered={false} title="年级阶段">
                  <p>
                    幼儿园&nbsp;&nbsp;&nbsp;&nbsp;小学&nbsp;&nbsp;&nbsp;&nbsp;中学&nbsp;&nbsp;&nbsp;&nbsp;其他
                  </p>
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false} title="科目">
                  <p>
                    综合素质&nbsp;&nbsp;&nbsp;&nbsp;教育知识与能力&nbsp;&nbsp;&nbsp;&nbsp;保教知识与能力&nbsp;&nbsp;&nbsp;&nbsp;其他
                  </p>
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false} title="话题类型">
                  <p>真题&nbsp;&nbsp;&nbsp;&nbsp;练习题</p>
                </Card>
              </Col>
            </Row>
            <Card bordered={false}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="全部试题" key="1">

                <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                    <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                                        <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={1}>
                    <Icon
                      type="file-text"
                      style={{ color: "#FFA70F", fontSize: "40px" }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                  <p>
                      <b>2018年下半年小学语文综合素质</b></p> <br />
                      <p>
                        总分值：40分&nbsp;&nbsp;题数：20题&nbsp;&nbsp;已做数：12323人
                      
                    </p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <Button type="primary" icon="edit">
                      马上做题
                    </Button>
                  </Col>
                </Row>
                <Divider type="horizontal" />

               
              </TabPane>
            </Tabs>
            </Card>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Pagination defaultCurrent={1} defaultPageSize={9} total={15} />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
