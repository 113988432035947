import React from "react";
import {
  Card,
  Tabs,
  Button,
  Row,
  Col,
  Pagination,
  Divider,
  Icon,
  Tag,
  Menu,
  Input,
  Dropdown,
  DatePicker,
  Table,
  message
} from "antd";
import UserLearningNavBar from "components/learning-navbar";
import "./index.scss";

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;


const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: text => <a href="javascript:;">{text}</a>
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: tags => (
      <span>
        {tags.map(tag => {
          let color = tag.length > 5 ? "geekblue" : "green";
          if (tag === "loser") {
            color = "volcano";
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    )
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <a href="javascript:;">Invite {record.name}</a>
        <Divider type="vertical" />
        <a href="javascript:;">Delete</a>
      </span>
    )
  }
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"]
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"]
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["cool", "teacher"]
  }
];

function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">
      <Icon type="user" />
      1st menu item
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="user" />
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3">
      <Icon type="user" />
      3rd item
    </Menu.Item>
  </Menu>
);

const operations = (
  <div>
    <Icon type="retweet" />
    重置
    <Divider type="vertical" />
    <Icon type="cloud-download" />
    导出
  </div>
);

function onChange(date, dateString) {
    console.log(date, dateString);
  }  

export default class UserStudyPage extends React.Component {
  render() {

    return (
      <div className="user-study-page-background">
      <div className="user-study-page-container">
        </div>
        </div>
     
    );
  }
}
