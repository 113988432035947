import React, { Component } from "react";
import {
  Tabs,
  Button,
  Card,
  DatePicker,
  Statistic,
  Row,
  Col,
  Icon,
  Table,
  Divider,
  Tag,
  Input,
  Menu,
  Dropdown,
  message,
  Comment,
  Tooltip,
  List
} from "antd";
import moment from "moment";

import "./index.scss";

const { MonthPicker, RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";
const monthFormat = "YYYY/MM";

function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">
      <Icon type="user" />
      1st menu item
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="user" />
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3">
      <Icon type="user" />
      3rd item
    </Menu.Item>
  </Menu>
);

export default class UserCertPage extends Component {
  render() {
    return (
      <div className="user-cert-page-background">
        <div className="user-cert-page-container">
          <Card bordered={false}>
          <br/>
          <br/>
          <div style={{ textAlign:'center', fontSize:"30px" }}>
                  辅导教师资格标准证书查询
                </div>
          <br/>
          <br/>
            <Row gutter={8}>
              <Col span={10}>
                <div style={{ textAlign:'right' }}>
                  认证时间：
                </div>
                </Col>
                <Col span={12}>
                <div style={{textAlign:'left' }}>
                  <MonthPicker
                    defaultValue={moment("2015/01", monthFormat)}
                    format={monthFormat}
                  />
                  </div>
                   </Col>
                </Row>
                <br/>
                <Row gutter={8}>
              <Col span={10}>
                <div style={{ textAlign:'right' }}>
                认证级别：
                </div>
                </Col>
                <Col span={12}>
                <div style={{textAlign:'left' }}>
                 <Dropdown overlay={menu}>
                    <Button style={{ marginRight: "20px" }}>
                      初级认证 <Icon type="down" />
                    </Button>
                  </Dropdown>
                  </div>
                   </Col>
                </Row>
                <br/>

                <Row gutter={8}>
              <Col span={10}>
                <div style={{ textAlign:'right' }}>
                 姓名：
                </div>
                </Col>
                <Col span={12}>
                <div style={{textAlign:'left' }}>
                <Input
                    style={{ width: "200px", marginRight: "20px" }}
                    placeholder=" "
                  />
                  </div>
                   </Col>
                </Row>
                <br/>

                <Row gutter={8}>
              <Col span={10}>
                <div style={{ textAlign:'right' }}>
                * 身份证号：
                </div>
                </Col>
                <Col span={12}>
                <div style={{textAlign:'left' }}>
                <Input
                    style={{ width: "300px", marginRight: "20px" }}
                    placeholder=" "
                  />
                  </div>
                   </Col>
                </Row>
                <br/>

                <Row gutter={8}>
              <Col span={10}>
                <div style={{ textAlign:'right' }}>
                * 证件号：
                </div>
                </Col>
                <Col span={12}>
                <div style={{textAlign:'left' }}>
                <Input
                    style={{ width: "300px", marginRight: "20px" }}
                    placeholder=" "
                  />
                  </div>
                   </Col>
                </Row>
                <br/>
                <Row gutter={8}>
              <Col span={10}>
                <div style={{ textAlign:'right' }}>
                <Button type="primary">查询</Button>
                </div>
                </Col>
                <Col span={12}>
                <div style={{textAlign:'left', marginLeft: "20px"  }}>
                <Button >重置</Button>
                  </div>
                   </Col>
                </Row>
                <br/> <br/>

          </Card>
        </div>
      </div>
    );
  }
}
