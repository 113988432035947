// import { routerRedux } from 'dva/router';

// use localStorage to store the authority info, which might be sent from server in actual project.
// export function getAuthority(str) {
// return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
// const authorityString =
//   typeof str === 'undefined' ? localStorage.getItem('antd-pro-authority') : str;
// // authorityString could be admin, "admin", ["admin"]
// let authority;
// try {
//   authority = JSON.parse(authorityString);
// } catch (e) {
//   authority = authorityString;
// }
// console.log('authority',authority)
// if (typeof authority === 'string') {
//   return authority;
// }
// return authority || ['admin'];
// }
export function getAuthority() {
  let auth = '';
  try {
    auth = sessionStorage.getItem('antd-pro-authority');
    return auth;
  } catch (err) {
    return auth;
  }
}

export function setAuthority(authority) {
  // const proAuthority = typeof authority === 'string' ? [authority] : authority;
  // return localStorage.setItem('antd-pro-authority', JSON.stringify(proAuthority));
  return sessionStorage.setItem('antd-pro-authority', authority);
}

export function setToken(token) {
  return sessionStorage.setItem('admin_token', token);
}

export function getToken() {
  return sessionStorage.getItem('admin_token') || '';
}

export function setUser(user) {
  let userStr = '';
  try {
    userStr = JSON.stringify(user);
  } catch (e) {
    // console.log(e);
  }
  return sessionStorage.setItem('user', userStr);
}
export function getUser() {
  if (getToken() && getToken() !== '') {
    const userStr = sessionStorage.getItem('user');
    let user = {};
    try {
      user = JSON.parse(userStr);
    } catch (e) {
      // console.log(e);
    }
    return user;
  }
  // routerRedux.push('/user/login');
  return {};
}

export function setCity(city) {
  let userStr = '';
  try {
    userStr = JSON.stringify(city);
  } catch (e) {
    // console.log(e);
  }
  return sessionStorage.setItem('city', userStr);
}

export function getCity() {
  let city
    const userStr = sessionStorage.getItem('city');
    try {
      city = JSON.parse(userStr);
    } catch (e) {
      // console.log(e);
    }
    if (!city) {
      city = {
        name: '南京市',
        code: '3201'
      }
    }
    return city;
}

export function setJobCategory(user) {
  let userStr = '';
  try {
    userStr = JSON.stringify(user);
  } catch (e) {
    // console.log(e);
  }
  return sessionStorage.setItem('jobCategory', userStr);
}

export function getJobCategory() {
  let city
    const userStr = sessionStorage.getItem('jobCategory');
    // console.log(' getJobCategory ', userStr)
    try {
      city = JSON.parse(userStr);
    } catch (e) {
      console.log(e);
    }
    if (!city) {
      city = {
      }
    }
    return city;
}

export function setShop(shop) {
  let shopStr = '';
  try {
    shopStr = JSON.stringify(shop);
  } catch (e) {
    // console.log(e);
  }
  return localStorage.setItem('shop', shopStr);
}
export function getShop() {
  if (getToken() && getToken() !== '') {
    const shopStr = localStorage.getItem('shop');
    let shop = {};
    try {
      shop = JSON.parse(shopStr);
    } catch (e) {
      // console.log(e);
    }
    return shop;
  }
  // routerRedux.push('/user/login');
  return {};
}
export function setSignList(sign) {
  let signStr = '';
  try {
    signStr = JSON.stringify(sign);
  } catch (e) {
    // console.log(e);
  }
  return localStorage.setItem('sign', signStr);
}
export function getSignList() {
    const signStr = localStorage.getItem('sign');
    if(signStr) {
      let sign = {};
      try {
        sign = JSON.parse(signStr);
      } catch (e) {
        // console.log(e);
      }
      return sign;
    }
      return {}
}

export function setGrey(grey) {
  return localStorage.setItem('grey', grey || false);
}

export function getGrey() {
  if (getToken() && getToken() !== '') {
    return localStorage.getItem('grey');
  }
  // routerRedux.push('/user/login');
  return false;
}

export function setAddress(user) {
  let userStr = '';
  try {
    userStr = JSON.stringify(user);
  } catch (e) {
    // console.log(e);
  }
  return sessionStorage.setItem('ship_address', userStr);
}

export function getAddress() {
  const userStr = sessionStorage.getItem('ship_address');
  let user = undefined;
  try {
    user = JSON.parse(userStr);
  } catch (e) {
    // console.log(e);
  }
  return user;
}

