import React, { PureComponent } from 'react';
import { Modal, Input, Radio, message } from 'antd';
import './index.scss';
import constants from '../../utils/constants';

export default class PayMethodModal extends PureComponent{

  constructor(props) {
    super(props);
    console.log(" pay method props", props)
    this.state = {
      cardname: props.type == constants.products.DOCUMENTS ? '题库（备课）卡' : props.type == constants.products.QUIZ ? '刷题卡' : '听课卡',
    }
  }

  componentDidMount() {
    // this.pollPayResult(this.props.orderNumber)
  }

  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };

  isPayAlipay() {
    return this.state.value == 1
  }

  isPayCard() {
    return this.state.value == 2
  }

  getVIPCardInfo = ()=> {
    return {cardVipId: this.idRef.state.value, cardVipPwd: this.pwdRef.state.value}
  }

  handleOk = ()=> {
    console.log(' on ok', this.state.value)
    const { onOk } = this.props;
    if (this.state.value == 2) {
      if (!this.idRef.state.value || !this.pwdRef.state.value) {
        message.error('请输入卡密')
        return;
      }
    }
    onOk()
  }

    render() {
      let {cardname} = this.state;
      const { isShowPayModal, onClose, payCardFlag } = this.props;
      const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      };
      return (
        <Modal
          visible={isShowPayModal}
          okText='确认'
          cancelText='取消'
          onCancel={onClose}
          closable={false}
          onOk={this.handleOk}
          afterClose={()=> {
            console.log(' after close callback')
          }}
        >
          <div style={{
                justifyContent: 'center',
                alignItems: 'center'}}>
            <div style={{width: '100%', marginBottom: 5}}>
                <div style={{backgroundColor: '#fff', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, alignItems: 'center', marginTop: -1}}>
                    <div><span style={{color: '#FFC001', fontSize: 20, marginBottom: 10, fontWeight: 'bold', justifyContent: 'center'}}>选择支付方式</span></div>
                    <Radio.Group onChange={this.onChange} value={this.state.value}>
                        <Radio style={radioStyle} value={1}>
                        支付宝支付
                        </Radio>
                        {payCardFlag === 1 && <Radio style={radioStyle} value={2}>
                        卡号支付
                        {this.state.value === 2 && <div>
                          <Input style={{ width: 200, marginLeft: 10 }} placeholder={`请输入${cardname}号`} ref={(ref)=> this.idRef = ref}/>
                          <br/>
                          <Input style={{ width: 200, marginLeft: 10, marginTop: 10 }} placeholder={`请输入${cardname}密码`} ref={(ref)=> this.pwdRef = ref}/>
                        </div>}
                        </Radio>}
                    </Radio.Group>
                    {/* <div style={{ }}>
                        <img source={alipayIcon} />
                        <span style={{color: '#999', fontSize: 16, marginTop: 10}} >
                        支付宝支付
                        </span>
                    </div>
                    <div style={{ }}>
                        <img source={alipayIcon} />
                        <span style={{color: '#999', fontSize: 16, marginTop: 10}} >
                        卡号支付
                        </span>
                    </div> */}
                </div>
            </div>
        </div>
      </Modal>
    );
    }
}