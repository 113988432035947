import React, { PureComponent } from 'react';
import {withRouter} from 'react-router-dom';
import * as utils from 'utils/utils';

class CompanyAvatar extends PureComponent{

    back = ()=> {
      // console.log(' back button', this.props)
    }

    render() {
        let { avatarUrl, onClick, isVip, size = 'normal', style={}} = this.props
        let avatarSize = size == 'normal' ? 50 : 80
        let vipSize= size == 'normal' ? 12 : 20;
        let borderRadius = size == 'normal' ? 5: 8;
        let inset = size == 'normal' ? 5: 8

        let styles = {
          color: '#E8B400',
          fontSize: 13,
          border: '1px solid #E8B400',
          height: 24,
          textAlign: 'center',
          borderRadius: 12,
          marginLeft: 12,
          paddingLeft: 8,
          paddingRight: 8,
          cursor: 'pointer',
          ...style
          }
        return (
          <div onClick={onClick ? onClick : this.back} style={{position: 'relative'}}>
            <div style={{borderRadius, overflow: 'hidden'}}>
                <img src={avatarUrl || 'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/org_avatar.png'}
                    style={{width: avatarSize, height: avatarSize }}/>
            </div>
            {isVip === 1 &&
            <div style={{
                position: 'absolute',
                bottom: - inset,
                right: - inset
            }}>
                <img src={'https://syyc-img.oss-cn-shanghai.aliyuncs.com/h5/vip.png'} style={{
                    width: vipSize,
                    height: vipSize,
                }}/>
            </div>
            }
        </div>
        );
    }
}

export default withRouter(CompanyAvatar)