import React from "react";
import styles from "./index.scss";
import { Card, DatePicker, Button, Row, Col , Radio, Select, Table, message} from "antd";
import JobItem from 'components/job';
import { Link } from "react-router-dom";
// import router from 'umi/router';
import * as utils from 'utils/utils';
import dateutils from 'utils/dateutils'
import {getUser, getToken} from 'utils/authority'
import constants from 'utils/constants'
import {callGet, callPost, syncUserInfo} from 'service/api'
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'
import WalletBackgroundImage from "images/wallet_page_bg.png";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

const { MonthPicker } = DatePicker;

const columns = [
  {
    title: "账单",
    dataIndex: "title",
    key: "title",
    // render: text => <a href="javascript:;">{text}</a>
  },
  {
    title: "交易单号",
    dataIndex: "orderNo",
    key: "orderNo"
  },
  {
    title: "支付方式",
    dataIndex: "paymentType",
    key: "paymentType",
    render: paymentType => (
      <span>
        {paymentType == 1 ? '支付宝' : paymentType == 2 ? '微信' : paymentType == 3 ? '钱包': 'Apple Pay'}
      </span>
    )
  },
  {
    title: "支付时间",
    key: "orderedTime",
    dataIndex: "orderedTime",
  },
  {
    title: "交易金额",
    key: "amountString",
    dataIndex: "amountString",
  }
];

const MONTH_FORMAT = 'yyyy/MM'

// @connect(({ job, login, loading }) => ({
//     job,
//     login,
// }))
export default class ResumeDetailPage extends React.Component {
  constructor(props) {
    super(props);
    // console.log(' JobDetailPage ', props);
    // const {id} = props.match.params;
    let now = new Date()
    // console.log(' month ',dateutils.formatDate(now, MONTH_FORMAT) )
    this.state = {
      levels: [],
      // data: {id},
      data: {},
      tableData: {},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      positionNum: 0,
      recommends: [],
      isApplied: false,
      isShowBuyTip: false,
      isShowPayModal: false,
      type: 1,
      payAmount: 0,
      orderNumber: '',
      levelIndex: 0,
      count: 0,
      selectDate: dateutils.formatDate(now, MONTH_FORMAT),
      pagenation:{
        current: 1,
        pageSize: 10,
        total: 0
      }
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {

    // const script = document.createElement("script")
    // script.src = "https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js"
    // // script.async = true

    // const flexibleScript = document.createElement("script")
    // flexibleScript.src = "http://player.alicdn.com/resource/player/lib/flexible.min.js";
    
    // const zeptoScript = document.createElement('script')
    // zeptoScript.src = 'https://cdn.jsdelivr.net/npm/zepto/zepto.min.js';
    // document.body.appendChild(flexibleScript)
    // document.body.appendChild(script)
    // document.body.appendChild(zeptoScript)

    this._toLoadDetail()
    this._toLoadList()
  }

  // componentWillReceiveProps(newProps) {
  //     const {id} = newProps.match.params;
  //     const {data} = this.state;
  //     console.log(' componentWillReceiveProps ', newProps, this.state)
  //     if (id !== this.state.data.id) {
  //         data.id = id;
  //         this.setState({
  //           data
  //         }, this._toLoadDetail)
  //     }
  // }

  _toLoadDetail = async () => {
    if (!getToken()) {
      return;
    }
    let {resume} = this.state;
    // console.log('load detail', data.id)
    if (!resume) {
      return;
    }
    try {
        let result = await callGet('/resumes/' + resume.resumeId)
        if (result) {
            this.setState({
                resume: result
            })
        }
    } catch (e) {
        console.log('e', e.errorMessage)
        message.error(e.errorMessage)
    }
  }

  _toLoadList = async ()=> {
    if (!getToken()) {
      return;
    }

    try {
        const list = await callGet('/resumes', {pageNo: 0, pageSize: 1})
        this.setState({
            resume: list && list.length > 0 ? list[0] : undefined,
        }, this._toLoadDetail)
    } catch (e) {
        console.log('e', e)
        message.error(e.errorMessage)
    }
  }

  _toLoadPageData = async ()=> {
    
  }

  handleDateChange = (date, dateString) => {
    console.log(`selected ${date}`, dateString);
    this.setState({
      selectDate: dateString
    }, this._toLoadList)
  }

  createResume = ()=> {
    message.warn('请移步师业有成app完善简历')
  }

  render() {
    // const { course, loading} = this.props;
    const {sources, data, income, outcome, tableData, resume } = this.state;
    const {isMobile} = this.props;
    console.log('render', sources)

    return (
      <div style={{paddingTop: 12}}>
        <div className='user-learning-page-container'>
          <BackButton/>
        {!resume && <div>
          <Button onClick={this.createResume} type="primary" size="small">创建简历</Button>
          </div>}
          </div>
      </div>
    );
  }
}