import React from "react";
import "./index.scss";
import { Card, Tabs, Button, Row, Col ,Icon, message} from "antd";
import UserLearningNavBar from "components/learning-navbar";
import UserBanner from "components/banner";
import VideoCourse from 'components/videoCourse'
import LiveCourse from 'components/liveCourse'
import {getUser, getToken} from 'utils/authority'
import {callGet} from 'service/api'
import { connect } from "react-redux";

const { TabPane } = Tabs;

class UserOnlineCoursePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveStreamCourses: [],
      officialVideoCourses: [],
      thirdPartyVideoCourses: [],
      banners: []
    }
  }

  componentDidMount() {
    console.log(' env ', process.env);
    this.reload()
  }

  componentWillReceiveProps(nextProps) {
    console.log(' componentWillReceiveProps ', nextProps);
    if (nextProps.jobCategory.id != this.props.jobCategory.id) {
      this.reload()
    }
  }

  reload = ()=> {
    callGet('/public/web/recommendOnlineCourses').then((res)=>{
          console.log('res',res)
        // let courseList = res.data.data.map()
        const {liveStreamCourses, officialVideoCourses, thirdPartyVideoCourses} = res;
        this.setState({
          liveStreamCourses,
          officialVideoCourses,
          thirdPartyVideoCourses
        })
      console.log('res',res)
    }).catch( (err)=>{
      message.error(JSON.stringify(err.errorMessage))
      console.log('err',err)
    });

    callGet('/public/banners', {
      platformType: 1,
      category: 2,
      deviceType: 4
    }).then((res)=>{
          console.log('res',res)
        // let courseList = res.data.data.map()
        this.setState({
          banners: res,
        })
      console.log('res',res)
    }).catch( (err)=>{
      message.error(JSON.stringify(err.errorMessage))
      console.log('err',err)
    });
  }

  operations = (index) => {
    return  <p onClick={()=> this.more(index)}>
      <Button type="primary" size="small">
       更多
      </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </p>
  }

  more = (index)=> {
    console.log('more', index)
    let path
    if (index === 0) {
        path = {
          pathname: '/user/learning/liveCourses',
          state: {
            moduleType: 1
          }}
    } else {
      path = {
        pathname: '/user/learning/courses',
        state: {
          official: index === 1,
        }
      }
    }
    this.props.history.push(path)
  }

  onLiveCourseClick = (item)=> {
    console.log(' course click', getToken())
    // sessionStorage.setItem('token', res.data.data.token);
    // sessionStorage.getItem('token')
    if (!getToken()) {
      this.props.history.push('/user/login')
      return;
    }
    let path = {
      pathname: `/user/learning/courseDetail/${item.liveStreamCourseId}`,
      state: {
        id: item.liveStreamCourseId,
        liveCourse: true
      }}
    // router.push(path);
    this.props.history.push(path)
  }

  onCourseClick = (item)=> {
    console.log(' course click', getToken())
    // sessionStorage.setItem('token', res.data.data.token);
    // sessionStorage.getItem('token')
    if (!getToken()) {
      this.props.history.push('/user/login')
      return;
    }
    let path = {
      pathname: `/user/learning/courseDetail/${item.id}`,
      state: {
        id: item.id,
        type: item.type
      }}
    // router.push(path);
    this.props.history.push(path)
  }

  render() {
    const {liveStreamCourses=[], officialVideoCourses=[], thirdPartyVideoCourses=[], banners=[]} = this.state;
    return (
      <div>
        <UserLearningNavBar tabkey="1"/>
        <UserBanner banners={banners}/>
        
        <div className="user-learning-page-background">
          <div className="user-learning-page-container">
            <Tabs defaultActiveKey="1" tabBarExtraContent={this.operations(0)}>
                <TabPane tab="直播课堂" key="1">
                <Row>
                  {liveStreamCourses.map(item => <LiveCourse key={'live'+item.id} {...item} onClick={()=> this.onLiveCourseClick(item)}/>)}
                </Row>
                </TabPane>
              </Tabs>

              <Tabs defaultActiveKey="1" tabBarExtraContent={this.operations(1)}>
                <TabPane tab="直营视频课程" key="1">
                <Row>
                  {officialVideoCourses.map(item => <VideoCourse key={'official'+item.id} {...item} onClick={()=> this.onCourseClick(item)}/>)}
                </Row>
                </TabPane>
              </Tabs>

              <Tabs defaultActiveKey="1" tabBarExtraContent={this.operations(2)} style={{marginTop:'20px'}}>
                <TabPane tab="加盟视频课程" key="1" >
                  <Row>
                    {thirdPartyVideoCourses.map(item => <VideoCourse key={'thirdparty'+item.id} {...item} onClick={()=> this.onCourseClick(item)}/>)}
                  </Row>
                </TabPane>
              </Tabs>  
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {jobCategory} = state;
  return {
    jobCategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (UserOnlineCoursePage);