import React from "react";
import {
  Layout,
  Menu,
  Icon,
  Row,
  Col,
  Input,
  Cascader,
  Modal,
  Tabs,
  Card,
  List
} from "antd";
import "./index.scss";
import { Link } from "react-router-dom";
import Logo from "images/logo/syyc-logo.png";
import checkIcon from "images/check_enable.png";
import arrowDown from "images/arrow_down.png";
import MyFooter from "../../app/common/components/footer";
import { enquireScreen } from 'enquire-js';
import {getUser, getToken, setToken, setUser, setJobCategory, setCity} from 'utils/authority'
import {callGet} from 'service/api'
import { connect } from "react-redux";
import {updateCity, updateJobCategory} from 'reduxs/actions'
import { callPost } from "../../service/api";
import { updateUser } from "../../reduxs/actions";
const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

class UserLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 'learning',
      city: props.city,
      isMobile: false,
      districts: [],
      data:[
      ],
      jobIndex: 0,
      jobCategory: props.jobCategory,
    }
  }

  parsePath = (path)=> {
    console.log('path ', path.split('/'))
    return path.split('/')[2];
  }

  componentDidMount() {
    const { location } = this.props.children.props;
    console.log(' the props ', this.props)
    let tab = this.parsePath(location.pathname)
    if (tab) {
      this.setState({
        current: tab
      })
    }

    this.enquireHandler = enquireScreen(mobile => {
      const { isMobile } = this.state;
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });

    this.getBasicInfo()

    if (!this.props.jobCategory || !this.props.jobCategory.id) {
      this.openJobCategory()
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(' componentWillReceiveProps ', nextProps);
    if (nextProps.user.channelId !== this.props.user.channelId) {
      this.reload()
    }
  }

  reload = ()=> {
    console.log(' load it')
    let data = this.state.data;
    if (getUser() && data.length > 0) {
      data.forEach((item, index)=> {
        if (item.id === getUser().channelId) {
          this.setState({
            jobIndex: index,
            jobCategory: item
          })
          this.props.toUpdateJobCategory(item)
          return;
        }
      })
    }
  }

  handleClick = e => {
    console.log('click ', e);
    const {current='learning'} = this.state;
    if (current !== e.key) {
      // router.push(`/${e.key}`)
      this.props.children.props.history.push('/user/'+e.key)
      // switch(e.key) {
      //   case '1':
      //     router.push('/learning');
      //     break;
      //   case '2':
      //     router.push('/job');
      //     break;
      //     case '5':
      //     router.push('/job');
      //     break;
      //     case '7':
      //     router.push('/user');
      //     break;
      //   default:
      //       break;
      // }
      this.setState({
        current: e.key
      })
    }
  }

  onCityChange =  (val,citys) => {
    console.log('on city change ', val, citys)
    let pro
    if (citys.length === 2) {
      pro = citys[1]
      let city = {
        name: pro.name,
        code: pro.code
      }
      this.setState({
        city
      })
      // setCity(city)
      this.props.toUpdateCity(city)
    }
  }

  login = ()=> {
    console.log('this props', this.props)
    const theProp = this.props.children.props;
    if (getToken()) {
      // callLogout()
      setToken('')
      setUser({})
      theProp.history.push('/user/login')
    } else {
      // callLogin()
      theProp.history.push('/user/login')
    }
  }

  register = ()=> {
    const theProp = this.props.children.props;
    theProp.history.push('/user/register')
  }

  jumpToOrg = ()=> {
    window.open('https://mp.syyc.net/company')
  }

  onCityCascaderVisible = (visible) => {
    console.log('e', visible)
    const {districts} = this.state;
    if (visible && districts.length === 0) {
      this.getDistrictsInfo()
    }
  }

  getDistrictsInfo = async ()=> {
    try {
      const result = await callGet('/public/districts');
      if (result) {
        result.forEach(pro => {
          pro.child.forEach(city => {
            city.child = undefined
          })
        })
      }
      this.setState({
        districts: result || []
      })
    } catch (e) {
      console.log('e', e)
    }
  }

  getBasicInfo = async ()=> {
    try {
      const result = await callGet('/public/basicData');
      this.setState({
        data: result || [],
        jobCategory: result.length > 0 ? result[0] : {}
      }, this.reload)
    } catch (e) {
      console.log('e', e)
    }
  }

  openJobCategory = ()=> {
    this.setState({
      isShowBuyTip: true
    })
  }

  hideJobCategory = ()=> {
    this.setState({
      isShowBuyTip: false
    })
  }

  onleftClick = ()=> {
    console.log(" on left click")
  }

  onRightClick = ()=> {
    console.log('on right click')
  }

  render() {
    const {current, isMobile, city, districts, jobCategory} = this.state;
    // console.log('render', this.state)
    return (
      <div>
          <div className="user-layout-upper-header">
            <Row gutter={8} style={{ backgroundColor: "#ffffff" }}>
              <Col span={isMobile ? 6 : 2}>
                <img className="user-layout-logo" src={Logo} style={{marginLeft: isMobile ? 10 : 0}} alt=''/>
              </Col>
              <Col span={isMobile ? 18 : 22}>
                <Row gutter={16} style={{color: '#999999', fontSize: 12}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-end', alignItems: 'center', padding: 10, cursor: 'pointer'}}>
                    <span onClick={this.jumpToOrg} style={{marginRight: isMobile ? 10 : 20}}>切换到企业版</span>
                    <div style={{marginRight: isMobile ? 10 : 20}}>
                      <span style={{marginRight: 0, cursor: 'pointer'}} onClick={this.openJobCategory}>{jobCategory && jobCategory.id ? jobCategory.name : '选择职业属性'}</span>
                      <img src={arrowDown} style={{width: 18, height: 18, filter: 'grayscale(80%)'}}/>
                    </div>
                    <Cascader fieldNames={{ label: 'name', value: 'code', children: 'child' }} options={districts} onPopupVisibleChange={this.onCityCascaderVisible} changeOnSelect onChange={this.onCityChange}>
                    <span>{city.name}[切换城市]</span>
                    </Cascader>
                    <div onClick={this.login} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginLeft: isMobile ? 10 : 20}}><Icon type="book" style={{marginRight: 6}} /> {getToken() ? '退出登录' : '登录'}</div>
                    {!getToken() && <span style={{margin: isMobile ? '0 4px' : '0 4px'}}>|</span>}
                    {!getToken() && <div onClick={this.register} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}> {'注册'}</div>}
                  </div>
                </Row>

                <Row gutter={16}>
                  {/* <Col span={4} className="user-layout-search-input">
                    <Search
                      placeholder="搜索"
                      onSearch={value => console.log(value)}
                      style={{ width: 180 }}
                    />
                  </Col> */}
                  
                  <Col>
                    <Menu mode="horizontal" style={{ lineHeight: "64px" }} selectedKeys={[current]} onClick={this.handleClick}>
                      <Menu.Item className="layout-menu-item" key="learning">
                        <Link to="/user/learning">成师</Link>
                      </Menu.Item>

                      <Menu.Item className="layout-menu-item" key="job">
                        <Link to="/user/job">成业</Link>
                      </Menu.Item>

                      {/* <Menu.Item className="layout-menu-item" key="study">
                        <Link to="/user/study">学习中心</Link>
                      </Menu.Item> */}

                      <Menu.Item className="layout-menu-item" key="myjob">
                        <Link to="/user/myjob">简历投递</Link>
                      </Menu.Item>

                      {/* <Menu.Item className="layout-menu-item" key="message">
                      <Link to="/user/message">消息</Link>
                      </Menu.Item> */}

                      <Menu.Item className="layout-menu-item" key="my">
                        <Link to="/user/my">我的</Link>
                      </Menu.Item>
                      <Menu.Item className="layout-menu-item" key="membership">
                      <Link to="/user/membership">会员中心</Link>
                      </Menu.Item>

                       <Menu.Item className="layout-menu-item" key="joinus">
                        <Link to="/user/joinus">加入我们</Link>
                      </Menu.Item>

                      {/* <Menu.Item className="layout-menu-item" key="complaint">
                      <Link to="/user/complaint">反馈与帮助</Link>
                      </Menu.Item> */}
                    </Menu>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

        <div className='user-content'>{this.props.children}</div>

        <MyFooter isMobile={isMobile}/>

        {this.renderChooseModal()}
      </div>
    );
  }

  renderChooseModal = () => {
    const { isShowBuyTip, data, jobIndex } = this.state;
    return (
      <Modal
        visible={isShowBuyTip}
        footer={null}
        closable={false}
        wrapClassName={'cus-modal-wrapper'}
      >
        <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 20,
            width: '100%'
            }}>
            <div className='joblist'>
              {
                data.map((item, index) => (
                  <div onClick={()=> this.chooseJob(item, index)} style={{ position: 'relative', cursor: 'pointer', minWidth: 260, maxWidth: 260, height: 140, padding: 10, marginRight: 15, backgroundColor: jobIndex === index ? '#fff' : '#F7F7F9', borderRadius: 8}}>
                    <div style={{fontSize: 18, color: '#333', fontWeight: 'bold', paddingBottom: 8}}>{item.name}</div>
                    <div style={{color: '#666'}}>{item.description}</div>
                    {jobIndex === index && <img src={checkIcon} style={{position: 'absolute', right: 10, top: 10, width: 20, height: 20}}/>}
                  </div>
                ))
              }
            </div>
            <div onClick={()=> {
                      this.hideJobCategory()
                      this.submit()
                  }
                  } style={{ display: 'flex', justifyContent: 'center', marginTop: 20}}>
                  <div style={{display: 'flex', maxWidth: 360, height: 40, alignItems: 'center', borderRadius: 20, border: '1px solid #FFC001', justifyContent: 'center', backgroundColor: 'white', flex: 1}}>
                      <span style={{color: '#FFC001', paddingLeft: 20, paddingRight: 20, }}>确定选择该职业属性</span>
                  </div>
              </div>
          </div>
      </Modal>
    );
  };

  chooseJob = (item, index)=> {
    this.setState({
      jobIndex: index,
      jobCategory: item
    })
    setJobCategory(item)
  }

  submit = async()=> {
    let {jobCategory} = this.state;
    try {
      if (this.props.user && this.props.user.channelId) {
        const result = await callPost('/user/channel/change', {channelId: jobCategory.id});
        const user = await callGet('/users/info')
        this.props.toUpdateUser(user)
      }
      this.props.toUpdateJobCategory(jobCategory)
    } catch (e) {
      console.log('e', e)
    }
    
  }
}

const mapStateToProps = state => {
  console.log(' mapStateToProps', state)
  const {city, user, jobCategory} = state;
  return {
    city, user, jobCategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toUpdateCity: city => dispatch(updateCity(city)),
    toUpdateUser: user => dispatch(updateUser(user)),
    toUpdateJobCategory: jobCategory => dispatch(updateJobCategory(jobCategory))
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (UserLayout);
