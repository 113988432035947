import React, { Component } from 'react';
import {
    Card,
    Tabs,
    Button,
    Row,
    Col,
    Pagination,
    Divider,
    Icon,
    Tag,
    Menu,
    Input,
    Dropdown,
    List, Avatar,
    Table,
    message
  } from "antd";
  import './index.scss';

  const { TabPane } = Tabs;

  const data = [
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
  ];
  


export  default class UserComplaintPage extends Component{
    render(){
        return(
          <div className="user-complaint-page-background">
          <div className="user-complaint-page-container">
            <Card bordered={false} style={{  minHeight: '500px'}}>
                
            <Row gutter={16}  style={{paddingTop:'20px'}}>
              <Col span={4}> </Col>
              <Col span={12} ><Input placeholder=" " /></Col>
              <Col span={4}> <Button type="primary">提问</Button> </Col>
              <Col span={4}> </Col>
            </Row>
              
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                          title={<a href="#">{item.title}</a>}
                          description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                        />
                      </List.Item>
                    )}
                    style={{width:"70%" , margin:'auto',paddingTop:'50px'}}
                  />
                   <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Pagination defaultCurrent={1} defaultPageSize={9} total={15} />{" "}
            </div>
             
                </Card>
            </div>
            </div>
        )
    }
}