import React from "react";
import "./index.scss";
import { Card, Tabs, Button, Row, Col ,Icon, message} from "antd";
import JobNavBar from "components/job-navbar";
import VideoCourse from 'components/videoCourse'
import OnSiteCourse from 'components/OnsiteCourse'
import {getUser, getToken} from 'utils/authority'
import {callGet} from 'service/api'

const { TabPane } = Tabs;

export default class UserJobCareerCoursePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveStreamCourses: [],
      officialVideoCourses: [],
      thirdPartyVideoCourses: [],
      banners: []
    }
  }

  componentDidMount() {
    console.log(' env ', process.env);
    callGet('/public/web/recommendOnlineCourses', {moduleType: 2}).then((res)=>{
          console.log('res',res)
        // let courseList = res.data.data.map()
        const {liveStreamCourses, officialVideoCourses, thirdPartyVideoCourses} = res;
        this.setState({
          officialVideoCourses : (officialVideoCourses || []).concat((thirdPartyVideoCourses) || []),
        })
      console.log('res',res)
    }).catch( (err)=>{
      message.error(JSON.stringify(err.errorMessage))
      console.log('err',err)
    });

    callGet('/public/web/recommendOnSiteCourses', {moduleType: 2}).then((res)=>{
      console.log('res',res)
        // let courseList = res.data.data.map()
        this.setState({
          offlineCourses: res.officialVideoCourses || [],
          thirdPartyOfflineCourses: res.thirdPartyVideoCourses || []
        })
      console.log('res',res)
    }).catch( (err)=>{
      message.error(JSON.stringify(err.errorMessage))
      console.log('err',err)
    });
  }

  operations = (index) => {
    return  <p onClick={()=> this.more(index)}>
      <Button type="primary" size="small">
       更多
      </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </p>
  }

  more = (index)=> {
    console.log('more', index)
    let path
    if (index === 1) {
        path = {
          pathname: '/user/learning/courses',
          state: {
            moduleType: 2
          }}
    } else {
      path = {
        pathname: '/user/learning/courses',
        state: {
          official: index === 2,
          offline: true,
          moduleType: 2
        }
      }
    }
    this.props.history.push(path)
  }

  onLiveCourseClick = (item)=> {
    console.log(' course click', getToken())
    // sessionStorage.setItem('token', res.data.data.token);
    // sessionStorage.getItem('token')
    if (!getToken()) {
      this.props.history.push('/user/login')
      return;
    }
    window.open(item.liveStreamUrl)
  }

  onCourseClick = (item, offline=false)=> {
    console.log(' course click', getToken())
    // sessionStorage.setItem('token', res.data.data.token);
    // sessionStorage.getItem('token')
    if (!getToken()) {
      this.props.history.push('/user/login')
      return;
    }
    let path = {
      pathname: `/user/learning/courseDetail/${item.id}`,
      state: {
        id: item.id,
        type: item.type
      }}
    // router.push(path);
    this.props.history.push(path)
  }

  render() {
    const {offlineCourses=[], officialVideoCourses=[], thirdPartyVideoCourses=[], thirdPartyOfflineCourses=[]} = this.state;
    return (
      <div>
        {/* <UserLearningNavBar tabkey="1"/> */}
        <JobNavBar tabkey='5' />
        <div className="user-learning-page-background">
          <div className="user-learning-page-container">
              <Tabs defaultActiveKey="1" tabBarExtraContent={this.operations(1)}>
                <TabPane tab="线上视频课" key="1">
                <Row>
                  {officialVideoCourses.map(item => <VideoCourse key={'official'+item.id} {...item} onClick={()=> this.onCourseClick(item)}/>)}
                </Row>
                </TabPane>
              </Tabs>

              <Tabs defaultActiveKey="1" tabBarExtraContent={this.operations(2)} style={{marginTop:'20px'}}>
                <TabPane tab="线下面授课" key="1" >
                  <Row>
                    {offlineCourses.map(item => <OnSiteCourse key={'thirdparty'+item.id} {...item} onClick={()=> this.onCourseClick(item, true)}/>)}
                  </Row>
                </TabPane>
              </Tabs>

              <Tabs defaultActiveKey="1" tabBarExtraContent={this.operations(2)} style={{marginTop:'20px'}}>
                <TabPane tab="线下加盟面授课" key="1" >
                  <Row>
                    {thirdPartyOfflineCourses.map(item => <OnSiteCourse key={'thirdparty'+item.id} {...item} onClick={()=> this.onCourseClick(item, true)}/>)}
                  </Row>
                </TabPane>
              </Tabs>  
          </div>
        </div>
      </div>
    );
  }
}