import React, { Component, PureComponent } from 'react';
import { Col, Icon } from 'antd';
import './index.scss';
import {changeMoney} from 'utils/utils'

export default class QuizFile extends PureComponent{
    render() {
      const {id, index, organizationName, title, originalPrice, vipPrice, downloadCount, onClick=()=>{}, fileType } = this.props;
      let extensionImg;
      switch (fileType) {
        case 1:
          extensionImg = 'https://img.syyc.top/h5/doc.png'
          break;
        case 2:
          extensionImg = 'https://img.syyc.top/h5/pdf.png'
          break;
        case 3:
          extensionImg = 'https://img.syyc.top/h5/ppt.png'
          break;
        default:
          extensionImg = 'https://img.syyc.top/h5/jpg.png'
      }
        return (
          <div onClick={onClick} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '12px 6px', borderTop: index !== 0 ? '1px solid #f5f5f5' : '1px solid white'}} key={`job-item-${id}`}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <img src={extensionImg} alt="" style={{width:'48px',height:'48px'}}/>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: '10px'}}>
            <span><b>{title}&nbsp;&nbsp;&nbsp;&nbsp;</b> <br/></span>
            <div style={{display: 'flex', flexDirection: 'row'}}>
            <span style={{color: '#F07F1D'}}><b>会员价{changeMoney(vipPrice)}</b></span>
            <span style={{color: '#888', marginLeft: 20}}>原价{changeMoney(originalPrice)}</span>
            
            </div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between'}}>
            <span style={{fontSize:'16px'}}>{organizationName}
            {/* <Icon style={{color:'#FFA70F',fontSize:'20px'}} type="safety-certificate" /> */}
            </span>
            <span>{`${downloadCount}人下载`}</span>
          </div>
        </div>

          // <Col onClick={onClick} xs={12} md={6} xl={4}  key={`course-item-${id}`}>
          //   <div className='file-cell' style={{position: 'relative'}}>
          //     <img src={courseImageUrl} alt="" className='file-cover'/>
          //     <div style={{ display: 'flex', flexDirection: 'column'}}>
          //       <span style={{color:'#212121', fontWeight: 'bold', letterSpacing: '2px'}}>{title}</span>
          //       <span>{organizationName}</span>
          //       <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          //         <p style={{color:'#FFA70F',fontWeight: 'bold'}}>{changeMoney(originalPrice)}</p>
          //         <p>{`${downloadCount}人下载`}</p>
          //       </div>
          //     </div>
          //   </div>
          // </Col>
        );
    }
}


// 'screen-xs': {
//   maxWidth: 575,
// },
// 'screen-sm': {
//   minWidth: 576,
//   maxWidth: 767,
// },
// 'screen-md': {
//   minWidth: 768,
//   maxWidth: 991,
// },
// 'screen-lg': {
//   minWidth: 992,
//   maxWidth: 1199,
// },
// 'screen-xl': {
//   minWidth: 1200,
//   maxWidth: 1599,
// },
// 'screen-xxl': {
//   minWidth: 1600,
// },