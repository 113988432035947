import React from 'react';
import {
  AutoComplete,
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Checkbox,
  Slider,
  TimePicker,
  DatePicker,
  Upload,
  Cascader,
  Select,
  TreeSelect,
} from 'antd';
import { getOption } from './transformItem';
import AdRangePicker from '../AdminFormItem/RangePicker';
import AdDatePicker from '../AdminFormItem/DatePicker';
import AdInput from '../AdminFormItem/Input';
import FileBase64 from '../FileBase64';

const strComponent = {
  AutoComplete,
  Input: AdInput,
  Search: Input.Search,
  InputGroup: Input.Group,
  TextArea: Input.TextArea,
  InputNumber,
  Button,
  Switch,
  Radio,
  RadioGroup: Radio.Group,
  Checkbox,
  CheckboxGroup: Checkbox.Group,
  Slider,
  TimePicker,
  DatePicker: AdDatePicker,
  MonthPicker: DatePicker.MonthPicker,
  RangePicker: AdRangePicker,
  Upload,
  Cascader,
  Select,
  Option: Select.Option,
  OptGroup: Select.OptGroup,
  TreeSelect,
  FileBase64,
};

const getComponent = (compData, key = '0') => {
  const type = typeof compData;
  if (type === 'string') {
    return <span key={key}>{compData}</span>;
  }
  if (Array.isArray(compData)) {
    return compData.map((item, index) => getComponent(item, index));
  }
  if (type === 'object') {
    if (React.isValidElement(compData)) {
      // Element中需要手动加key
      return compData;
    }
    if (compData.name) {
      const { name, ...props } = compData;
      const Component = strComponent[name];
      const compChild = props.children;
      if (Array.isArray(compChild) && compChild.length) {
        const comChildren = getOption(compChild, props.childrenStr);
        const childrenItem = comChildren.map((item, index) =>
          getComponent(item, `${key}-${index}`)
        );
        return (
          <Component {...props} key={`${key}-0`}>
            {childrenItem}
          </Component>
        );
      }
      return <Component {...props} key={`${key}-0`} />;
    }
  }
  throw compData;
};

export { strComponent };
export default getComponent;
