import moment from 'moment';
import React from 'react';
import nzh from 'nzh/cn';
import { parse, stringify } from 'qs';
import copyf from 'copy-to-clipboard';
import { message } from 'antd';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getTimeString(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [
      moment(now).format('YYYY-MM-DD HH:mm:ss'),
      moment(now.getTime() + (oneDay - 1000)).format('YYYY-MM-DD HH:mm:ss'),
    ];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [
      moment(beginTime).format('YYYY-MM-DD HH:mm:ss'),
      moment(beginTime + (7 * oneDay - 1000)).format('YYYY-MM-DD HH:mm:ss'),
    ];
  }

  if (type === 'seven') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    const beginTime = now.getTime();

    return [
      moment(beginTime - 7 * oneDay).format('YYYY-MM-DD HH:mm:ss'),
      moment(beginTime + (oneDay - 1000)).format('YYYY-MM-DD HH:mm:ss'),
    ];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
    ];
  }

  const year = now.getFullYear();
  return [
    moment(`${year}-01-01 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
    moment(`${year}-12-31 23:59:59`).format('YYYY-MM-DD HH:mm:ss'),
  ];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  return nzh.toMoney(n);
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v || Number.isNaN(v)) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          styles={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            lineHeight: 20,
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

export function isAntdPro() {
  return window.location.hostname === 'preview.pro.ant.design';
}

export function timeago(date) {
  const dateTimeStamp = new Date(date).getTime(); // dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
  const minute = 1000 * 60; // 把分，时，天，周，半个月，一个月用毫秒表示
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const now = new Date().getTime(); // 获取当前时间毫秒
  const diffValue = now - dateTimeStamp; // 时间差
  let result = '';
  if (diffValue < 0) {
    return result;
  }
  const minC = diffValue / minute; // 计算时间差的分，时，天，周，月
  const hourC = diffValue / hour;
  const dayC = diffValue / day;
  const weekC = diffValue / week;
  const monthC = diffValue / month;
  if (monthC >= 1 && monthC <= 3) {
    result = ` ${parseInt(monthC, 0)}月前`;
  } else if (weekC >= 1 && weekC <= 3) {
    result = ` ${parseInt(weekC, 0)}周前`;
  } else if (dayC >= 1 && dayC <= 6) {
    result = ` ${parseInt(dayC, 0)}天前`;
  } else if (hourC >= 1 && hourC <= 23) {
    result = ` ${parseInt(hourC, 0)}小时前`;
  } else if (minC >= 1 && minC <= 59) {
    result = ` ${parseInt(minC, 0)}分钟前`;
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = '刚刚';
  } else {
    const datetime = new Date();
    datetime.setTime(dateTimeStamp);
    const Nyear = datetime.getFullYear();
    const Nmonth =
      datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1;
    const Ndate = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
    // const Nhour = datetime.getHours() < 10 ? `0${  datetime.getHours()}` : datetime.getHours();
    // const Nminute = datetime.getMinutes() < 10 ? `0${  datetime.getMinutes()}` : datetime.getMinutes();
    // const Nsecond = datetime.getSeconds() < 10 ? `0${  datetime.getSeconds()}` : datetime.getSeconds();
    result = `${Nyear}-${Nmonth}-${Ndate}`;
  }
  return result;
}

export function copy(str) {
  copyf(str);
  message.success('复制成功');
}

export function getDateAfter(str) {
  const nowDate = new Date(str); // 获取当前时间)
  const date = moment(new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)).format('YYYY-MM-DD');
  return date;
}

export function getLateFee(btrtime,creattime,fine) {    // sDate1和sDate2是2006-12-18格式  
  const s1 = new Date(btrtime.replace(/-/g, "/"));
  const s2 = new Date(creattime.replace(/-/g, "/"));
  const days = s2.getTime() - s1.getTime();
  const time = Math.round(days / (1000 * 60 * 60 * 24),0);
  const latefee = fine*(time-15) * 0.03
  if(time < 16) {
    return '0'
  }
  if( latefee > fine) {
    return (fine/100).toFixed(2)
  }
  return (latefee /100).toFixed(2)
};

export function changeMoney(num) {
  const regexp = /(?:\.0*|(\.\d+?)0+)$/
  if (num > 1000000) {
      num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
      return '¥' + num + '万'
  } else {
      num = (num / 100).toFixed(2)
      num = num.replace(regexp, '$1')
      return '¥' + num
  }
}

function formatSalayAmount(num) {
  // console.log('formatSalayAmount', num, num == undefined, num == '')
  // const regexp = /(?:\.0*|(\.\d+?)0+)$/
  // if (num > 1000000) {
  //     num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
  //     return '¥' + num + '万'
  // } else {
  //     num = (num / 100).toFixed(2)
  //     num = num.replace(regexp, '$1')
  //     return '¥' + num
  // }
  if (num == undefined || num == null || num == '') {
      return ''
  }
  return (Number(num) / 100 / 1000).toFixed(2) * 100 / 100 + 'k'
}

export function getSalaryRange(item) {
  // console.log('getSalaryRange', item)
  if (!item) {
      return;
  }
  let {startSalary=undefined, endSalary=undefined} = item
  if (startSalary === undefined || endSalary === undefined) {
      return ''
  }
  if (startSalary <= 0) {
      return formatSalayAmount(endSalary) + '以内'
  } else if (endSalary <= 0) {
      return formatSalayAmount(startSalary) + '以上'
  } else {
      return formatSalayAmount(startSalary) + '-' + formatSalayAmount(endSalary)
  }
}