import React from "react";
import styles from "./index.scss";
import { Card, DatePicker, Button, Row, Col , Radio, Select, Table, message} from "antd";
import JobItem from 'components/job';
import { Link } from "react-router-dom";
// import router from 'umi/router';
import * as utils from 'utils/utils';
import dateutils from 'utils/dateutils'
import {getUser, getToken} from 'utils/authority'
import constants from 'utils/constants'
import {callGet, callPost, syncUserInfo} from 'service/api'
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'
import WalletBackgroundImage from "images/wallet_page_bg.png";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

const { MonthPicker } = DatePicker;

const columns = [
  {
    title: "账单",
    dataIndex: "title",
    key: "title",
    // render: text => <a href="javascript:;">{text}</a>
  },
  {
    title: "交易单号",
    dataIndex: "orderNo",
    key: "orderNo"
  },
  {
    title: "支付方式",
    dataIndex: "paymentType",
    key: "paymentType",
    render: paymentType => (
      <span>
        {paymentType == 1 ? '支付宝' : paymentType == 2 ? '微信' : paymentType == 3 ? '钱包': 'Apple Pay'}
      </span>
    )
  },
  {
    title: "支付时间",
    key: "orderedTime",
    dataIndex: "orderedTime",
  },
  {
    title: "交易金额",
    key: "amountString",
    dataIndex: "amountString",
  }
];

const MONTH_FORMAT = 'yyyy/MM'

// @connect(({ job, login, loading }) => ({
//     job,
//     login,
// }))
export default class WalletPage extends React.Component {
  constructor(props) {
    super(props);
    console.log(' JobDetailPage ', props);
    // const {id} = props.match.params;
    let now = new Date()
    console.log(' month ',dateutils.formatDate(now, MONTH_FORMAT) )
    this.state = {
      levels: [],
      // data: {id},
      data: {},
      tableData: {},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      positionNum: 0,
      recommends: [],
      isApplied: false,
      isShowBuyTip: false,
      isShowPayModal: false,
      type: 1,
      payAmount: 0,
      orderNumber: '',
      levelIndex: 0,
      count: 0,
      selectDate: dateutils.formatDate(now, MONTH_FORMAT),
      pagenation:{
        current: 1,
        pageSize: 10,
        total: 0
      }
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {

    // const script = document.createElement("script")
    // script.src = "https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js"
    // // script.async = true

    // const flexibleScript = document.createElement("script")
    // flexibleScript.src = "http://player.alicdn.com/resource/player/lib/flexible.min.js";
    
    // const zeptoScript = document.createElement('script')
    // zeptoScript.src = 'https://cdn.jsdelivr.net/npm/zepto/zepto.min.js';
    // document.body.appendChild(flexibleScript)
    // document.body.appendChild(script)
    // document.body.appendChild(zeptoScript)

    this._toLoadDetail()
    this._toLoadList()
  }

  // componentWillReceiveProps(newProps) {
  //     const {id} = newProps.match.params;
  //     const {data} = this.state;
  //     console.log(' componentWillReceiveProps ', newProps, this.state)
  //     if (id !== this.state.data.id) {
  //         data.id = id;
  //         this.setState({
  //           data
  //         }, this._toLoadDetail)
  //     }
  // }

  _toLoadDetail = async () => {
    if (!getToken()) {
      return;
    }
    let {data} = this.state;
    // console.log('load detail', data.id)
    try {
        let result = await callGet('/users/coin')
        if (result) {
            this.setState({
                data: result
            })
        }
    } catch (e) {
        console.log('e', e.errorMessage)
        message.error(e.errorMessage)
    }
  }

  _toLoadList = async ()=> {
    if (!getToken()) {
      return;
    }
    let {selectDate} = this.state;
    // if (!selectDate) {
    //   selectDate = new Date()
    // }
    let date = selectDate
    
    try {
        const totalInfo = await callGet('/users/coin/transactions/sum', {date})
        const listInfo = await callGet('/users/coin/transactions', {date})
        this.setState({
            outcome: totalInfo.expense,
            income: totalInfo.income,
            tableData: listInfo,
            pagenation: {

            }
        })
    } catch (e) {
        console.log('e', e)
        message.error(e.errorMessage)
    }
  }

  _toLoadPageData = async ()=> {
    
  }

  handleDateChange = (date, dateString) => {
    console.log(`selected ${date}`, dateString);
    this.setState({
      selectDate: dateString
    }, this._toLoadList)
  }

  render() {
    // const { course, loading} = this.props;
    const {sources, data, income, outcome, tableData } = this.state;
    const {isMobile} = this.props;
    console.log('render', sources)

    return (
      <div style={{paddingTop: 12}}>
           <div
            className="header"
            style={{
              backgroundImage: `url(${WalletBackgroundImage})`,
              height: "220px",
              backgroundSize: "cover",
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: isMobile ? '0 12px' : '0 64px'
            }}
            >
              <div style={{textAlign: 'center', fontSize: 20, color: '#fff'}}>
                <div>账户余额（元）</div>
                <div style={{fontSize: 40}}>{parseFloat((data ? data.balance : 0)/100)}</div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                <Button type="primary" size="small">充值</Button>
                <Button size="small">提现</Button>
                <Button type="primary" size="small">我的佣金</Button>
              </div>
            </div>
          <div>
            <div className='billmonth'>
                <h3>
                我的账单
                </h3>
                <span>总支出{parseFloat(outcome/ 100)}</span>
                <span>总收入{parseFloat(income/ 100)}</span>
                <div style={{flex: 1}}></div>
                <MonthPicker defaultValue={moment(this.state.selectDate, MONTH_FORMAT)} onChange={this.handleDateChange} picker="month"/>
                {/* <Select defaultValue="lucy" style={{ width: 120 }} onChange={this.handleChange}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select> */}
            </div>
            <Table
                bordered
                columns={columns}
                style={{ marginTop: "10px" }}
                dataSource={tableData.list || []}
              />
          </div>
      </div>
    );
  }
}