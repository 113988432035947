import React, { Component } from "react";
import {
  Tabs,
  Button,
  Card,
  DatePicker,
  Statistic,
  Row,
  Col,
  Icon,
  Table,
  Avatar,
  Tag,
  Input,
  Menu,
  Dropdown,
  Upload,
  message,
  Comment,
  Tooltip,
  Form,
  List,
  Cascader
} from "antd";

import "./index.scss";
import { enquireScreen } from 'enquire-js';
import { getUser } from 'utils/authority';
import {callGet, callPost, getAddress as getAddressRemote} from 'service/api'

const { TabPane } = Tabs;

class Joinusage extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    const panes = [
      { title: '简历1', content: 'Content of Tab Pane 1', key: '1' },
      { title: '简历2', content: 'Content of Tab Pane 2', key: '2' },
    ];
    this.state = {
      activeKey: panes[0].key,
      panes,
      isMobile: false,
      ship: {
        name: '',
        phone: '',
        city: '',
        address: ''
      },
      address: {
      },
      location: undefined,
      districts: []
    };
  }

  componentDidMount() {
    this.enquireHandler = enquireScreen(mobile => {
      const { isMobile } = this.state;
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });
  }

  getDistrictsInfo = async ()=> {
    try {
      const result = await callGet('/public/districts');
      this.setState({
        districts: result || []
      })
    } catch (e) {
      console.log('e', e)
    }
  }

  onChange = activeKey => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  add = () => {
    const { panes } = this.state;
    const activeKey = `newTab${this.newTabIndex++}`;
    panes.push({ title: 'New Tab', content: 'New Tab Pane', key: activeKey });
    this.setState({ panes, activeKey });
  };

  remove = targetKey => {
    let { activeKey } = this.state;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter(pane => pane.key !== targetKey);
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].key;
      } else {
        activeKey = panes[0].key;
      }
    }
    this.setState({ panes, activeKey });
  };

  submitProfile = async ()=> {
    if (!this.nicknameRef || !this.cityRef || !this.phoneRef) {
      return;
    }
    if (!this.nicknameRef.state.value) {
      message.error('请完善姓名')
      return;
    }
    if (!this.cityRef.state.value) {
      message.error('请填写代理城市')
      return;
    }
    if (!this.phoneRef.state.value) {
      message.error('请填写手机号')
      return;
    }
    
    try {
      let params = {
        name: this.nicknameRef.state.value,
        cityName: this.cityRef.state.value,
        email: this.emailRef.state.value,
        phone: this.phoneRef.state.value
      }
      let result = await callPost('/user/agentApplications', params)
      message.success('提交成功师业有成稍后与您联系')
      this.nicknameRef.state.value = ''
      this.cityRef.state.value = ''
      this.emailRef.state.value = ''
      this.phoneRef.state.value = ''
      this.forceUpdate()
    } catch (e) {
      console.log('e', e)
      message.error(e.errorMessage)
    }
  }

  courseApply = async ()=> {
    if (!this.nicknameRef2 || !this.courseRef || !this.companyRef || !this.phoneRef2) {
      return;
    }
    if (!this.nicknameRef2.state.value) {
      message.error('请完善姓名')
      return;
    }
    if (!this.courseRef.state.value) {
      message.error('请填写投放课程名称')
      return;
    }
    if (!this.companyRef.state.value) {
      message.error('请填写公司名称')
      return;
    }
    if (!this.phoneRef2.state.value) {
      message.error('请填写手机号')
      return;
    }
    
    try {
      let params = {
        name: this.nicknameRef2.state.value,
        courseName: this.courseRef.state.value,
        orgName: this.companyRef.state.value,
        email: this.emailRef2.state.value,
        phone: this.phoneRef2.state.value
      }
      let result = await callPost('/courseSubmitApplications', params)
      message.success('提交成功师业有成稍后与您联系')
      this.nicknameRef2.state.value = ''
      this.courseRef.state.value = ''
      this.companyRef.state.value = ''
      this.emailRef2.state.value = ''
      this.phoneRef2.state.value = ''
      this.forceUpdate()
    } catch (e) {
      console.log('e', e)
      message.error(e.errorMessage)
    }
  }

  onShipnameChange = (e)=> {
    console.log(' onShipnameChange ', e, e.nativeEvent, e.currentTarget)
  }

  onCityChange =  (val,citys) => {
    console.log('on city change ', val, citys)
    if (citys.length === 3) {
      let location = {
        pro: citys[0].name,
        proCode: citys[0].code,
        city: citys[1].name,
        cityCode: citys[1].code,
        district: citys[2].name,
        districtCode: citys[2].code
      }
      this.setState({
        location
      })
    }
  }

  onCityCascaderVisible = (visible) => {
    console.log('e', visible)
    const {districts} = this.state;
    if (visible && districts.length === 0) {
      this.getDistrictsInfo()
    }
  }

  goVipPay = ()=> {
    this.props.history.push('/user/vippay')
  }

  render() {
    const { form, submitting } = this.props;
    const { getFieldDecorator } = form;
    const user = getUser()
    const { isMobile, address, ship, location, districts } = this.state;
    let districtInfo = ''
    if (location) {
      if (location.pro == '北京市' || location.pro == '天津市' || location.pro == '重庆市' || location.pro == '上海市') {
        districtInfo = location.city + location.district
      } else {
        districtInfo = location.pro + location.city + location.district
      }
    }
    return (
      <div className="company-my-page-background">
        <div className="company-my-page-container">
          <Card bordered={false}>
            <Tabs >
              <TabPane tab="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;成为代理商" key="1">
                <Card bordered={true} style={{ minHeight: "500px" }}>
                  <br />
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      姓名
                    </Col>
                    <Col span={20}><Input ref={(ref)=> this.nicknameRef = ref} size="large" placeholder='请填写姓名' /></Col>
                  </Row>
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      代理城市
                    </Col>
                    <Col span={20}>
                      <Input ref={(ref)=> this.cityRef = ref} size="large" placeholder='可填多个城市","号分割' />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      邮箱（选填）
                    </Col>
                    <Col span={20}>
                      <Input ref={(ref)=> this.emailRef = ref} size="large" placeholder='请填写邮箱' />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      手机号
                    </Col>
                    <Col span={20}>
                      <Input ref={(ref)=> this.phoneRef = ref} size="large" placeholder='请填写手机号' />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <div style={{width: '100%', textAlign: 'center'}}>
                  <Button size="large" onClick={this.submitProfile} type='primary'>立即申请</Button>
                  </div>
                </Card>
              </TabPane>
              <TabPane tab="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;成为供应商" key="2">
                <Card bordered={true} style={{ minHeight: "500px" }}>
                  <br />
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      姓名
                    </Col>
                    <Col span={20}><Input ref={(ref)=> this.nicknameRef2 = ref} size="large" placeholder='请填写姓名' /></Col>
                  </Row>
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      投放课程名称
                    </Col>
                    <Col span={20}>
                      <Input ref={(ref)=> this.courseRef = ref} size="large" placeholder='' />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      公司名称
                    </Col>
                    <Col span={20}>
                      <Input ref={(ref)=> this.companyRef = ref} size="large" placeholder='' />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      邮箱（选填）
                    </Col>
                    <Col span={20}>
                      <Input ref={(ref)=> this.emailRef2 = ref} size="large" placeholder='请填写邮箱' />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={16} className="form-item">
                    <Col span={4} style={{ textAlign: "right" }}>
                      手机号
                    </Col>
                    <Col span={20}>
                      <Input ref={(ref)=> this.phoneRef2 = ref} size="large" placeholder='请填写手机号' />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <div style={{width: '100%', textAlign: 'center'}}>
                  <Button size="large" onClick={this.courseApply} type='primary'>立即申请</Button>
                  </div>
                </Card>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
}

export default Form.create({name: 'joinus'})(Joinusage)