import React from "react";
import styles from "./index.scss";
import { Card, Tabs, Button, Row, Col , Radio, Modal, message} from "antd";
import JobItem from 'components/job';
import { Link } from "react-router-dom";
// import router from 'umi/router';
import * as utils from 'utils/utils';
import dateutils from 'utils/dateutils'
import {getUser, getToken} from 'utils/authority'
import constants from 'utils/constants'
import {callGet, callPost, syncUserInfo} from 'service/api'
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'

// @connect(({ job, login, loading }) => ({
//     job,
//     login,
// }))
export default class VipPayPage extends React.Component {
  constructor(props) {
    super(props);
    console.log(' JobDetailPage ', props);
    const {id} = props.match.params;
    this.state = {
      levels: [],
      data: {id},
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      positionNum: 0,
      recommends: [],
      isApplied: false,
      isShowBuyTip: false,
      isShowPayModal: false,
      type: 1,
      payAmount: 0,
      orderNumber: '',
      levelIndex: 0,
      count: 0
    }
    this.player = null; // 创建播放器实例变量
  }

  componentDidMount() {

    // const script = document.createElement("script")
    // script.src = "https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js"
    // // script.async = true

    // const flexibleScript = document.createElement("script")
    // flexibleScript.src = "http://player.alicdn.com/resource/player/lib/flexible.min.js";
    
    // const zeptoScript = document.createElement('script')
    // zeptoScript.src = 'https://cdn.jsdelivr.net/npm/zepto/zepto.min.js';
    // document.body.appendChild(flexibleScript)
    // document.body.appendChild(script)
    // document.body.appendChild(zeptoScript)

    this._toLoadDetail()
  }

  componentWillReceiveProps(newProps) {
      const {id} = newProps.match.params;
      const {data} = this.state;
      console.log(' componentWillReceiveProps ', newProps, this.state)
      if (id !== this.state.data.id) {
          data.id = id;
          this.setState({
            data
          }, this._toLoadDetail)
      }
  }

  _toLoadDetail = async () => {
    let {data} = this.state;
    console.log('load detail', data.id)
    try {
        let result = await callGet('/userVipLevel')
        if (result) {
            this.setState({
                levels: result || []
            })
        }
    } catch (e) {
        console.log('e', e.errorMessage)
    }
  }

  _toApply = async ()=> {
    let {levels, levelIndex } = this.state;
    if (levels.length == 0) {
        return;
    }
    try {
        let params = {}
        params.num = 1;
        params.itemId = levels[levelIndex].vipLevelId;
        params.itemType = 6;
        let orderResult = await callPost('/purchase', params)
        const alipayInfo = await callPost('/alipay/payOrder', {tradeType: 2, balance: orderResult.paidAmount, orderNumber: orderResult.orderNumber})
        console.log(' alipay info', alipayInfo)
        this.setState({
            payAmount: orderResult.paidAmount,
            orderNumber: orderResult.orderNumber,
            isShowBuyTip: false,
            isShowPayModal: true,
            payQrcode: alipayInfo.payParam
        }, ()=> this.refs.paymodal.pollPayResult(orderResult.orderNumber))
    } catch (err) {
        message.error(err.errorMessage)
    }
  }
  _onLevelChoose = (e)=> {
      console.log(' level ', e)
      this.setState({
          levelIndex: e.target.value
      })
  }

  renderLevelsRadio(vip) {
      const {levels} = this.state;
        const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
        };

    const dateStyle = {
        color: '#FFC001'
    }
    return (
      <Radio.Group onChange={this._onLevelChoose} value={this.state.levelIndex} style={{marginBottom: 20}}>
          {
              levels && levels.map((item, index) => (
                <Radio style={radioStyle} value={index}>
                   {vip === 1 ? '续费' : '开通' + item.name}， <span style={dateStyle}>{utils.changeMoney(item.fee)}</span>
                </Radio>
              ))
          }
      </Radio.Group>
    );
  }

  render() {
    // const { course, loading} = this.props;
    const {sources, data, positionNum, recommends } = this.state;
    const user = getUser()
    console.log('render', sources)

    return (
        
      <div style={{paddingTop: 12}}>
        {/* <UserLearningNavBar /> */}
        {/* <UserBanner banners={banners}/> */}
           <BackButton/>
        
          <div className='user-learning-page-container'>
            {/* <div  className="prism-player" id='Ali_Player' /> */}
            <div className='main'>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h3>
                    88会员
                    </h3>
                </div>
                <Card title={user.isVip === 1 ? `选择续费时间` : '选择开通时间'} extra={ user.isVip === 1 ? `会员到期日${dateutils.formatDate(user.vipExpireAt, 'yyyy-MM-dd')}` : ''}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {
                            this.renderLevelsRadio(user.isVip)
                        }
                        {
                            user &&
                                <Button onClick={this._toApply} ghost type="primary" shape="round" size={'large'} >{user.isVip === 1 ? '立即续费' : '开通会员'}</Button>
                        }
                        {/* {
                            data && data.buyFlag === 1 &&
                                <Button type="primary" shape="round" size={'large'} disabled>您已开通该职位平台优推服务</Button>
                        }
                        {
                            data && data.buyFlag !== 1 && data.quickHiringService === 1 &&
                                <Button onClick={this._showBuyTip} type="primary" shape="round" size={'large'}>开通该职位平台优推服务</Button>
                        } */}
                    </div>
                </Card>
            </div>
          </div>
          {this.renderPayModal(user.isVip)}
      </div>
    );
  }

  renderPayModal = (vip) => {
    const { isShowPayModal, payAmount, payQrcode, orderNumber } = this.state;
    return (
        <PayModal
        ref="paymodal"
         isShowPayModal={isShowPayModal}
         payAmount={payAmount}
         payQrcode={payQrcode}
         orderNumber={orderNumber}
         title={vip === 1 ? '续费会员服务' : '购买会员服务'}
         desc={vip === 1 ? '续费会员服务' : '购买会员服务'}
         onClose={() => {
            this.setState({
              isShowPayModal: false,
            });
          }}
          callback={async ()=> {
            await syncUserInfo();
            message.success(vip === 1 ? '续费成功' : '购买成功')
            this.props.history.goBack()
          }}
         />
    );
  };

}
