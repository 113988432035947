const initialized = {

}

const jobCategory = (state = initialized, action ) => {
    switch (action.type) {
        case 'UPDATE_JOB_CATEGORY':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }
}
export default jobCategory