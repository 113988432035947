import React, { PureComponent } from 'react';
import { Input, Icon } from 'antd';
import moment from 'moment';

class AdDatePicker extends PureComponent {
  state = {};

  componentDidMount() {
  }

  onChange = text => {
    const { onChange } = this.props;
    // const dateTime = [(`${date[0]} 00:00:00`),(`${date[1]} 23:59:59`)]
    if (onChange) {
      onChange(text);
    }
  };

  emitEmpty = () => {
    const { onChange } = this.props;
    onChange('');
  };

  render() {
    const { value, placeholder } = this.props;
    /**  beck mask for fix: after first char input lose focus */
    // const suffix = value ? (
    //   <Icon
    //     style={{ color: 'rgba(0,0,0,.25)', cursor: 'pointer' }}
    //     type="close-circle"
    //     onClick={this.emitEmpty}
    //   />
    // ) : null;
    const suffix = <Icon
      style={{ color: 'rgba(0,0,0,.25)', cursor: 'pointer' }}
      type="close-circle"
      onClick={this.emitEmpty}
    />
    return (
      <Input
        style={{ width: '100%' }}
        value={value}
        suffix={suffix}
        onChange={this.onChange}
        placeholder={placeholder}
      />
    );
  }
}
export default AdDatePicker;
