import utils from "./utils";
const globalStyles = {
    container: {
        backgroundColor: '#F7F8FC',
        flex: 1
    },
    horizontal_container: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    horizontal_start_container: {
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start'
    },
    horizontal_end_container: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    horizontal_space_around_container: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    
    vertical_container: {
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    vertical_end_container: {
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    center: {
        justifyContent: 'center',
    },
    center2: {
       alignItems: 'center',
    },
    center3: {
        alignContent: 'center'
    },
    bottom: {
        justifyContent: 'flex-end'
    },
    back: {
       width: 29,
       height: 18,
       margin: 10
    },
    back2: {
        width: 10,
        height: 18,
        margin: 10
    },
    back_white: {
        width: 10,
        height: 10 / 31 * 56,
        margin: 10,
    },
    title: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold'
    },
    marginLeft10: {
        marginLeft: 10
    },
    margin10: {
        margin: 10
    },
    margin20: {
        margin: 20
    },
    marginLeft5: {
        marginLeft: 5
    },
    marginLeft20: {
        marginLeft: 20
    },
    marginTop0: {
        marginTop: 0
    },
    marginTop10: {
        marginTop: 10
    },
    marginTop20: {
        marginTop: 20
    },
    marginRight10: {
        marginRight :10
    },
    marginRight5: {
        marginRight :5
    },
    marginRight20: {
        marginRight: 20
    },
    marginBottom10: {
        marginBottom: 10
    },
    marginBottom0: {
        marginBottom: 0
    },
    marginBottom20: {
        marginBottom: 20
    },
    paddingLeft10: {
        paddingLeft: 10
    },
    paddingLeft20: {
        paddingLeft: 20
    },
    paddingTop10: {
        paddingTop: 10
    },
    paddingTop20: {
        paddingTop: 20
    },
    paddingRight10: {
        paddingRight :10
    },
    padding5: {
        padding: 5
    },
    padding10: {
        padding: 10
    },
    padding20: {
        padding: 20
    },
    paddingRight20: {
        paddingRight: 20
    },
    paddingBottom10: {
        paddingBottom: 10
    },
    paddingBottom0: {
        paddingBottom: 0
    },
    paddingBottom20: {
        paddingBottom: 20
    },
    text_white: {
        color: '#fff'
    },
    line_yellow_round: {
        height: 5,
        width: 5 / 21 * 123
    },
    line: {
        height: 0.7,
        backgroundColor: '#f5f5f5',
        marginTop: 10,
        marginBottom: 10,
    },
    listSeparator: {
        height: 0.7,
        backgroundColor: '#f5f5f5',
    },
    sort_container_normal: {
        backgroundColor: '#F7F8FC',
        padding: 20,
        paddingBottom: 10,
        paddingTop: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 5,
        marginTop: 10
    },
    spilt: {
        width: 0.7,
        height: 25,
        backgroundColor: '#f5f5f5'
    },
    sort_container_select: {
        backgroundColor: '#FFC600',
        padding: 20,
        paddingBottom: 10,
        paddingTop: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 5,
        marginTop: 10
    },
    sort_txt_normal: {
        fontSize: 12,
        color: '#363636'
    },

    sort_txt_select: {
        fontSize: 12,
        color: '#fff'
    },
    subject_yellow_bg: {
        backgroundColor: '#FFC600'
    },
    menu_select: {
        color: '#363636',
        fontSize: 16,
        fontWeight: 'bold'
    },
    menu_normal: {
        color: '#363636',
        fontSize: 14,
    },

    action_bar_icon: {
        width: 10 / 31 * 56,
        height: 10 / 31 * 56,
    },

    commission: {
        color: '#E8B400',
        fontSize: 10,
        height: 20,
        borderColor: '#E8B400',
        borderWidth: 1,
        borderRadius: 10,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
    }
};


export default globalStyles;