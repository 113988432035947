import React from "react";
import "./index.scss";
import { Card, Tabs, Button, Row, Col ,Radio, Modal, message} from "antd";
// import VideoPlayer from '@/components/videoplayer';
import { Link } from "react-router-dom";
// import { connect } from './node_modules/dva';
// import router from './node_modules/umi/router';
import Player, {MessageContext, Layer} from 'griffith';
import * as utils from 'utils/utils';
import {getUser, getToken} from 'utils/authority'
import { callGet, callPost } from "service/api";
import BackButton from 'components/backButton'
import PayModal from 'components/payModal'
import PayMethodModal from 'components/payMethodModal'
import DistributionModal from 'components/distributionModal'
import CommissionButton from 'components/commissionButton'
import CardPayAvaliable from "components/cardPayAvaliable";
import CompanyAvatar from 'components/companyAvatar'
import dateutils from 'utils/dateutils'
import constants from "../../../../utils/constants";
// @connect(({ course,login, loading }) => ({
//   course,
//   login,
//   loading: loading.effects['course/getList']
// }))
export default class CourseDetailPage extends React.Component {
  constructor(props) {
    super(props);
    console.log(' CourseDetailPage ', props,);
    const {id, offline} = props.match.params;
    this.state = {
      data: {id},
      offline,
      userInfo: '',
      sources: undefined,
      currentIndex: 0,
      isShowPayModal: false,
      shareImage: '',
      id: 0,
      payMethodIndex: 1,
      videoPlayIndex: -1
    }
    // this.player = React.createRef(); // 创建播放器实例变量
  }

  componentDidMount() {

    // const script = document.createElement("script")
    // script.src = "https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js"
    // // script.async = true

    // const flexibleScript = document.createElement("script")
    // flexibleScript.src = "http://player.alicdn.com/resource/player/lib/flexible.min.js";
    
    // const zeptoScript = document.createElement('script')
    // zeptoScript.src = 'https://cdn.jsdelivr.net/npm/zepto/zepto.min.js';
    // document.body.appendChild(flexibleScript)
    // document.body.appendChild(script)
    // document.body.appendChild(zeptoScript)
    this._toLoadDetail()
  }

  _toLoadDetail = async () => {
    let {data} = this.state;
    // let {dispatch} = this.props;
    let {offline = false, isSerialSingle = false, type, liveCourse=false} = this.props.location.state || {};
    console.log('load detail', this.props.location.state)
    let result
    try {
        if (liveCourse) {
            result = await callGet('/public/liveStreamCourses/' + data.id)
            result.id = data.id
        } else if (offline) {
            result = await callGet('/public/onsiteCourses/' + data.id)
        } else {
            if (type === 1 && !isSerialSingle) { //系列课
                result = await callGet('/public/videoCourseSerials/' + data.id)
            } else {
                result = await callGet('/public/videoCourses/' + data.id)
                result.type = 2;
            }
        }
    } catch (e) {
        console.log('e', e)
        message.error(e.errorMessage)
    }
    
    if (result) {
        if (!liveCourse && result.chapters && result.chapters.length > 0) {
            result.chapters.forEach((item, index)=>{
                item.title = item.name;
                item.data = item.sections;
                item.index = index;
            })
        }
        result.tags = result.tag ? result.tag.split('-') : []
        this.setState({
            data: result
        })
        this._init()
    }
  }

  _init = () => {
    console.log(' _init ', this.state.data)
    let result = this.state.data;
    let {offline = false, liveCourse = false, type} = this.props.location.state || {};
    let userInfo = getUser();
    let video, playIndex = 0;
    if (result.type == 2 || offline) { // 单科
        if (offline || result.buyFlag == 1 || (userInfo.isVip === 1 && result.vipPrice === 0)) {
            video = result;
        }
    } else { // 系列课
        if (!liveCourse && result && result.chapters) {
            result.chapters.forEach((item, index)=>{
                if (item.sections) {
                    item.sections.forEach(sectionItem => {
                        let videoCourse = sectionItem.videoCourse;
                        videoCourse.playIndex = playIndex;
                        sectionItem.playIndex = playIndex;
                        playIndex ++;
                        if (video == undefined) {
                            if (result.buyFlag == 1 || videoCourse.buyFlag == 1 || (userInfo.isVip === 1 && videoCourse.vipPrice === 0)) {
                                video = videoCourse;
                            }
                        }
                    })
                }
            })
        }
    }
    if (video != undefined) {
        this._toPlay(video)
    }
  }

  _toPlay = async (video)=> {
    console.log(' _toPlay player ', this.player, video)
    // console.log(' to play video', video)
    let { isPlay, videoPlayIndex, sources, offline, data } = this.state;
    let {liveCourse = false} = this.props.location.state || {};
    if (video.playIndex == videoPlayIndex) {
        console.log(' already play ', videoPlayIndex);
        return;
    }
    if (liveCourse) {
        return;
    }
    try {
        const videoInfo = await callGet('/user/videoPlayInfos/' + video.videoId)
        if (!videoInfo.paid) {
            message.success('请购买后查看完整视频', 4);
        }
        let videoUrl = videoInfo.playInfo ? videoInfo.playInfo[0].playURL : '';
        // if (sources) {
        //     videoUrl = 'https://zhstatic.zhihu.com/cfe/griffith/zhihu2018_hd.mp4'
        // }
        this.setState({
            sources: {
            // hd: {
            //   play_url: 'https://zhstatic.zhihu.com/cfe/griffith/zhihu2018_hd.mp4',
            // },
            sd: {
                play_url: videoUrl,
            // play_url: 'http://v.syyc.top/sv/4800535c-16fac073970/4800535c-16fac073970.mp4?auth_key=1579232786-81708efedea948a680175832b9c3f31e-0-9c86b689de8fd5ccffc69cb533359d6d'
            },
            },
            videoPlayIndex: video.playIndex
        })
        console.log('player ', this.player)
    } catch (e) {
        console.log('e', e)
        message.error(e.errorMessage)
    }
  }

  renderPayMethodModal = () => {
    const { isShowPayMethod, data } = this.state;
    let {offline = false, liveCourse = false, type} = this.props.location.state || {};
    let itemType = offline ? constants.products.COURSE_OFFLINE : liveCourse ? constants.products.COURSE_LIVE : type == 1 ? constants.products.COURSE_SERIAL : constants.products.COURSE

    return (
    <PayMethodModal
        ref="paymethodmodal"
        type={itemType}
        payCardFlag={data.payCardFlag}
        isShowPayModal={isShowPayMethod}
        title={'选择支付方式'}
        onClose={() => {
           this.setState({
            isShowPayMethod: false,
           });
         }}
        onOk={(value)=> {
            // this.setState({
            //     isShowPayMethod: false,
            //    });
            this._toPay()
        }}
        />
    );
  };

  renderPayModal = () => {
    const { isShowPayModal, data, payAmount, payQrcode, orderNumber } = this.state;
    return (
        <PayModal
         ref="paymodal"
         isShowPayModal={isShowPayModal}
         payAmount={payAmount}
         payQrcode={payQrcode}
         orderNumber={orderNumber}
         title={data.type == 1 ? '购买系列课视频' : '购买视频课程'}
         desc={data.type == 1 ? '购买系列课视频' : '购买视频课程' + data.title}
         onClose={() => {
            this.setState({
              isShowPayModal: false,
            });
          }}
          callback={()=> {
            this.setState({
              isShowPayModal: false,
            });
            this._toLoadDetail()
          }}
         />
    );
  };

  hideShare =()=> {
      this.setState({
          showDistribution: false
      })
  }

  renderShareModal = () => {
    const { showDistribution, shareImage } = this.state;
    return (
        <DistributionModal
          showDistribution={showDistribution}
          shareImage={shareImage}
          close={this.hideShare}
        />
    );
  };

  share= async ()=> {
    let {data, offline, shareImage} = this.state;
    if (shareImage) {
        this.setState({
            showDistribution: true
        })
        return;
    }
    try {
        let params = {
            id: data.id,
            type: offline ? 3 : data.type == 1 ? 2 : 1
        };
        let result = await callGet('/public/share', params)
        this.setState({
            // type,
            shareImage: result.shareImage,
            showDistribution: true
        })
    } catch (e) {
        console.log(' to buy', e)
        message.error(e.errorMessage)
    }
  }

  _toBrowse = (item)=> {
    let {data} = this.state;
    if (data && data.buyFlag !== 1) {
        this._toBuy()
        return;
    }
    let {liveCourse=false} = this.props.location.state || {};
    if (liveCourse) {
        if (!item && data.chapters && data.chapters.length > 0) {
            item = data.chapters[0]
        }
        if (!item) {
            item = data;
        }
        // console.log('toBrowser ', item)
        window.open(item.liveStreamUrl)
    }
  }

  _toBuy = async () => {
    this.setState({
        isShowPayMethod: true
    })
  }

    _toPay = async () => {
        let {data, offline} = this.state;
        let {liveCourse=false} = this.props.location.state || {};
        const isVIPCardPay = this.refs.paymethodmodal.isPayCard();
        try {
            let orderResult;
            let params = {};
            params.itemId = data.id;
            params.num = 1;
            params.itemType = offline ? 4 : liveCourse ? 11 : data.type == 1 ? 3 : 2;
            params.payment = isVIPCardPay ? '2' : '0'

            if (isVIPCardPay) {
                let cardInfo = this.refs.paymethodmodal.getVIPCardInfo();
                params.cardNo = cardInfo.cardVipId
                params.cardPassword = cardInfo.cardVipPwd
            }

            orderResult = await callPost('/purchase', params)
            if (!isVIPCardPay) {
                const alipayInfo = await callPost('/alipay/payOrder', {tradeType: 2, balance: orderResult.paidAmount, orderNumber: orderResult.orderNumber})
                // console.log(' alipay info', alipayInfo)
                this.setState({
                    // type,
                    payAmount: orderResult.paidAmount,
                    orderNumber: orderResult.orderNumber,
                    isShowPayModal: true,
                    isShowPayMethod: false,
                    payQrcode: alipayInfo.payParam
                }, ()=> this.refs.paymodal.pollPayResult(orderResult.orderNumber))
            } else {
                message.success('购买成功')
                this.setState({
                    isShowPayMethod: false
                })
                this._toLoadDetail()
            }
        } catch (e) {
            console.log(' to buy', e)
            message.error(e.errorMessage)
        }
    }

    _to88vipPay = ()=> {
        this.props.history.push('/user/vippay')
    }

    onBeforePlay = ()=> {
        console.log('hook play before')
        let {liveCourse=false} = this.props.location.state || {};
        const { data } = this.state;
        return new Promise((resolve, reject) => {
            if (liveCourse) {
                if (data.buyFlag === 1) {
                    window.open(data.liveStreamUrl)
                }
                reject()
            } else {
                resolve()
            }
        })
    }

  render() {
    // const { course, loading} = this.props;
    const {sources, data, currentIndex, videoPlayIndex } = this.state;
    let {liveCourse=false} = this.props.location.state || {};
    const user = getUser()
    console.log('render', this.state)
    return (
      <div className="user-learning-page-background">
        {/* <UserLearningNavBar /> */}
        {/* <UserBanner banners={banners}/> */}
        
          <div className="user-learning-page-container">
          <BackButton/>
            {/* <div  className="prism-player" id='Ali_Player' /> */}
            <div style={{marginTop: 10}}>
                <Player id={videoPlayIndex} ref={this.player} sources={sources} cover={data.courseImageUrl} onBeforePlay={this.onBeforePlay}/>
            </div>
            <div className='course'>
                <div className='tab'>
                    <div className='tab_item' onClick={() => this._classTabOnClick(0)}>
                        <p className={currentIndex === 0 ? 'tab_item_select' : 'tab_item_normal'}>{'课程介绍'}</p>
                        {
                            currentIndex === 0 &&
                            <div className='split'/>
                        }
                    </div>
                    <div className='split_line'/>
                    {
                        data.type === 1 &&
                        <div className='tab_item' onClick={() => this._classTabOnClick(1)}>
                            <p className={currentIndex === 1 ? 'tab_item_select' : 'tab_item_normal'}>{'课程目录'}</p>
                            {
                                currentIndex === 1 &&
                                <div className='split'/>
                            }
                        </div>
                    }
                    {
                        data.type === 1 && <div className='split_line'/>
                    }
                </div>

                <div style={{marginBottom: 24}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
                        <div style={{flex:1, flexShrink: 1}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{
                                    color: '#000',
                                    fontWeight: 'bold',
                                    fontSize: 17
                                }}>{this.state.data.title}</p>
                                {
                                    this.state.data.type === 1 && <div className='serial_tag'>
                                        <p style={{
                                            color: '#fff',
                                            fontSize: 10,
                                            padding: 5
                                        }}>系列课</p></div>
                                }

                            </div>
                            <div
                                style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', flexWrap: 'wrap'}}>
                                <p style={{color: '#000000', fontSize: 10}}>原价：</p><p style={{
                                    textDecorationLine: 'line-through',
                                    color: '#959595',
                                    fontSize: 14
                                }}>{utils.changeMoney(this.state.data.originalPrice)}</p>
                                <p style={{color: '#000000', fontSize: 10, marginLeft: 12}}>会员价：</p>
                                <p style={{
                                        color: '#E8B400',
                                        fontSize: 17
                                    }}>{utils.changeMoney(this.state.data.vipPrice)}</p>
                                { data.commission > 0 && <CommissionButton commission={data.commission} onClick={this.share}/>}
                                {data.payCardFlag === 1 && <CardPayAvaliable />}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <div className='vip' onClick={()=> this._to88vipPay()}>
                                {user && user.isVip === 1 ? '会员续费' : '开通会员'}
                            </div>
                            {data && data.buyFlag === 0 && <div className='buy' onClick={this._toBuy}>
                                立即购买
                            </div>}
                            {data && data.buyFlag === 1 && liveCourse && <div className='buy' onClick={() => this._toBrowse()}>
                                观看直播
                            </div>}
                        </div>
                    </div>
                    <div className='line'/>

                    {
                        this.state.currentIndex === 0 && this._renderClassIntroduction()

                    }
                    {
                        this.state.currentIndex === 1 && this._renderClassChapters()
                    }
                    {
                        this.state.currentIndex === 2 && this._renderClassComments()
                    }
                </div>
                {/* {
                    data && data.buyFlag === 0 &&
                    <div className='buyWrap'>
                        <div className='buy' onClick={this._toBuy}>
                            立即购买
                        </div>
                    </div>
                } */}
            </div>
        </div>
        {this.renderPayModal()}
        {this.renderShareModal()}
        {this.renderPayMethodModal()}
      </div>
    );
  }

  _classTabOnClick = (index) => {
    this.setState({
        currentIndex: index
    })
  }

  _renderClassIntroduction = () => {
    let {data} = this.state;
    // console.log('_renderClassIntroduction ', data)
    return (
        <div>
            {/* {this.state.data && this.state.data.groupPurchases && this.state.data.groupPurchases.map(
                item => <PurchasesItem
                    owner={item.owner}
                    title={item.title}
                    groupPrice={item.groupPrice}
                    remainCount={item.remainCount}
                    current={item.current}
                    expiry={item.expiry}
                    participated={item.participated}
                />)} */}

            {
                data.organization && <div className='org'>
                        {/* <img className='org_avatar' src={data.organization.avatarUrl || '' } alt='icon'/> */}
                        <CompanyAvatar {...data.organization}/>
                        <div style={{marginLeft: 6}}>
                            <p
                                style={{color: '#363636', fontSize: 12}}>{this.state.data.organization.name}</p>
                            <p style={{
                                color: '#959595',
                                fontSize: 10,
                                marginTop: 5
                            }}>{this.state.data.organization.introduction}</p>
                        </div>
                    </div>
            }

            {data && data.tags && <div className='line'/>}

            {data && data.tags && <div className='tag'>
                {
                    data && data.tags && data.tags.map(item => <div style={{
                        backgroundColor: '#FFC600',
                        borderRadius: 14,
                        height: 28,
                        padding: '10px 12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 10
                    }}>
                        <span style={{
                            color: '#fff',
                            fontSize: 8,
                        }}>{item}</span>
                    </div>)
                }
            </div>}

            {data && data.tags && <div className='line'/>}

            <div>
                {/* <img source={book} style={{width: 12, height: 12 / 58 * 45}}/> */}
                <p style={{color: '#363636', fontSize: 12}}>课程简介</p>
            </div>
            <div style={{margin: '0 0'}}>
                {/* <HTMLView
                    value={this.state.data.description}
                /> */}
                {data.description}
            </div>
            {
                data.tableOfContentUrls && data.tableOfContentUrls.length > 0 && data.tableOfContentUrls.map((item, index) => 
                    <img key={'catalog'+index} style={{width: '100%'}} src={item.imageUrl} alt=''/>
                )
            }
        </div>
    );
}

    _renderClassChapters = () => {
        let { data } = this.state;
        let {liveCourse=false} = this.props.location.state || {};
        console.log('_renderClassChapters', data)
        const title_purchased = {
            color: '#363636',
            fontSize: 13,
            marginLeft: 20,
            marginRight: 10
        }
        const title_not_purchased= {
            color: '#D4D4D4',
            fontSize: 13,
            marginLeft: 20,
            marginRight: 10
        }
        return (
            <div>
                <p style={{color: '#363636', fontSize: 12, marginLeft: 8}}>课程目录</p>
                { !liveCourse && data && data.chapters && data.chapters.length > 0 && data.chapters.map((item, index)=> this._renderChapterItem(item, index))
                }
                {
                    liveCourse &&  data && data.chapters && data.chapters.map(
                        (item, index) => <div style={{display: 'flex', flexDirection: 'row', paddingTop: 10, cursor: 'pointer'}} onClick={()=> this._toBrowse(item)}>
                        <div style={{border: '1px solid #5A5A5A', borderRadius: 2, padding: 2, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span style={{color: '#5A5A5A', fontSize: 12}}>{item.liveStatus == 1 ? "预约" :  item.liveStatus == 2 ? "直播" : '回放'}</span></div>
                        <div style={{marginLeft: 10}}>
                            <span style={{fontSize: 15, color: '#000000'}}>{item.title}</span>
                            <span style={{fontSize: 13, color: '#000000', marginLeft: 10}}>{dateutils.parseLivetime(item)}</span>
                        </div>
                    </div>
                    )
                }
            </div>
        );
    }

    _renderChapterItem = (item, index)=> {
        let { videoCourse={}, sections=[] } = item;
        let userInfo = getUser()
        console.log('_renderChapterItem', item)
       
        return (
            (
                <div style={{display: 'flex', flexDirection: 'column', margin: '0 12px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', height: 30, alignItems: 'center', borderTop: index === 0 ? '0 solid #F4F4F4' : '1px solid #F4F4F4'}}>
                    <span style={{color: "#363636", fontSize: 16, marginLeft: 0, }}>{item.title}</span>
                    </div>
                     
                {/* <img style={{ width: 20, height: 14}} source={!showVideoCover && videoPlayIndex == datas.item.playIndex ? require('../../assets/video_play.jpg') : require('../../assets/video_pause.jpg')}/> */}
                {/* {
                    !showVideoCover && videoPlayIndex == datas.item.playIndex && <span style={{color: '#FFA700', marginLeft: 10, fontSize: 13}}>当前视频</span>
                } */}
                <div style={{marginLeft: 12}}>
                    {
                        sections.length > 0 && sections.map((item, index)=> this._renderClassItem(item, index))
                    }
                </div>
                </div>
            )
        )
    }

    _renderClassItem = (item, index)=> {
        let { videoPlayIndex, showVideoCover } = this.state;
        let { videoCourse={} } = item;
        let userInfo = getUser()
        return (
            <div onClick={()=> this._toPlay(videoCourse)} style={{display: 'flex', flexDirection: 'row', height: 23, alignItems: 'center'}}>
                <span style={(videoCourse.buyFlag === 1 || (userInfo.isVip && videoCourse.vipPrice === 0) ? styles.title_purchased : styles.title_not_purchased)}>{item.name}</span>
                {/* <img style={{ width: 20, height: 14}} source={!showVideoCover && videoPlayIndex == datas.item.playIndex ? require('../../assets/video_play.jpg') : require('../../assets/video_pause.jpg')}/> */}
                {
                    videoPlayIndex == item.playIndex && <span style={{color: '#FFA700', marginLeft: 10, fontSize: 13}}>当前视频</span>
                }
            </div>
        )
    }

}

const styles = {
    title_purchased: {
        color: '#363636',
        fontSize: 13,
        marginLeft: 20,
        marginRight: 10
    },
    title_not_purchased: {
        color: '#D4D4D4',
        fontSize: 13,
        marginLeft: 20,
        marginRight: 10
    },
}