import React, { PureComponent } from 'react';
import { Button, Icon } from 'antd';
import {withRouter} from 'react-router-dom';

class BackButton extends PureComponent{

    back = ()=> {
      // console.log(' back button', this.props)
      const {history} = this.props;
      if (history) {
        history.goBack()
      }
    }

    render() {
      const {onClick} = this.props;
        return (
          <Button type="primary" onClick={onClick ? onClick : this.back} ghost>
            <Icon type="left" />
            返回
          </Button>
        );
    }
}

export default withRouter(BackButton)