import React from "react";
import { Card, Tabs, Button, Row, Col ,Icon} from "antd";
import UserJobNavBar from "components/job-navbar";
import UserBanner from "components/banner";

const { TabPane } = Tabs;

const operations = (
  <p>
    <Button type="primary" size="small">
      更多
    </Button>
    &nbsp;&nbsp;&nbsp;&nbsp;
  </p>
);

export default class UserJobFreeCoursePage extends React.Component {
  render() {
    return (
      <div>
        <UserJobNavBar tabkey='3'/>
        <UserBanner />
        <div className="user-job-page-background">
          <div className="user-job-page-container">


          </div>
        </div>
      </div>
    );
  }
}
