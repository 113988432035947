import React, { Component } from "react";
import {
  Tabs,
  Button,
  Card,
  DatePicker,
  Statistic,
  Row,
  Col,
  Icon,
  Table,
  Avatar,
  Tag,
  Input,
  Menu,
  Dropdown,
  Upload,
  message,
  Comment,
  Tooltip,
  Form,
  List,
  Cascader
} from "antd";

import "./index.scss";
import moment from "moment";
import { enquireScreen } from 'enquire-js';
import ProfileBackgroundImage from "images/profile_page_backgroung.png";
import { getAddress as getAddressCache, setAddress as setAddressCache, setUser, getToken, getUser } from 'utils/authority';
import dateutils from 'utils/dateutils'
import {callGet, callPost, getAddress as getAddressRemote} from 'service/api'
import WalletPage from './wallet'
import ResumePage from "./resume";
import OrdersPage from "./orders";

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { TabPane } = Tabs;
const { TextArea } = Input;

const { Search } = Input;

const { Meta } = Card;
const { MonthPicker, RangePicker } = DatePicker;

const listData = [];
for (let i = 0; i < 23; i++) {
  listData.push({
    href: "http://ant.design",
    title: `ant design part ${i}`,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    description:
      "Ant Design, a design language for background applications, is refined by Ant UED Team.",
    content:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently."
  });
}

const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

const data = [
  {
    actions: [<span>Reply to</span>],
    author: "Han Solo",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>
          {moment()
            .subtract(1, "days")
            .fromNow()}
        </span>
      </Tooltip>
    )
  },
  {
    actions: [<span>Reply to</span>],
    author: "Han Solo",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment()
          .subtract(2, "days")
          .format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>
          {moment()
            .subtract(2, "days")
            .fromNow()}
        </span>
      </Tooltip>
    )
  }
];

const systemData = [
  {
    title: "Ant Design Title 1"
  },
  {
    title: "Ant Design Title 2"
  },
  {
    title: "Ant Design Title 3"
  },
  {
    title: "Ant Design Title 4"
  }
];

const dateFormat = "YYYY/MM/DD";

const operations = (
  <DatePicker
    defaultValue={moment("2015/01/01", dateFormat)}
    format={dateFormat}
  />
);

function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

class UserMyPage extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    const panes = [
      { title: '简历1', content: 'Content of Tab Pane 1', key: '1' },
      { title: '简历2', content: 'Content of Tab Pane 2', key: '2' },
    ];
    this.state = {
      activeKey: panes[0].key,
      panes,
      isMobile: false,
      ship: {
        name: '',
        phone: '',
        city: '',
        address: ''
      },
      address: {
      },
      location: undefined,
      districts: []
    };
  }

  componentDidMount() {
    this.enquireHandler = enquireScreen(mobile => {
      const { isMobile } = this.state;
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });

    this.getAddress()
    // this.getDistrictsInfo()
  }

  getDistrictsInfo = async ()=> {
    try {
      const result = await callGet('/public/districts');
      this.setState({
        districts: result || []
      })
    } catch (e) {
      console.log('e', e)
    }
  }

  getAddress = async ()=> {
    if (!getToken()) {
      return;
    }
    try {
      const add = await getAddressRemote();
      this.setState({
        address: add,
        ship: {
          id: add.id,
          name: add.name,
          phone: add.mobileNo,
          address: add.fullAddress
        },
        location: {
          pro: add.province,
          proCode: add.provinceCode,
          city: add.city,
          cityCode: add.cityCode,
          district: add.district,
          districtCode: add.districtCode
        }
      })
    } catch (e) {
      console.log('e', e)
    }
  }

  onChange = activeKey => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  add = () => {
    const { panes } = this.state;
    const activeKey = `newTab${this.newTabIndex++}`;
    panes.push({ title: 'New Tab', content: 'New Tab Pane', key: activeKey });
    this.setState({ panes, activeKey });
  };

  remove = targetKey => {
    let { activeKey } = this.state;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter(pane => pane.key !== targetKey);
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].key;
      } else {
        activeKey = panes[0].key;
      }
    }
    this.setState({ panes, activeKey });
  };

  submitProfile = async ()=> {
    const { location, address } = this.state;
    // let addressFull = address ? ( address.province == address.city ? address.city + address.district + address.fullAddress  : address.province + address.city + address.district + address.fullAddress  ) : '';
    console.log('submit ', this.nicknameRef, this.shipnameRef)
    if (!this.nicknameRef || !this.personTitleRef || !this.shipnameRef || !this.shipphoneRef || !this.shipaddressRef) {
      return;
    }
    if (!this.nicknameRef.state.value) {
      message.error('请完善昵称')
      return;
    }
    // if (!this.nicknameRef.state.value) {
    //   message.error('请完善昵称')
    //   return;
    // }
    if (!this.shipnameRef.state.value) {
      message.error('请填写收货人姓名')
      return;
    }
    if (!this.shipphoneRef.state.value) {
      message.error('请填写收货人电话')
      return;
    }
    if (!location) {
      message.error('请选择城区')
      return;
    }
    if (!this.shipaddressRef.state.value) {
      message.error('请填写收货人地址')
      return;
    }

    const user = getUser()
    let params = {};
    params.nickName = this.nicknameRef.state.value || '';
    params.avatarUrl = user && user.avatarUrl ? user.avatarUrl : '';
    params.title = this.personTitleRef.state.value || '';
    // params.gender = sex ? sex : userInfo.gender ? userInfo.gender : 0;
    
    try {
      const result = await callPost('/users/info', params, 'PUT')
      setUser(result)

      params = {}
      params.city = location.city;
      params.cityCode = location.cityCode;
      // params.defaultFlag = this.state.defaultFlag;
      params.district = location.district;
      params.districtCode = location.districtCode;
      params.fullAddress = this.shipaddressRef.state.value;
      params.mobileNo = this.shipphoneRef.state.value;
      params.name = this.shipnameRef.state.value;
      params.province = location.pro;
      params.provinceCode = location.proCode;

      let addNew
      
      if (address.id) {
        addNew = await callPost('/user/addresses/' + address.id, params, 'PUT');
      } else {
        addNew = await callPost('/user/addresses', params)
      }
      this.setState({
        address: addNew,
      })

      message.success('更新成功')
      setAddressCache(addNew)
    } catch (e) {
      console.log('e', e)
      message.error(e.errorMessage)
    }
  }

  onShipnameChange = (e)=> {
    console.log(' onShipnameChange ', e, e.nativeEvent, e.currentTarget)
  }

  onCityChange =  (val,citys) => {
    console.log('on city change ', val, citys)
    if (citys.length === 3) {
      let location = {
        pro: citys[0].name,
        proCode: citys[0].code,
        city: citys[1].name,
        cityCode: citys[1].code,
        district: citys[2].name,
        districtCode: citys[2].code
      }
      this.setState({
        location
      })
    }
  }

  onCityCascaderVisible = (visible) => {
    console.log('e', visible)
    const {districts} = this.state;
    if (visible && districts.length === 0) {
      this.getDistrictsInfo()
    }
  }

  goVipPay = ()=> {
    this.props.history.push('/user/vippay')
  }

  render() {
    const { form, submitting } = this.props;
    const { getFieldDecorator } = form;
    const user = getUser()
    const { isMobile, address, ship, location, districts } = this.state;
    let districtInfo = ''
    if (location) {
      if (location.pro == '北京市' || location.pro == '天津市' || location.pro == '重庆市' || location.pro == '上海市') {
        districtInfo = location.city + location.district
      } else {
        districtInfo = location.pro + location.city + location.district
      }
    }
    return (
      <div className="company-my-page-background">
        <div className="company-my-page-container">
          <div
            className="background-image"
            style={{
              backgroundImage: `url(${ProfileBackgroundImage})`,
              height: 240,
              backgroundSize: "cover",
            }}
          >
            <div style={{ paddingTop: isMobile ? 10 : 20, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Avatar
                src={user && user.avatarUrl ? user.avatarUrl : 'https://img.syyc.top/h5/avatar.png'}
                style={{
                  backgroundColor: "#ffbf00",
                  verticalAlign: "middle",
                  marginBottom: "10px"
                }}
                size={64}
              />
              <span style={{ fontSize: '20px', marginTop: '6px' }}>{user && user.nickName ? user.nickName : ''}</span>
              <span style={{ marginTop: '6px' }}>{user && user.userAccountId ? `ID: ${user.userAccountId}` : ''}</span>
              <span>{user && user.title ? user.title : ''}</span>
              {
                    getToken() && user.isVip === 1 && <div style={{ marginTop: 6, marginLeft: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
                      会员{user.vipExpireAt ? dateutils.formatDate(user.vipExpireAt, 'yyyy-MM-dd') : ''}到期
                      {/* <p>累计已省1789元&nbsp; &nbsp; &nbsp; <Button size="small" >
                          查看
                        </Button></p> */}
                      <Button type="primary" style={{ width: '130px', marginLeft: 12 }}  onClick={this.goVipPay}>
                        立刻续费
                      </Button>
                    </div>
                  }
                  {
                    getToken() && user.isVip !== 1 && <Button type="primary" style={{ width: '130px', marginLeft: 12 }} onClick={this.goVipPay}>
                        开通会员
                      </Button>
                  }
                  {
                    !getToken() && <Button type="primary" style={{ width: '130px', marginLeft: 12 }} onClick={()=> this.props.history.push('/user/login')}>
                      登录
                    </Button>
                  }
            </div>
          </div>

          <Card bordered={false}>
            <Tabs >
              <TabPane tab="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我的主页" key="1">
                <Tabs tabPosition="left" >
                  {/* <TabPane tab="发布动态" key="1">
                    <Card bordered={true} style={{ minHeight: "500px" }}>
                      <Card title="发布动态" bordered={true} >
                        <TextArea rows={4} />
                        <Row gutter={16} style={{ marginTop: '10px' }}>
                          <Col span={12}>
                            <p><Icon type="smile" />表情&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="picture" />图片&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="file" />文件&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="notification" />@提醒好友 </p>
                          </Col>
                          <Col span={12} style={{ textAlign: 'right' }}>
                            <Dropdown overlay={menu}>
                              <a className="ant-dropdown-link" href="#">
                                公开 <Icon type="down" />
                              </a>
                            </Dropdown> &nbsp;&nbsp;&nbsp;&nbsp; <Button type="primary">发布</Button>

                          </Col>
                        </Row>

                      </Card>

                      <Card style={{ marginTop: '20px' }} >
                        <Meta
                          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                          title="周珊珊"
                          description="1分钟前"
                        />
                        <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                        <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                        <Divider type="horizontal" />
                        <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                      </Card>

                      <Card style={{ marginTop: '20px' }} >
                        <Meta
                          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                          title="周珊珊"
                          description="1分钟前"
                        />
                        <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                        <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                        <Divider type="horizontal" />
                        <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                      </Card>

                      <Card style={{ marginTop: '20px' }} >
                        <Meta
                          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                          title="周珊珊"
                          description="1分钟前"
                        />
                        <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                        <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                        <Divider type="horizontal" />
                        <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                      </Card>

                      <Card style={{ marginTop: '20px' }} >
                        <Meta
                          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                          title="周珊珊"
                          description="1分钟前"
                        />
                        <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                        <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                        <Divider type="horizontal" />
                        <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                      </Card>

                      <Card style={{ marginTop: '20px' }} >
                        <Meta
                          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                          title="周珊珊"
                          description="1分钟前"
                        />
                        <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                        <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                        <Divider type="horizontal" />
                        <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                      </Card>
                    </Card>
                  </TabPane> */}
                  <TabPane tab="编辑资料" key="2">
                    <Card bordered={true} style={{ minHeight: "500px" }}>
                      <br />
                      <br />
                      <Row gutter={16} className="form-item">
                        <Col span={4} style={{ textAlign: "right" }}>
                          昵称
                        </Col>
                        <Col span={20}><Input ref={(ref)=> this.nicknameRef = ref} size="large" placeholder='请填写昵称' defaultValue={user.nickName || ''}/></Col>
                      </Row>
                      <br />
                      {/* <Row gutter={16}>
                        <Col span={4} style={{ textAlign: "right" }}>
                          师业ID
                        </Col>
                        <Col span={20}>
                          1325464
                         </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col span={4} style={{ textAlign: "right" }}>
                          实名认证
                        </Col>
                        <Col span={20}>
                          <Button>申请认证</Button>
                        </Col>
                      </Row>
                      <br /> */}
                      <Row gutter={16}>
                        <Col span={4} style={{ textAlign: "right" }}>
                          个人签名
                        </Col>
                        <Col span={20}>
                          <Input ref={(ref)=> this.personTitleRef = ref} size="large" placeholder='您还没有个人签名' defaultValue={user.title || ''}/>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col span={4} style={{ textAlign: "right" }}>
                          我的地址
                        </Col>
                        <Col span={20}>
                          <div className='address-item'>
                          {getFieldDecorator('shipname', {
                            initialValue: ship.name
                          })(
                            <Input addonBefore={
                              <span
                                onChange={this.changePrefix}
                              >
                                收货人姓名
                              </span>
                            } size="large" placeholder='请填写收货人姓名' ref={(ref)=> this.shipnameRef = ref} onChange={this.onShipnameChange}/>
                          )}
                          </div>
                          <div className='address-item'>
                          {getFieldDecorator('shipphone', {
                            initialValue: ship.phone
                          })(
                            <Input addonBefore={
                              <span
                              >
                                收货人手机
                              </span>
                            } size="large" placeholder='请填写收货人手机号' ref={(ref)=> this.shipphoneRef = ref}/>
                            )}
                          </div>
                          
                            <Cascader
                            onPopupVisibleChange={this.onCityCascaderVisible}
                                fieldNames={{ label: 'name', value: 'code', children: 'child' }}
                              options={districts} changeOnSelect onChange={this.onCityChange}>
                              <div className='address-city-item' style={{
                            
                            }}>
                              <div className='prefix'>
                                <span>
                                选择城区
                                </span>
                                  </div>
                                  {
                                    location && <span className='value'>
                                      {districtInfo}
                                    </span>
                                  }
                                  {
                                    !location && <span className='placeholder'>
                                      请选择城区
                                    </span>
                                  }
                                 </div>
                            </Cascader>
                         
                          <div className='address-item'>
                            {getFieldDecorator('shipaddress', {
                              initialValue: ship.address
                            })(
                              <Input addonBefore={
                                <span
                                >
                                  详细地址
                                </span>
                              } size="large" placeholder='请填写详细地址'  ref={(ref)=> this.shipaddressRef = ref}/>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <div style={{width: '100%', textAlign: 'center'}}>
                      <Button onClick={this.submitProfile} type='primary'>保存</Button>
                      </div>
                      
                    </Card>
                  </TabPane>
                  {/*<TabPane tab="完善简历" key="3">
                    <Card bordered={true} style={{ minHeight: "500px" }}>
                      <div>
                        <Tabs
                          hideAdd
                          onChange={this.onChange}
                          activeKey={this.state.activeKey}
                          type="editable-card"
                          onEdit={this.onEdit}
                          tabBarExtraContent={<Button onClick={this.add}>添加</Button>}
                        >
                          {this.state.panes.map(pane => (
                            <TabPane tab={pane.title} key={pane.key}>
                              {pane.content}
                            </TabPane>
                          ))}
                        </Tabs>
                      </div>

                    </Card>
                  </TabPane>

                  <TabPane tab="我的收藏" key="4">
                    <Card bordered={true} style={{ minHeight: "500px" }}>
                      <Tabs defaultActiveKey="1"
                        tabBarExtraContent={<Search
                          placeholder="input search text"
                          onSearch={value => console.log(value)}
                          style={{ width: 200 }}
                        />}>
                        <TabPane tab="题库收藏" key="1">
                          Content of Tab Pane 1
                  </TabPane>
                        <TabPane tab="课程收藏" key="2">
                          Content of Tab Pane 2
                  </TabPane>
                        <TabPane tab="习题收藏" key="3">
                          Content of Tab Pane 3
                  </TabPane>
                        <TabPane tab="职位收藏" key="4">
                          Content of Tab Pane 3
                  </TabPane>
                        <TabPane tab="用户动态" key="5">
                          Content of Tab Pane 3
                  </TabPane>
                      </Tabs>
                    </Card>
                  </TabPane>

                  <TabPane tab="我的钱包" key="5">
                    <Card bordered={true} style={{ minHeight: "500px" }} />
                  </TabPane>

                  <TabPane tab="我的积分" key="6">
                    <Card bordered={true} style={{ minHeight: "500px" }} />
                  </TabPane>

                  <TabPane tab="加入师业有成" key="8">
                    <Card bordered={true} style={{ minHeight: "500px" }} />
                  </TabPane>

                  <TabPane tab="我的邀请码" key="9">
                    <Card bordered={true} style={{ minHeight: "500px" }} />
                  </TabPane> */}
                </Tabs>
              </TabPane>
              {/*                    <TabPane tab="动态" key="2">
                    <Card bordered={true} style={{ minHeight: "500px" }}>
                <Card title="发布动态" bordered={true} >
                <TextArea rows={4} />
                <Row gutter={16} style={{marginTop:'10px'}}>
                  <Col span={12}> 
                  <p><Icon type="smile" />表情&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="picture" />图片&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="file" />文件&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="notification" />@提醒好友 </p>
                  </Col>
                  <Col span={12} style={{textAlign:'right'}}> 
                  <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" href="#">
                      公开 <Icon type="down" />
                    </a>
                  </Dropdown> &nbsp;&nbsp;&nbsp;&nbsp; <Button type="primary">发布</Button>

                  </Col>
                </Row>
               
                    </Card>

                    <Card style={{marginTop:'20px'}} >
                    <Meta
                      avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                      title="周珊珊"
                      description="1分钟前"
                    />
                  <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                  <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                  <Divider type="horizontal"/>
                  <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                  </Card>

                  <Card style={{marginTop:'20px'}} >
                    <Meta
                      avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                      title="周珊珊"
                      description="1分钟前"
                    />
                  <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                  <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                  <Divider type="horizontal"/>
                  <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                  </Card>

                  <Card style={{marginTop:'20px'}} >
                    <Meta
                      avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                      title="周珊珊"
                      description="1分钟前"
                    />
                  <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                  <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                  <Divider type="horizontal"/>
                  <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                  </Card>

                  <Card style={{marginTop:'20px'}} >
                    <Meta
                      avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                      title="周珊珊"
                      description="1分钟前"
                    />
                  <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                  <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                  <Divider type="horizontal"/>
                  <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                  </Card>

                  <Card style={{marginTop:'20px'}} >
                    <Meta
                      avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                      title="周珊珊"
                      description="1分钟前"
                    />
                  <p>【教师资格证】试讲环节时间应该如何分配，才能过关斩将？ #课程</p>
                  <img
                          alt="example"
                          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        />
                  <Divider type="horizontal"/>
                  <p><Icon type="heart" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="message" />5904&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="retweet" />5904</p>
                  </Card>
                </Card>
                    </TabPane>

                    <TabPane tab="相册" key="3">
                    
                    </TabPane>

                    <TabPane tab="关注" key="4">
                    
                    </TabPane>

                    <TabPane tab="粉丝" key="5">
                    
                    </TabPane>

                    <TabPane tab="访客" key="6">
                    
                    </TabPane> */}
                    <TabPane tab="我的简历" key="6">
                      <ResumePage {...this.props} isMobile={isMobile}/>
                    </TabPane>

                    <TabPane tab="钱包" key="7">
                      <WalletPage props={this.props} isMobile={isMobile}/>
                    </TabPane>

                    <TabPane tab="我的订单" key="8">
                      <OrdersPage props={this.props} isMobile={isMobile}/>
                    </TabPane>

            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
}

export default Form.create({name: 'register'})(UserMyPage)